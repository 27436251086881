import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, Tab, Nav, Image, ButtonGroup, InputGroup, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import styles from '../components/constants/styles.js'
import { roomComposition_dict  } from "../components/constants/global_definitions"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import { ElementFormComposition } from '../components/RoomComposition'
import ElementFormActivities from './FormActivities'
import ModalBaseObject from "../components/ModalBaseObject"
import { ControllerImageContainer } from '../components/ControllerImageContainer'
import { ServiceCheckPreview } from "../components/CheckPreview"

import { ServiceBadge } from '../components/StructureGraphicElements.js'
import { getSelectedElement, checkComponentPresence, compareName, makeid } from "../util/ui_utils.js"


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getRoomCheckList as getRoomCheckList2 } from '../Firebase2/Storage2/dbRoomCheck';
import { addRoomType as addRoomType2, editRoomType as editRoomType2 } from '../Firebase2/Storage2/dbBaseStruct';
import { ModalSelectRoomTypeImage } from './modal/ModalSelectRoomtypeImage.js';
import { ModalRoomtypeLinkedRooms } from './modal/ModalRoomtypeLinkedRooms.js';



const newroomtype = {
	name: "",
	risk_area: {},
	floor: {},
	floor_status: {},
	furniture_list: [],
	environment_element_list: [],
	economic_material_list: [],
	check_list: {},
	operation_list: [],
	patient_unit: 1,
	need_waxing: false,
	has_toilet: false,
	lqa: 0.9,
	has_microbiological_check: false,
	can_mechanized_washing: false
}
const contract = JSON.parse(sessionStorage.getItem('contract'))



function ModalRoomType({ item, setItem, t, is_new, small, element_list, canWrite, ui_list }) {
	const { domain } = useContext(DomainContext)
	const { updateRoomtype, contract, structureElements } = useContext(GlobalDataContext)
	const contractId = contract.id

	const [showEdit, setShow] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curElement, setCurrentElement] = useState(newroomtype)
	const [editedElement, setEditedElement] = useState(false)
	const [updateRooms, setUpdateRooms] = useState(false)




	//	const [lang, setLang] = useState('en')
	const handleShowNew = () => {
		setCurrentElement(newroomtype)
		setNew(true)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setNew(false)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 1000)
	}

	const handleClose = () => setShow(false);

	const handleSave = async (force_new) => {
		console.log('contractId', contractId, curElement)
		let newelement_list = [...element_list]
		if (newEdit || force_new) {
			let _elem = {...curElement}
			delete _elem.id
			let return_data = await addRoomType2(domain, contractId, _elem)
			console.log(return_data)
			if (return_data && return_data.error) {
				//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				newelement_list.push(return_data)
				console.log(newelement_list)
				setShow(false);
				newelement_list = newelement_list.sort(compareName)
				setItem(newelement_list)
				updateRoomtype(newelement_list)
			}
		} else {
			let return_data = await editRoomType2(domain, contractId, curElement.id, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				//childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const c = checkComponentPresence(curElement, element_list, 'id')
				newelement_list.splice(c, 1, curElement)
				setItem(newelement_list)
				updateRoomtype(newelement_list)
				setShow(false);
			}
		}
	}

	const onChangeHandlerComposition = (event) => {
		onChangeHandler(event)
		setUpdateRooms(true)
	}


	const onChangeHandler = (el, is_multi) => {
		let { name, value, type } = el
		if (is_multi) {
			setCurrentElement({...curElement, ...el})
			setEditedElement(true)
		} else {
			if (type === 'radio') {
				if (value === 'true') {
					value = true
				} else if (value === 'false') {
					value = false
				}
			} else if (type === 'checkbox') {
				let oldcheck = []
				try {
					oldcheck = [...curElement[name]]
				} catch (err) { }
				if (el.checked) {
					if (oldcheck.indexOf(value) < 0) {
						oldcheck.push(value)
					}
				} else {
					if (oldcheck.indexOf(value) >= 0) {
						oldcheck.splice(oldcheck.indexOf(value), 1)
					}
				}
				value = oldcheck
			}
			console.log(name, value)
			if (name !== undefined && value !== undefined) {

				setCurrentElement({ ...curElement, [name]: value })
				setEditedElement(true)
			}

		}
	}






	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNew()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('roomtype.labels.roomtype_add')}</Button>
				)
				: <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon={canWrite ? "pencil-alt" : "info-circle"} /> </Button>
			}
			<Modal show={showEdit} onHide={handleClose} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('roomtypes.labels.new_roomtype')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('roomtypes.labels.edit_roomtype')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('roomtypes.labels.view_roomtype')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3} lg="2">
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link eventKey="tabStructure">{t('roomtype.labels.composition')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link eventKey="tabOperations">{t('roomtype.labels.activities')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link eventKey="tabChecks">{t('roomtype.labels.checks')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} canWrite={canWrite} image_list={ui_list?ui_list.image_list: []} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabStructure">
											<ElementFormComposition element={curElement} t={t} functions={{ edit: onChangeHandlerComposition, setItem: setCurrentElement }} is_type={true} canWrite={canWrite} is_operation={false} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabOperations">
											<ElementFormActivities element={curElement} t={t} functions={{ edit: onChangeHandler }} ui_list={ui_list} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabChecks">
											<ElementFormChecks element={curElement} t={t} functions={{ edit: onChangeHandler }} ui_list={ui_list} canWrite={canWrite} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>

					<ModalRoomtypeLinkedRooms roomtype={curElement} t={t} canWrite={canWrite && !editedElement}/>
					<Button variant="secondary" onClick={handleClose}>
						<FontAwesomeIcon icon="times" /> {t('modal.close')}
					</Button>
					{canWrite &&
						<>
							<Button variant={editedElement ? "warning" : "primary"} onClick={() => handleSave(false)}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
							<Button variant={editedElement ? "outline-warning" : "outline-primary"} onClick={() => handleSave(true)}>
								<FontAwesomeIcon icon="save" /> {t('modal.save_new')}
							</Button>
						</>
						}
				</Modal.Footer>
			</Modal>
		</>
	)
}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, t, canWrite, image_list }) {
	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const [ui_list, setUiList] = useState({})
	const { contract, risk_area_list } = useContext(GlobalDataContext)

	const initValues = async () => {
		const floor_list = await getBaseObjectTypeList2(domain, 'floorings')
		//	let risk_area_list = await getBaseObjectTypeList2(domain, 'risk_areas')
		//risk_area_list.sort(compareOrder)
		setUiList({ ...ui_list, floor_list, risk_area_list })
	}
	useEffect(() => {
		initValues()
	}, [risk_area_list])

	const updateFloors = (b) => {
		setUiList({ ...ui_list, floor_list: b.list })
	}
	const updateRiskAreas = (b) => {
		setUiList({ ...ui_list, risk_area_list: b.list })
	}

	return (
		<>
			<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">
					{contract.type === 'civil' ?
						t('global.labels.standard_cleaning')
						:
						t('global.labels.risk_area')
					}
				</Form.Label>
				<Col sm="4">
					<Form.Control as="select" className="mb-2" value={element.risk_area.Id} name="risk_area" onChange={(event) => functions.edit({ name: 'risk_area', type: 'select', value: getSelectedElement(event.target.value, ui_list.risk_area_list) })} >
						<option value="-1" >
							{contract.type === 'civil' ?
								t('structure.labels.select_standard_cleaning')
								:
								t('structure.labels.select_risk_area')
							}</option>
						{ui_list.risk_area_list
							? [Object(ui_list.risk_area_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="2">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'risk_areas', list: ui_list.risk_area_list }} t={t} functions={{ setCurSection: updateRiskAreas }} />
						: ''}
				</Col>
				<Col sm="1">
					{element.risk_area.color ?
						<Badge className="d-flex" style={{
							background:
								`rgba(${element.risk_area.color.r}, ${element.risk_area.color.g}, ${element.risk_area.color.b}, ${element.risk_area.color.a})`
						}}>&nbsp;&nbsp;</Badge>
						: ''
					}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.flooring')} </Form.Label>
				<Col sm="4">
					<Form.Control as="select" className="mb-2" name="floor" value={element.floor.Id} onChange={(event) => functions.edit({ name: 'floor', type: 'select', value: getSelectedElement(event.target.value, ui_list.floor_list) })} >
						<option value="-1" >{t('roomtype.labels.select_floor')}</option>
						{ui_list.floor_list
							? [Object(ui_list.floor_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
					<Form.Text className="text-muted">
						{t('roomtype.labels.floor_type')}
					</Form.Text>
				</Col>
				<Col sm="2">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'floorings', list: ui_list.floor_list }} t={t} functions={{ setCurSection: updateFloors }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.has_toilet')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="has_toilet" value={true} checked={element.has_toilet} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="has_toilet" value={false} checked={!element.has_toilet} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.can_mechanized_washing')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="can_mechanized_washing" value={true} checked={element.can_mechanized_washing} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="can_mechanized_washing" value={false} checked={!element.can_mechanized_washing} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureCF" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.need_waxing')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="need_waxing" value={true} checked={element.need_waxing} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="need_waxing" value={false} checked={!element.need_waxing} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.image_reference')} </Form.Label>
				<Col sm="4">

					<ModalSelectRoomTypeImage t={t} curImage={element.image_reference_data} chooseImage={(i) => functions.edit({ image_reference: i.media, image_reference_data: {description: i.description, Id: i.Id, media: i.media} }, true)} image_list={image_list} />
				</Col>
				<Col >
					{element.image_reference ?
						<>
							<Image src={element.image_reference} thumbnail />
							<i className="text-muted">a puro titolo esemplificativo</i>
						</>
						: ''}

				</Col>
			</Form.Group>
		</>
	)
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormChecks({ element, functions, t, ui_list, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { contract, service_dict, contractId } = useContext(GlobalDataContext)
	console.log("CONTRACTId", contractId)


	const newcheck = {
		code: '',
		name: '',
		description: ''
	}


	const newcheckpoint = {
		name: '',
		code: '',
		top: 10,
		left: 10,
		Id: 0,
		element_list: []
	}

	const [check_list, setCheckList] = useState({})
	const [loading, setLoading] = useState(false)
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	//	const [newComponentEdit, setNewComponentEdit] = useState(true)
	//	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showCheckEdit, setShowCheckEdit] = useState(false)
	const [selectedElement, setSelectedElement] = useState(-1)
	const [newCheckEdit, setNewCheckEdit] = useState(true)
	const [curCheck, setCurCheck] = useState(newcheck)



	const [curActivity, setCurActivity] = useState({})
	const [actionIndex, setActionIndex] = useState(-1)


	const initValues = async () => {
		const newelement_list = await getRoomCheckList2(domain, contractId)
		console.log('check_list', newelement_list)
		setCheckList(newelement_list)
	}
	/* 
		const onChangeHandlerAction = (el) => {
			let { name, value, type } = el
			if (type === 'radio') {
				if (value === 'true') {
					value = true
				} else if (value === 'false') {
					value = false
				}
			} else if (type === 'checkbox') {
				let oldcheck = []
				try {
					oldcheck = [...curActivity[name]]
				} catch (err) { }
				if (el.checked) {
					if (oldcheck.indexOf(value) < 0) {
						oldcheck.push(value)
					}
				} else {
					if (oldcheck.indexOf(value) >= 0) {
						oldcheck.splice(oldcheck.indexOf(value), 1)
					}
				}
				value = oldcheck
			}
			console.log(name, value)
			let edited_action = { ...curActivity, [name]: value }
			if (!edited_action.check_list) {
				edited_action.check_list = {}
			}
			let edit_service_list = [...element.service_list]
	
			console.log(actionIndex)
			if (actionIndex !== 0) {
				edit_service_list.splice(actionIndex, 1, edited_action)
				console.log(edit_service_list)
				setCurActivity(edited_action)
				functions.edit({ name: 'service_list', value: edit_service_list })
			} else {
				console.log('INDEX ERROR')
			}
	
		}
	
		useEffect(() => {
			setLoading(false)
		}, [check_list])
	
	 */
	useEffect(() => {
		initValues()
	}, [])

	const onChangeAction = (el, index) => {
		let editlist = element.service_list
		const c = checkComponentPresence(el, editlist, 'id')
		console.log(el, c, editlist)
		if (index !== -1) {
			editlist.splice(index, 1, el)
			functions.edit({ name: 'service_list', value: editlist })
		}
	}


	console.log("roomtype service lsit", element.service_list)

	return (
		<>

			{element.service_list && element.service_list.length !== 0 ?
				<>
					<Row >
						<Col className="bg-secondary text-light ms-1">{t('global.labels.service')}</Col>
						<Col className="bg-secondary text-light ms-1">{t('global.labels.elements')} </Col>
						<Col className="bg-secondary text-light ms-1" sm="2">{t('global.labels.lqa')} </Col>
						<Col className="bg-secondary text-light ms-1 text-center" sm="2">{t('global.labels.actions')} </Col>
					</Row>
					{element.service_list.map((activity, k) => (
						<Row key={k} className="border-bottom mb-1 bp-1">
							<Col>
								<ServiceBadge service={service_dict[activity.service.id]} freq={true} />
							</Col>
							<Col>
								{activity.check_list ?
									<Badge bg="secondary">
										{Object.keys(activity.check_list).length} elementi
									</Badge>
									: ''}
							</Col>
							<Col sm="2">
								{activity.lqa}
							</Col>
							<Col className="text-center" sm="2" >
								<ButtonGroup>
									<ServiceCheckPreview activity={activity} ui_list={ui_list} t={t} roomType_name={element.name} roomType_elements={[...element.furniture_list, ...element.environment_element_list, ...element.economic_material_list]} />
									<ModalActivityFormChecks roomtype={element} index={k} activity={activity} ui_list={ui_list} canWrite={canWrite} t={t} functions={{ save: onChangeAction }} />
								</ButtonGroup>
							</Col>
						</Row>
					))}
					{contract.has_microbiological_check ?
						<>
							<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
								<Form.Label column sm="2">{t('structure.labels.has_microbiological_check')}</Form.Label>
								<Col>
									<Form.Check type="radio" name="has_microbiological_check" value={true} checked={element.has_microbiological_check} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
									<Form.Check type="radio" name="has_microbiological_check" value={false} checked={!element.has_microbiological_check} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							</Form.Group>
							{element.has_microbiological_check ?
								<TargetList element={element} functions={functions} t={t} canWrite={canWrite} />
								: ''}
						</>
						: ''}

				</>
				: ''}
		</>
	)
}






/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const TargetList = ({ element, functions, t, canWrite }) => {

	const removeTarget = (index) => {
		let edittarget_list = [...element.target_list]
		edittarget_list.splice(index, 1)
		functions.edit({ name: 'target_list', value: edittarget_list })
	}




	return (
		<Form.Group as={Row} className="border-bottom">
			<Col>
				{canWrite ?
					<Row>
						<Col className="text-end mb-1">
							<ModalTarget is_new={true} t={t} item={element} setItem={(a) => functions.edit(a)} canWrite={canWrite} />
						</Col>
					</Row>
					: ''}
				{element.target_list && element.target_list.length > 0
					?
					<>
						<Row className="mb-1" >
							<Col className="bg-secondary text-light ms-1 text-center" sm="3">{t('micriobiologicalcheck.labels.target')}</Col>
							<Col className="bg-secondary text-light ms-1 text-center" sm="2">{t('micriobiologicalcheck.labels.techniques')}</Col>
							<Col className="bg-secondary text-light ms-1 text-center" sm="2">{t('micriobiologicalcheck.labels.expected_result')}</Col>
							<Col className="bg-secondary text-light ms-1 text-center" sm="2">{t('micriobiologicalcheck.labels.reference_standards')}</Col>
							<Col className="bg-secondary text-light ms-1 text-center" >{t('operations.labels.noncompliance_action')}</Col>
							<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element.target_list.map((objective, k) => (
							<Row key={k} className='border-bottom mb-1 pb-1'>
								<Col sm="3">
									{objective.targets}
								</Col>
								<Col sm="2">
									{objective.techniques}
								</Col>
								<Col sm="2">
									{objective.expected_result} UFC/piastra
								</Col>
								<Col sm="2">
									{objective.reference_standards}
								</Col>
								<Col>
									{objective.action_list && objective.action_list.length > 0 ?
										<>
											{objective.action_list.map((a, ak) => (
												<Row key={ak} className='border-bottom mb-1 pb-1'>
													<Col sm="3">
														{a.min_value}
													</Col>
													<Col>
														{a.description}
													</Col>
												</Row>

											))}
										</> : ''}
								</Col>
								<Col sm="1" className="p-0 text-center">
									{canWrite ?
										<ButtonGroup>
											<ModalTarget item={objective} parent_list={element} setItem={(a) => functions.edit(a)} index={k} t={t} canWrite={canWrite} />
											<Button variant="outline-danger" size="sm" onClick={() => removeTarget(k)}><FontAwesomeIcon icon='trash' /> </Button>
										</ButtonGroup>
										: ''}
								</Col>
							</Row>
						))}
					</>
					: <>
						<Row><Col className="font-italic"> {t('roomtypes.messages.no_targets')}</Col></Row>
					</>
				}
			</Col>
		</Form.Group>
	)
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const TargetNoncomplianceActionList = ({ element, edit, t, canWrite }) => {
	//    console.log("modal", actionList, element.action_list)



	const removeAction = (index) => {
		let editaction_list = [...element.action_list]
		editaction_list.splice(index, 1)
		edit({ name: 'action_list', value: editaction_list })
	}




	return (
		<Form.Group as={Row} className="border-bottom">
			<Col>
				{canWrite ?
					<Row>
						<Col className="text-end mb-1">
							<ModalTargetAction is_new={true} t={t} item={element} setItem={(a) => edit(a)} canWrite={canWrite} />
						</Col>
					</Row>
					: ''}
				{element.action_list && element.action_list.length > 0
					?
					<>
						<Row className="mb-1" >
							<Col className="bg-secondary text-light ms-1" sm="3" >{t('global.labels.value')} </Col>
							<Col className="bg-secondary text-light ms-1" >{t('operations.labels.noncompliance_action')} </Col>
							<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element.action_list.map((a, k) => (
							<Row key={k} className='border-bottom mb-1 pb-1'>
								<Col sm="3">
									{a.min_value}
								</Col>
								<Col>
									{a.description}
								</Col>
								<Col sm="1 p-0 text-center">
									{canWrite ?
										<ButtonGroup>
											<ModalTargetAction item={a} parent_list={element} setItem={(a) => edit(a)} index={k} t={t} canWrite={canWrite} />
											<Button variant="outline-danger" size="sm" onClick={() => removeAction(k)}><FontAwesomeIcon icon='trash' /> </Button>
										</ButtonGroup>
										: ''}
								</Col>
							</Row>
						))}
					</>
					: <>
						<Row><Col className="font-italic"> {t('global.messages.no_noncomplianceaction')}</Col></Row>
					</>
				}
			</Col>
		</Form.Group>
	)
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalTarget({ item, index, setItem, t, is_new, small, parent_list, canWrite }) {
	const newtarget = {
		target: '',
		id: makeid(8),
		techniques: '',
		expected_result: 0,
		action_list: [],
		reference_standards: ''
	}
	const [showTargetEdit, setShowTargetEdit] = useState(false)
	const [curTarget, setCurTarget] = useState(newtarget)
	const [editedElement, setEditedElement] = useState(false)

	const handleCloseTarget = () => setShowTargetEdit(false);
	const handleSaveTarget = async () => {
		setShowTargetEdit(false);
		if (is_new) {
			let newtarget_list = item.target_list ? [...item.target_list] : []
			newtarget_list.push(curTarget)
			setItem({ name: 'target_list', value: newtarget_list })
		} else {
			let newtarget_list = [...parent_list.target_list]
			newtarget_list.splice(index, 1, curTarget)
			setItem({ name: 'target_list', value: newtarget_list })

		}
	}

	const handleShowNewTarget = () => {
		console.log('show new')
		setCurTarget(newtarget)
		setShowTargetEdit(true)
		setEditedElement(false)
	}

	const handleShowEditTarget = () => {
		setCurTarget(item)
		setShowTargetEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerTarget = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curTarget[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurTarget({ ...curTarget, [name]: value })
		setEditedElement(true)

	}




	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewTarget()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewTarget()} variant="outline-primary" size="sm"><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.target_add')}</Button>
				)
				: <Button className="me-1" variant="outline-info" size="sm" onClick={() => handleShowEditTarget()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showTargetEdit} onHide={handleCloseTarget} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('roomtypes.labels.new_target')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('roomtypes.labels.edit_target')}</span>
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('micriobiologicalcheck.labels.target')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="targets" value={curTarget.targets} onChange={(event) => onChangeHandlerTarget(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('micriobiologicalcheck.labels.techniques')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="techniques" value={curTarget.techniques} onChange={(event) => onChangeHandlerTarget(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('micriobiologicalcheck.labels.reference_standards')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="reference_standards" value={curTarget.reference_standards} onChange={(event) => onChangeHandlerTarget(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('micriobiologicalcheck.labels.expected_result')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="expected_result" value={curTarget.expected_result} onChange={(event) => onChangeHandlerTarget(event.target)} />
									
										<InputGroup.Text>
											UFC/piastra
										</InputGroup.Text>
									
								</InputGroup>
							</Col>
						</Form.Group>
						<TargetNoncomplianceActionList element={curTarget} edit={onChangeHandlerTarget} t={t} canWrite={canWrite} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseTarget}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveTarget}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalTargetAction({ item, index, setItem, t, is_new, small, parent_list, canWrite }) {
	const newaction = {
		description: '',
		min_value: '',
	}
	const [showActionEdit, setShowActionEdit] = useState(false)
	const [curAction, setCurAction] = useState(newaction)
	const [editedElement, setEditedElement] = useState(false)

	const handleCloseAction = () => setShowActionEdit(false);
	const handleSaveAction = async () => {
		setShowActionEdit(false);
		if (is_new) {
			let newaction_list = item.action_list ? [...item.action_list] : []
			newaction_list.push(curAction)
			setItem({ name: 'action_list', value: newaction_list })
		} else {
			let newaction_list = [...parent_list.action_list]
			newaction_list.splice(index, 1, curAction)
			setItem({ name: 'action_list', value: newaction_list })

		}
	}

	const handleShowNewAction = () => {
		console.log('show new')
		setCurAction(newaction)
		setShowActionEdit(true)
		setEditedElement(false)
	}

	const handleShowEditAction = () => {
		setCurAction(item)
		setShowActionEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerAction = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curAction[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurAction({ ...curAction, [name]: value })
		setEditedElement(true)

	}




	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewAction()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewAction()} variant="outline-primary" size="sm"><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.action_add')}</Button>
				)
				: <Button className="me-1" variant="outline-info" size="sm" onClick={() => handleShowEditAction()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showActionEdit} onHide={handleCloseAction} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('roomtypes.labels.new_noncomplianceaction')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('roomtypes.labels.edit_action')}</span>
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.value')}</Form.Label>
							<Col>
								<Form.Control type="text" value={curAction.min_value} name="min_value" onChange={(event) => onChangeHandlerAction(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('operations.labels.action')} </Form.Label>
							<Col>
								<Form.Control type="textarea" rows="3" value={curAction.description} name="description" onChange={(event) => onChangeHandlerAction(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseAction}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveAction}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalActivityFormChecks = ({ roomtype, index, activity, functions, t, ui_list, canWrite }) => {

	const { service_dict } = useContext(GlobalDataContext)

	const roomcomposition_dict = roomComposition_dict({ t })
	const newcheck = {
		code: '',
		name: '',
		description: ''
	}

	const newcomponent = {
		name: '',
		type: '',
		quantity: 1
	}

	const newcheckpoint = {
		name: '',
		code: '',
		top: 10,
		left: 10,
		Id: 0,
		element_list: []
	}

	const [check_list, setCheckList] = useState({})
	const [loading, setLoading] = useState(false)
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [showEditActivityChecks, setShowEditActivityChecks] = useState(false)
	//	const [newComponentEdit, setNewComponentEdit] = useState(true)
	//	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showCheckEdit, setShowCheckEdit] = useState(false)
	const [selectedElement, setSelectedElement] = useState(-1)
	const [newCheckEdit, setNewCheckEdit] = useState(true)
	const [curCheck, setCurCheck] = useState(newcheck)
	const [editedElement, setEditedElement] = useState(false)
	const [curActivity, setCurActivity] = useState()
	const [actionIndex, setActionIndex] = useState(-1)
	const [source_roomelements, setSourceRoomelements] = useState([])
	//----check

	const handleCloseActivityChecks = () => setShowEditActivityChecks(false);

	const handleSaveActivityChecks = async () => {
		functions.save(curActivity, index)
		setShowComponentEdit(false)
		setShowEditActivityChecks(false)
		//functions.edit({ name: 'service_list', value: edit_service_list })
	}

	const handleEditActivityChecks = () => {
		setCurActivity(activity)
		console.log("edit activity", activity)
		setShowEditActivityChecks(true)
		setEditedElement(false)
	}



	const setElementCheckList = (mylist) => {
		onChangeHandlerAction({ name: 'check_list', value: mylist })
	}

	const handleDeleteCheck = (el) => {
		console.log(el)
		let editlist = { ...curActivity.check_list }
		//let editlist = {...element.check_list}
		delete editlist[el.id]
		onChangeHandlerAction({ name: 'check_list', value: editlist })
		//functions.edit({name: 'check_list', value: editlist })
	}

	const handleCloseComponent = () => setShowComponentEdit(false);
	const handleEditComponent = () => {
		setShowComponentEdit(true)
	}

	const handleAddCheckPoint = (el) => {
		console.log(el)
		const new_el = { ...newcheckpoint, ...el }
		let editlist = { ...curActivity.check_list }
		editlist[el.id] = new_el
		//functions.edit({name: 'check_list', value: editlist })
		onChangeHandlerAction({ name: 'check_list', value: editlist })
		setEditedElement(true)
	}


	const onChangeHandlerAction = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curActivity[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		let edited_action = { ...curActivity, [name]: value }
		if (!edited_action.check_list) {
			edited_action.check_list = {}
		}
		setCurActivity(edited_action)
		setEditedElement(true)

	}

	useEffect(() => {
		setLoading(false)
	}, [check_list])



	useEffect(() => {
		const furniture = roomtype.furniture_list.map((e) => ({ ...e, category_type: 'furniture_list' }))
		const environment_element = roomtype.environment_element_list.map((e) => ({ ...e, category_type: 'environment_element_list' }))
		const economic_material = roomtype.economic_material_list.map((e) => ({ ...e, category_type: 'economic_material_list' }))
		let newroomcomposition_elements = [...furniture, ...environment_element, ...economic_material]
		newroomcomposition_elements = newroomcomposition_elements.sort(compareName)
		setSourceRoomelements(newroomcomposition_elements)
	}, [])




	return (
		<>
			<Button size="sm" variant="info" onClick={() => handleEditActivityChecks()}><FontAwesomeIcon icon={canWrite ? "pencil-alt" : "info-circle"} /> </Button>

			{curActivity ?
				<Modal show={showEditActivityChecks} onHide={handleCloseActivityChecks} fullscreen backdrop="static" className="bg-secondary">
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>{roomtype.name} | <span className="text-info"><FontAwesomeIcon icon="pencil-alt" />{t('roomtype.labels.edit_activity_checks')}</span> <ServiceBadge service={service_dict[activity.service.id]} freq={true} /> {editedElement ? <FontAwesomeIcon icon="save" /> : ''} </Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>

							<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
								<Form.Label column sm="2">{t('global.labels.lqa')}</Form.Label>
								<Col sm="3">
									<Form.Control type="number" name="lqa" min="0" value={curActivity.lqa} onChange={(event) => onChangeHandlerAction(event.currentTarget)} className="mb-2" />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formStructureComponent" >
								<Col>
									<Row className="mb-2">
										{canWrite ?
											<>
												{showComponentEdit ?
													<Col className="text-end"><Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button></Col>
													:
													<Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleEditComponent()}><FontAwesomeIcon icon="pencil-alt" /> {t('roomtypes.labels.check_edit')}</Button></Col>
												}
											</>
											: ''}
									</Row>
									<Row className="border-bottom h5">
										<Col sm="7">
											{t('structure.labels.check_inserted')}
										</Col>
										{showComponentEdit ?
											<>
												<Col className="text-info">{t('roomtype.labels.avaiable_checks')}</Col>
												{/* 												<Col className="text-end">
													{canWrite ?
													<ModalCheckPoint is_new={true} check_list={check_list} setCheckList={setCheckList} t={t} canWrite={canWrite}/>
														: ''}
												</Col> */}
											</>
											: ''}
									</Row>
									<Row className="mb-1">
										{roomtype.image_reference && !showComponentEdit
											?
											<Col md="auto" sm="auto">
												<DndProvider backend={HTML5Backend}>
													<ControllerImageContainer pointlist={curActivity.check_list} setPoint={setElementCheckList} setSelectedElement={setSelectedElement} selectedElement={selectedElement} background={roomtype.image_reference} service={ui_list.service_dict[curActivity.service.id]} zoom={1.3} />
												</DndProvider>
											</Col>
											: ''}

										{curActivity.check_list && Object.keys(curActivity.check_list).length > 0 ?
											<Col>
												<Row className="mb-1">
													<Col className="bg-secondary text-light ms-1">{t('global.labels.element')} </Col>
													<Col className="bg-secondary text-light ms-1">{t('global.labels.category')} </Col>
													<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
												</Row>
												{Object.keys(curActivity.check_list).map((keyObj, key) => (
													<Row key={key} className="border-bottom mb-1 pb-1">
														<Col>
															<Button size="sm" variant="secondary" onClick={() => setSelectedElement(keyObj)}><FontAwesomeIcon icon="map-marker-alt" className={selectedElement === keyObj ? "text-warning" : ""} /></Button>
															&nbsp;
															{curActivity.check_list[keyObj].name}
														</Col>
														<Col>{curActivity.check_list[keyObj].category}</Col>
														<Col sm="1" className="text-center p-0">
															{canWrite ?
																<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteCheck(curActivity.check_list[keyObj])} /> </Button>
																: ''}
														</Col>
													</Row>))
												}
											</Col>
											: <Col md="auto" className="font-italic"> {t('roomtype.messages.no_servicecheck')}</Col>
										}
										{showComponentEdit ?
											<>
												{source_roomelements && source_roomelements.length > 0 ?

													<Col className="editSection">
														<>
															<Row className="mb-1" >
																<Col className="bg-info text-light ms-1 text-center" sm="1">{t('global.labels.add')}</Col>
																<Col className="bg-info text-light ms-1">{t('global.labels.element')} </Col>
																<Col className="bg-info text-light ms-1">{t('global.labels.type')} </Col>
																<Col className="bg-info text-light ms-1">{t('global.labels.category')} </Col>
															</Row>
															{source_roomelements.map((i, key) => (
																<Row key={i.id} className={styles.source_row}>
																	<Col className="p-0 text-center" sm="1">
																		{curActivity.check_list[i.id] ?
																			<Button size="sm" variant="secondary" disabled><FontAwesomeIcon icon="check" /> <FontAwesomeIcon icon="plus-square" /> </Button>
																			:
																			<Button size="sm" variant="light" onClick={() => handleAddCheckPoint(i)}><FontAwesomeIcon className="text-warning" icon="arrow-left" /><FontAwesomeIcon icon="plus-square" /> </Button>
																		}
																	</Col>
																	<Col>{i.name}</Col>
																	<Col>{roomcomposition_dict[i.category_type].title}</Col>
																	<Col>{i.category}</Col>
																</Row>))
															}
														</>
													</Col>
													:
													<Col className="font-italic">
														{t('roomtype.messages.no_elements')}
													</Col>
												}
											</>
											: ''}
									</Row>
								</Col>
							</Form.Group>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseActivityChecks}>
							{t('modal.close')}
						</Button>
						{canWrite ?
							<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveActivityChecks}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
							: ''}
					</Modal.Footer>
				</Modal>



				: ''}
		</>
	)
}




export default translate()(ModalRoomType)
