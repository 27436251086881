import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Card, ButtonGroup } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'
import QRCode from 'qrcode.react'
import { useParams } from "react-router-dom"
import { useReactToPrint } from 'react-to-print';
import ReactDOM from 'react-dom';



import LocaleContext from './contexts/locale.js'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import { getElementPreview, getBaseObjectValue, getValue } from "../util/ui_utils.js"
import systemparams from './constants/systemparams.js';








const getRoomData = (element, locale, structureElements) => {
    let short_data = ''
    console.log(structureElements.all_department_dict[element.parent_list[3]].name)
    short_data = short_data + 'numero: ' + element.name
    if (element.roomtype){
        short_data = short_data + '; \ntipologia: ' + element.roomtype.name
    }
    short_data = short_data + '; \nreparto: ' + structureElements.all_department_dict[element.parent_list[3]].name
    short_data = short_data + '; \ncdc: ' + element.cdc
    short_data = short_data + '; \ndimensione: ' + element.size
    short_data = short_data + '; \narea di rischio: ' + getBaseObjectValue(element.risk_area, 'name', locale)
    short_data = short_data + '; \ntipologia pavimentazione: ' + getBaseObjectValue(element.floor, 'name', locale)
    short_data = short_data + '; \nid: ' + element.id
    return short_data
}






function ModalRoomPreview({ element }) {
    const t = useTranslate();
    const { domain } = useContext(DomainContext)
    const { locale } = useContext(LocaleContext)
	const { contract, roomtype_dict, structureElements  } = useContext(GlobalDataContext)

    let { cid } = useParams();
    let contractId = cid
    const componentRef = useRef();

    const [showRoomPreview, setShowRoomEdit] = useState(false)

    const handleClosePreviewEmployee = () => setShowRoomEdit(false);

    const handleShowPreviewEmployee = () => {
        setShowRoomEdit(true)
    }
    const handlePrint = useReactToPrint({
        pageStyle: pageStyle,
        content: () => componentRef.current,
    });


/*     const savePdf = () =>{
        ReactDOM.render(<Viewer  element={element}/>, document.getElementById('root'));


    } */

    return (
        <>
            <Button className="me-1" variant="outline-info" onClick={() => handleShowPreviewEmployee()}><FontAwesomeIcon icon="print" /> Stampa QrCode</Button>
            <Modal show={showRoomPreview} onHide={handleClosePreviewEmployee} backdrop="static" className="bg-secondary">
                <Modal.Header closeButton >
                    <Modal.Title>
                        {structureElements.all_department_dict[element.parent_list[3]].name} - 
                        {element.name} <i>{getValue(roomtype_dict[element.roomtype.id], 'name')}</i>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Card className="qrcard" ref={componentRef}>
                            <Card.Header className="p-2">
                                {structureElements.all_department_dict[element.parent_list[3]].name}
                                <br/>
                                {element.name} <i>{getValue(roomtype_dict[element.roomtype.id], 'name')}</i> 
                            </Card.Header>
                            <Card.Body>
                                <Row className="">
                                    <Col>
                                    <QRCode size={100} value={
                                        `${systemparams.public_url}${element.id}`
                                    } />
                                    </Col>
                                    <Col className="p-0">
                                        <Row className="my-auto h-100">
                                            <Col className="text-center my-auto">
                                                {getElementPreview(contract, '90px')}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreviewEmployee}>
                        {t('modal.close')}
                    </Button>
                    <Button variant="info" onClick={handlePrint}>
                        {t('modal.print')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
/* 
const Viewer = ({element}) =>{
    const t = useTranslate();
    const { domain } = useContext(DomainContext)
    const { locale } = useContext(LocaleContext)
	const { contract, roomtype_dict, structureElements  } = useContext(GlobalDataContext)

    return (
                    <PDFViewer>
                        
        <MyDocument structureElements={structureElements} element={element} roomtype_dict={roomtype_dict} locale={locale} contract={contract} />
                    </PDFViewer>

    )
}
const styles = StyleSheet.create({
  page: { backgroundColor: 'tomato' },
  section: { color: 'white', textAlign: 'center', margin: 30 }
});

// Create Document Component
const MyDocument = ({structureElements, element, roomtype_dict, locale, contract }) => (
    <Document>
      <Page size="A4" style={styles.page}>
                        <Card className="qrcard" >
                            <Card.Header >
                                {structureElements.all_department_dict[element.parent_list[3]].name}
                                <br/>
                                {element.name} <i>{getValue(roomtype_dict[element.roomtype.id], 'name')}</i> 
                            </Card.Header>
                            <Card.Body>
                                <Row className="">
                                    <Col className="p-1">
                                        <QRCode value={getRoomData(element, locale, structureElements)} size={110}/>
                                    </Col>
                                    <Col className="p-0">
                                        <Row className="my-auto h-100">
                                            <Col className="text-center my-auto">
                                                {getElementPreview(contract, '90px')}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
      </Page>
    </Document>
  );
 */
const pageStyle = `
html:{
    size: 90mm 60mm;
    backgroundColor: '#ff0000';

}
    .qrcard{
        width: 90mm;
        height: 60mm;
    }
  @page {
    size: 90mm 60mm;
    backgroundColor: '#ff0000';
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {

  }
`;



export default translate()(ModalRoomPreview)