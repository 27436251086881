import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Image, ProgressBar, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import DateTime from 'react-datetime';
import { differenceInMonths } from 'date-fns'
import CurrencyInput from 'react-currency-input';

import DomainContext from '../../components/contexts/domainContext'
import EditContractContext from '../../components/contexts/editContractContext.js';
import LocaleContext from '../../components/contexts/locale.js'

import { contractScope_dict, contractSector_dict, contractStatus_list, contractType_dict, popoverTiming } from "../../components/constants/global_definitions"

import PhoneList from "../../components/PhoneList.js"
import LaboratoryList from "../../components/LaboratoryList.js"
import ModalBaseObject from "../../components/ModalBaseObject"
import ElementFormMedia from "../../components/FormMedia"
import AlertSection from "../../components/AlertSection.js"
import DeleteConfirmationSection from "../../components/DeleteConfirmationSection.js"
import { renderPhoneList, RiskareaBadge } from '../../components/StructureGraphicElements.js'
import ServiceamountList, { ServiceamountHourList } from "../../components/ServiceAmount"

import { getSelectedElement, getValue, getFloatNumber, myTimestampToDate, compareName, checkComponentPresence, getAmountValue, myDateToTimestamp, getBaseObjectValue, compareDateParam } from "../../util/ui_utils.js"

import { getServiceList } from '../../Firebase2/Storage2/dbService.js';
import { getContractListExpanded, addContract, editContract } from '../../Firebase2/Storage2/dbContract';
import { getMessageGroupList, addMessageGroup, editMessageGroup, deleteMessageGroup } from '../../Firebase2/Storage2/dbMessageGroup';
import { getStructureList } from '../../Firebase2/Storage2/dbBaseCompany';
import { contractFromContract, contractFromTemplate, templateFromContract } from "../../components/FirebaseApi/template_api.tsx"
import { getContractDescription } from '../page_sections/contract_common';


import firebase from 'firebase/compat/app'
import { uploadFileComplete } from '../../Firebase2/Storage2/dbFileStorage';
import { firebaseAuth } from '../../provider/AuthProvider';
import { ElementStructureSkel } from './modalsection/sectionContractStructureskel';



const section = 'contract'

const newcontract = {
    code: '',
    name: '',
    company: {},
    logo: '',
    website: '',
    structure: {},
    extension_month: 0,
    extension_month_active: false,
    geographic_area: '',
    auction_base: 0,
    rebate_rate: 0,
    object: '',
    ownedBy: [],
    startDate: firebase.firestore.Timestamp.now(),
    endDate: firebase.firestore.Timestamp.now(),
    creation_date: firebase.firestore.Timestamp.now(),
    end_precheck: firebase.firestore.Timestamp.now(),
    status: 'draft',
    type: 'hospital',
    scope: 'hospital',
    sector: 'private',
    cig: '',
    execution_mode: 'auto',
    risk_area_amount: {},
    serviceamount_list: [],
    servicehouramount_list: [],
    day_alert: 15,
    yeld_mq: 18,
    autocheck: [],
    contradictory: [],
    client_checks: [],
    check_mode: 'complete',
    control_validation_mode: 'global',
    is_archived: false,
}


const newcompany = {
    name: '',
    code: '',
    vat: '',
    cf: '',
    address: {
        street: '',
        number: '',
        zip: '',
        city: '',
        state: ''
    },
    phone_list: [],
    email: '',
    pec: '',
    website: '',
    logo: '',
    referent_list: [],
    management_group_list: [],
    bank: '',
    payment_mode: '',
    sdi: '',
    note: '',
    supply_list: [],
    structure_list: []
}


const contract_scope_icons = {
    "hospital": "./icone_contratto/ospedale.png",
    "retirement": "./icone_contratto/casariposo.png",
    "clinic": "./icone_contratto/poliambulatorio.png",
    "community": "./icone_contratto/comunita.png",
    "school": "./icone_contratto/scuola.png",
    "office": "./icone_contratto/ufficio.png",
    "shopping_center": "./icone_contratto/centrocommerciale.png",
    "bank": "./icone_contratto/banca.png",
    "railroad": "./icone_contratto/ferrovia.png",
    "highway": "./icone_contratto/autostrada.png",
    "airport": "./icone_contratto/aeroporto.png",
    "hotel": "./icone_contratto/hotel.png",
    "catering": "./icone_contratto/ristorante.png",
    "residential": "./icone_contratto/residenziale.png",
    "prison": "./icone_contratto/carcere.png",
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalContract = ({ is_new, element, setElementList, setElement, element_list, ui_list, t, canWrite, setUiList, isGlobal, isGlobalAdmin }) => {
    const { domain, domain_data } = useContext(DomainContext)
    const { userData } = useContext(firebaseAuth)
    const childRefAlert = useRef()

    const contract_scope_dict = contractScope_dict({ t })
    const contract_sector_dict = contractSector_dict({ t })
    const contract_type_dict = contractType_dict({ t })

    const [isPreparing, setIsPreparing] = useState(false)
    const [showEdit, setShow] = useState(false);
    const [newEdit, setNew] = useState(true);
    const [curElement, setCurrentElement] = useState(element)
    const [editedElement, setEditedElement] = useState(false)
    const { structure_list, setStructureList } = useContext(EditContractContext)
    const [service_list, setServiceList] = useState([])
    const [msggroup_list, setMsggroupList] = useState([])
    const [new_contract, setNewContract] = useState({ mode: 'new', source: -1 })
    const [isSaving, setIsSaving] = useState(false)
    const [showContractWizard, setShowContractWizard] = useState(false)
    const [showContractTemplate, setShowContractTemplate] = useState(false)
    const [uploadingState, setUploadingState] = useState(-1)


    const onUpdateData = (data, b) => {
        console.log("update", data, b)
        setUiList({ ...ui_list, [data]: b.list })
    }

    const cb_progress = (status, byte, total) => {
        console.log(status, byte, total)
        try {
            setUploadingState(100 * byte / total)
        } catch (e) {
            console.warn("handleSaveMedia - error: ", e)
        }
    }

    const handleClose = () => setShow(false);
    const handleSave = async () => {
        if (newEdit) {
            let return_data = await addContract(domain, curElement)
            console.log(return_data)
            if (return_data && return_data.error) {
                //childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
            } else {
                const newlist = [...element_list]
                newlist.push(return_data)
                setEditedElement(false)
                setElementList(newlist)
                childRefAlert.current.addAlert('success', 'salvataggio eseguito correttamente')
                //        setShow(false);
            }
        } else {
            console.group(domain, curElement)
            const cb_save = async (url, tracker) => {
                console.log("END FILE", url)
                if (url) {
                    setUploadingState(-1)
                    curElement.ecolabel_logo = url
                    curElement.ecolabel_tracker = tracker
                }
                delete curElement.ecolabel_file
                let return_data = await editContract(domain, curElement.id, curElement)
                console.log(return_data)
                console.groupEnd()
                if (return_data && return_data.error) {
                    //				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
                } else {
                    const newlist = [...element_list]
                    const c = checkComponentPresence(curElement, element_list, 'id')
                    if (c >= 0) {
                        newlist.splice(c, 1, curElement)
                    }
                    childRefAlert.current.addAlert('success', 'salvataggio eseguito correttamente')
                    setEditedElement(false)
                    setElementList(newlist)
                    //      setShow(false);
                }

            }
            if (curElement.ecolabel_file) {
                const mypath = domain + '/' + section
                const mediainfo = { description: 'ecolabel_logo', source_name: curElement.ecolabel_file[0].name, section: section, item_reference: {} }
                console.log(mediainfo)
                console.log(domain, '0', mypath, curElement.ecolabel_file)
                let file_data = uploadFileComplete(domain, '0', mypath, curElement.ecolabel_file[0], curElement.ecolabel_file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
                console.log("FILE", file_data)

            } else {
                cb_save()

            }
        }
    }


    const handleShowEdit = async (wizard) => {
        setCurrentElement(element)
        let message_group_list = await getMessageGroupList(domain, element.id)
        message_group_list = message_group_list.sort(compareName)
        setUiList({ ...ui_list, message_group_list: message_group_list })
        const newlist = await getStructureList(domain, element.id)
        let risk_area_summary = {}
        newlist.map(s => {
            Object.keys(s.risk_area_summary).map(ra => {
                if (risk_area_summary[ra]) {
                    risk_area_summary[ra].size += s.risk_area_summary[ra].size
                    risk_area_summary[ra].room_count += s.risk_area_summary[ra].room_count
                } else {
                    risk_area_summary[ra] = { ...ui_list.risk_area_dict[ra], size: s.risk_area_summary[ra].size, count: s.risk_area_summary[ra].room_count ? s.risk_area_summary[ra].room_count : 0 }
                }
                return true
            })
            return true
        })
        console.log("RISK_AREA", risk_area_summary)
        element.risk_area_summary = risk_area_summary
        setCurrentElement(element)
        setStructureList(newlist)
        let service_list = await getServiceList(domain, element.id)
        service_list.sort(compareName)
        setServiceList(service_list)
        setNew(false)
        setShow(true)
        if (!wizard) {
            setEditedElement(false)
        }
    }

    const onChangeHandler = (el) => {
        let { name, value, type } = el
        let sub = undefined;
        try {
            sub = el.getAttribute('sub') || el.sub
        } catch (err) { }
        console.log(name, value, type, sub)
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curElement[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck && oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                } else {
                    oldcheck = [value]
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        } else if (type === 'file') {
            console.log("FILES", el.files)
            value = el.files

        }
        if (sub) {
            value = { ...curElement[sub], [name]: value }
            name = sub;
        }
        console.log(name, value)
        setCurrentElement({ ...curElement, [name]: value })
        setEditedElement(true)
    }



    const onChangeHandlerCompany = (el) => {
        let { name, value, type } = el
        let sub = undefined;
        try {
            sub = el.getAttribute('sub') || el.sub
        } catch (err) { }
        console.log(name, value, type, sub)
        let editCompany = newcompany
        if (curElement.company) {
            editCompany = { ...curElement.company }
        }
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...editCompany[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        if (sub) {
            value = { ...editCompany[sub], [name]: value }
            name = sub;
        }
        editCompany[name] = value
        console.log(name, value)
        console.log(editCompany)
        setCurrentElement({ ...curElement, company: editCompany })
        setEditedElement(true)
    }





    //wizard 
    const handleShowWizard = () => {
        setShow(false)
        setNewContract({ name: '', mode: 'new', contract_selected: -1, template_selected: -1 })
        setShowContractWizard(true)
    }

    const handleCloseWizard = () => {
        setShowContractWizard(false)
    }

    const updateContractWizard = (el) => {
        console.log(el.name, el.value)
        if (el.name === 'new_contract_mode') {
            setNewContract({ ...new_contract, 'mode': el.value, 'contract_selected': -1, 'template_selected': -1 })
        } else if (el.name === 'name') {
            setNewContract({ ...new_contract, 'name': el.value })
        } else {
            if (new_contract.mode === 'template') {
                setNewContract({ ...new_contract, 'template_selected': el.value })
            } else {
                setNewContract({ ...new_contract, 'contract_selected': el.value })
            }
        }
    }

    const handleCreateNew = async () => {
        setIsPreparing(true)
        if (new_contract.mode === 'template') {
            const return_data = await contractFromTemplate(new_contract.name, domain, new_contract.template_selected)
            console.log(return_data)
            if (return_data.error) {
            } else {
                let newelement_list = await getContractListExpanded(domain)
                newelement_list = newelement_list.sort(compareName)
                setElementList(newelement_list)
                setEditedElement(true)
                //handleShowEdit(getSelectedElement(, newelement_list, 'id'), true)
            }
        } else if (new_contract.mode === 'other_contract') {
            const return_data = await contractFromContract(new_contract.name, domain, new_contract.contract_selected)
            if (return_data.error) {

            } else {
                let newelement_list = await getContractListExpanded(domain)
                newelement_list = newelement_list.sort(compareName)
                setElementList(newelement_list)
                setEditedElement(true)
                //handleShowEdit(getSelectedElement(, newelement_list, 'id'), true)
            }
            console.log("contract_return ", return_data)
        } else {
            handleShowNew()
        }
        setShowContractWizard(false)
    }



    const handleShowNew = () => {
        setCurrentElement(newcontract)
        setIsPreparing(false)
        setNew(true)
        setShow(true)
        setEditedElement(false)
    }


    //template
    const handleSaveTemplate = () => {
        setNewContract({ name: 'template da ' + curElement.name, mode: 'new', contract_selected: -1, template_selected: -1 })
        setShowContractTemplate(true)
        setShow(false)
    }
    const handleCloseTemplate = () => {
        setShowContractTemplate(false)
    }

    const updateTemplate = (el) => {
        console.log(el.name, el.value)
        if (el.name === 'name') {
            setNewContract({ ...new_contract, 'name': el.value })
        }
    }

    const handleCreateTemplate = async () => {
        setIsSaving(true)
        const return_data = await templateFromContract(curElement.id, domain, new_contract.name)
        console.log(return_data)
        if (return_data.error) {
            childRefAlert.current.addAlert('danger', 'Error:' + return_data.error)
        } else {
            childRefAlert.current.addAlert('success', 'creazione template eseguita correttamente')
        }
        setIsSaving(false)
        setShowContractTemplate(false)
    }



    const isDisabled = () => {
        return false
    }

    return (
        <>
            {is_new ?
                <Button variant="primary" onClick={handleShowWizard}><FontAwesomeIcon icon="plus-square" /> {t('contract.buttons.add_contract')}</Button>
                :
                [canWrite ?
                    <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /></Button>
                    :
                    <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="eye" /></Button>
                ]
            }
            {showEdit ?
                <Modal key={"dlg_edit"} show={showEdit} onHide={handleClose} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
                    <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                        <Modal.Title>
                            {newEdit ?
                                <>
                                    <FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('contract.labels.new_contract')}</span>
                                </>
                                :
                                [canWrite ?
                                    <>
                                        <FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('contract.labels.edit_contract')}</span>
                                    </>
                                    :
                                    <>
                                        <FontAwesomeIcon icon="eye" /> <span className="text-info">{t('contract.labels.info_contract')}</span>
                                    </>
                                ]
                            }
                            : {curElement.name}&nbsp;
                            {curElement.is_archived ? <>({t('contract.labels.archived')})</> : ''}
                            {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AlertSection ref={childRefAlert} />
                        <Container fluid>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tabCompany">
                                <Row>
                                    <Col sm={3} >
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabCompany">{t('company.labels.client')} </Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item disabled={true} variant="secondary" className="mb-1">
                                                <Nav.Link eventKey="tabStructureSkel">Definizione Strutture</Nav.Link>
                                            </Nav.Item> */}
                                            <Nav.Item variant="secondary" className="mb-1">
                                                <Nav.Link eventKey="tabDetails">{t('company.labels.details')} </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabAmounts">{t('global.labels.amounts')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabReferents">{t('global.labels.referents')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabManagementGroup">{t('global.labels.management_group')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabChecks">{t('global.labels.checks')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabPublicData">Dati Pubblici</Nav.Link>
                                            </Nav.Item>
                                            {canWrite ?
                                                <Nav.Item className="mb-1">
                                                    <Nav.Link eventKey="tabTickets" disabled={newEdit}>{t('global.labels.tickets')}</Nav.Link>
                                                </Nav.Item>
                                                : ''}
                                            <Nav.Item className="mb-1">
                                                <Nav.Link variant="secondary" eventKey="tabMedia" disabled={newEdit}>{t('contracts.labels.imagesdocs')} </Nav.Link>
                                            </Nav.Item>
                                            {canWrite ?
                                                <Nav.Item className="mb-1">
                                                    <Nav.Link variant="secondary" eventKey="tabArchive" disabled={newEdit}>{t('global.labels.archive')} </Nav.Link>
                                                </Nav.Item>
                                                : ''}
                                        </Nav>
                                    </Col>
                                    <Col>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tabDetails">
                                                <ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} canWrite={canWrite} isGlobal={isGlobal} isGlobalAdmin={isGlobalAdmin} uploadingState={uploadingState} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabCompany">
                                                <ElementFormCompany elem={curElement} t={t} functions={{ edit: onChangeHandlerCompany }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="tabStructureSkel">
                                                <ElementStructureSkel element={curElement} t={t} functions={{ edit: onChangeHandlerCompany }} canWrite={canWrite} />
                                            </Tab.Pane> */}
                                            <Tab.Pane eventKey="tabAmounts">
                                                <ElementFormAmounts disabled={newEdit} element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} service_list={service_list} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabReferents">
                                                <ElementFormReferent element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabManagementGroup">
                                                <ElementFormManagementGroup element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabPublicData">
                                                <ElementFormPublicData element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabChecks">
                                                <ElementFormChecks disabled={newEdit} element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} service_list={service_list} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabTickets">
                                                <ElementFormTickets disabled={newEdit} element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabMedia">
                                                <ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} section={section} canWrite={canWrite} />
                                            </Tab.Pane>
                                            {canWrite ?
                                                <Tab.Pane eventKey="tabArchive">
                                                    <ElementFormArchive element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} />
                                                </Tab.Pane>
                                                : ''}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('modal.close')}
                        </Button>
                        {canWrite ?
                            <Button disabled={isDisabled()} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
                                <FontAwesomeIcon icon="save" /> {t('modal.save')}
                            </Button>
                            : ''}
                        {console.log(".....isGlobal", isGlobal)}
                        {canWrite && userData.isGlobalAdmin ?
                            <Button variant="outline-info" className="ms-4" onClick={handleSaveTemplate}>
                                <FontAwesomeIcon icon="save" /> {t('modal.save_template')}
                            </Button>
                            : ''}
                    </Modal.Footer>
                </Modal>


                : ''}




            <Modal key={"dlg_wizard"} show={showContractWizard} onHide={handleCloseWizard} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon="plus-square" /> {t('contract.labels.new_contract')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} className="border-bottom mb-1 pb-1">
                            <Col>
                                <Form.Check type="radio" name="new_contract_mode" value="new" checked={new_contract.mode === 'new'} label={t('contract.labels.new_empty')} onChange={(event) => updateContractWizard(event.currentTarget)} />
                            </Col>
                            <Col>
                                <Form.Check type="radio" name="new_contract_mode" value="template" checked={new_contract.mode === 'template'} label={t('contract.labels.system_template')} onChange={(event) => updateContractWizard(event.currentTarget)} />
                            </Col>
                            <Col>
                                <Form.Check type="radio" name="new_contract_mode" value="other_contract" disabled={element_list.length === 0} checked={new_contract.mode === 'other_contract'} label={t('contract.labels.other_contract')} onChange={(event) => updateContractWizard(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        {new_contract.mode === 'template' ?
                            <>
                                <Form.Group as={Row} className="border-bottom mb-1">
                                    <Form.Label column sm="2">{t('contract.labels.select_template')}</Form.Label>
                                    <Col>
                                        <Form.Control as="select" value={new_contract.template_selected} name="template_selected" className="mb-2" onChange={(event) => updateContractWizard({ name: 'template_selected', type: 'select', value: event.target.value })}  >
                                            <option value="-1" >{t('contract.labels.select_template_option')}</option>
                                            {ui_list.template_list.map((f, k) => (
                                                <option key={k} value={f.id} >{f.name} - {getValue(contract_scope_dict[f.scope], 'label')} - {getValue(contract_sector_dict[f.sector], 'label')}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            {getContractDescription(getSelectedElement(new_contract.template_selected, ui_list.template_list, 'id'), t)}
                                        </Form.Text>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                                    <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="text" name="name" value={new_contract.name} onChange={(event) => updateContractWizard(event.target)} className="mb-2" />
                                        <Form.Text className="text-muted">
                                            {t('contract.labels.contract_name')}
                                        </Form.Text>
                                    </Col>
                                </Form.Group>
                            </>
                            : [new_contract.mode === 'other_contract' ?
                                <>
                                    <Form.Group as={Row} className="border-bottom mb-1">
                                        <Form.Label column sm="2">{t('contract.labels.select_contract_title')}</Form.Label>
                                        <Col>
                                            <Form.Control as="select" value={new_contract.contract_selected} name="contract_selected" className="mb-2" onChange={(event) => updateContractWizard({ name: 'contract_selected', type: 'select', value: event.target.value })}  >
                                                <option value="-1" >{t('contract.labels.select_contract')}</option>
                                                {element_list.map((f, k) => (
                                                    <option key={k} value={f.id} >{f.name}</option>
                                                ))}
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                {getContractDescription(getSelectedElement(new_contract.contract_selected, element_list, 'id'), t)}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                                        <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                                        <Col sm="8">
                                            <Form.Control type="text" name="name" value={new_contract.name} onChange={(event) => updateContractWizard(event.target)} className="mb-2" />
                                            <Form.Text className="text-muted">
                                                {t('contract.labels.contract_name')}
                                            </Form.Text>
                                        </Col>
                                    </Form.Group>
                                </>

                                : '']
                        }

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {!isPreparing ?
                        <Button variant="secondary" onClick={handleCloseWizard}>
                            {t('modal.close')}
                        </Button>
                        : ''}
                    <Button variant="primary" disabled={isPreparing} onClick={handleCreateNew}>
                        {isPreparing ?
                            <>
                                Creazione contratto in corso. L'operazione potrebbe durare qualche minuto
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </>
                            : <>
                                {t('modal.continue')}
                            </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>




            {userData.isGlobalAdmin ?
                <Modal key={'modal_template'} show={showContractTemplate} onHide={handleCloseTemplate} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FontAwesomeIcon icon="plus-square" /> {t('contract.labels.new_template')} ({new_contract.name})
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                                <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="text" name="name" value={new_contract.name} onChange={(event) => updateTemplate(event.target)} className="mb-2" />
                                </Col>
                            </Form.Group>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" disabled={isSaving} onClick={() => handleCloseTemplate()}>
                            {t('modal.close')}
                        </Button>
                        <Button variant="primary" disabled={isSaving} onClick={() => handleCreateTemplate()}>
                            {isSaving ? <Spinner animation="border" role="status" size="sm">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                                : ''}
                            {t('modal.continue')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                : ''}





        </>
    )

}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, ui_list, t, canWrite, isGlobalAdmin, uploadingState }) {
    const { locale } = useContext(LocaleContext)
    const working_day_list = [3, 4, 9, 13, 17, 22, 26, 30]

    const status_list = contractStatus_list({ t })

    const scope_list = Object.values(contractScope_dict({ t }))

    return (
        <>
            <Form.Group as={Row} className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.geographic_area')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="geographic_area" value={element.geographic_area} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.object')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="textarea" rows="3" name="object" value={element.object} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                </Col>
            </Form.Group>
            {element.sector === 'public' ?
                <Form.Group as={Row} className="border-bottom mb-1">
                    <Form.Label column sm="2">Cig</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" name="cig" value={element.cig} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    </Col>
                </Form.Group>
                : ''
            }
            <Form.Group as={Row} className="border-bottom mb-1">
                <Form.Label column sm="2">Divisione ecolabel</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="ecolabel_description" value={element.ecolabel_description} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom pb-1">
                <Form.Label column sm="2">Ecolabel logo</Form.Label>
                <Col>
                    <Form.Control type="file" disabled={!element.id} name="ecolabel_file" onChange={(event) => functions.edit(event.target)} />
                </Col>
                <Col>
                    {element.ecolabel_logo
                        ?
                        <>

                            <Image style={{ height: '50px' }} src={element.ecolabel_logo} fluid />
                            <Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'ecolabel_file', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
                        </>
                        : ''}
                </Col>
            </Form.Group>
            {uploadingState > 0 ?
                <Row>
                    <Col>
                        <ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
                    </Col>
                </Row>
                : <></>}
            <Form.Group as={Row} className="border-bottom mb-1">
                <Form.Label column sm="2">n° certificazione</Form.Label>
                <Col sm="4">
                    <Form.Control type="text" name="ecolabel_number" value={element.ecolabel_number} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                </Col>
                {element.ecolabel_number && element.ecolabel_number.length > 0 &&
                    <Col sm="4">
                        <DateTime className="mb-2" initialValue={myTimestampToDate(element.ecolabel_expiration)} dateFormat='DD/MM/YYYY' timeFormat={false} locale="it" closeOnSelect={true} name="ecolabel_expiration" onChange={(e) => functions.edit({ name: 'ecolabel_expiration', value: myDateToTimestamp(e) })} />
                        <Form.Text className="text-muted">
                            {t('contract.labels.end_validity')}
                        </Form.Text>
                    </Col>
                }
            </Form.Group>
            <Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.working_days')}</Form.Label>
                <Col sm="8">
                    <Form.Control as="select" value={element.working_days || 30} name="working_days" className="mb-2" onChange={(event) => functions.edit({ name: 'working_days', type: 'select', value: event.target.value })}  >
                        {working_day_list.map((f, k) => (
                            <option key={k} value={f} >{f}</option>
                        ))}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        {t('global.labels.monthly')}
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                <Form.Label column sm="2">{t('contract.labels.length')}</Form.Label>
                <Col sm="3">
                    <DateTime initialValue={myTimestampToDate(element.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'startDate', value: myDateToTimestamp(e) })} />
                    <Form.Text className="text-muted">
                        {t('contract.labels.start')}
                    </Form.Text>
                </Col>
                <Col sm="3">
                    <DateTime initialValue={myTimestampToDate(element.end_precheck)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'end_precheck', value: myDateToTimestamp(e) })} />
                    <Form.Text className="text-muted">
                        {t('contract.labels.end_precheck')}
                    </Form.Text>
                </Col>
                <Col sm="3">
                    <DateTime initialValue={myTimestampToDate(element.endDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'endDate', value: myDateToTimestamp(e) })} />
                    <Form.Text className="text-muted">
                        {t('contract.labels.end')}
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                <Form.Label column sm="2">{t('contract.labels.extension')}</Form.Label>
                <Col sm="2">
                    <Form.Check type="radio" name="extension_month_active" value={true} checked={element.extension_month_active} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="extension_month_active" value={false} checked={!element.extension_month_active} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Text className="text-muted">
                        {t('contract.labels.active')}
                    </Form.Text>
                </Col>
                {element.extension_month_active === true &&
                    <Col sm="3">
                        <InputGroup className="mb-2" >
                            <Form.Control type="number" min="0" name="extension_month" value={element.extension_month} onChange={(event) => functions.edit(event.target)} />
                            <InputGroup.Text>
                                {t('global.labels.months')}
                            </InputGroup.Text>
                        </InputGroup>
                        <Form.Text className="text-muted">
                            {t('contract.labels.length')}
                        </Form.Text>
                    </Col>
                }
            </Form.Group>
            <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.day_alert')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="number" min="15" name="day_alert" value={element.day_alert} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="type" value="hospital" checked={element.type === 'hospital'} label={t('contract.labels.type_hospital')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="type" value="civil" checked={element.type === 'civil'} label={t('contract.labels.type_civil')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.scope')}</Form.Label>
                {element.type === 'hospital' ?
                    <Col>
                        {scope_list.filter(s => s.type === 'hospital').map(s => (
                            <Form as={Row} inline key={s.id}>
                                <Col xs="auto">
                                    <img height="20" alt="" src={s.icon} />
                                </Col>
                                <Col>
                                    <Form.Check type="radio" name="scope" value={s.id} checked={element.scope === s.id} label={s.label} onChange={(event) => functions.edit(event.currentTarget)} />
                                </Col>
                            </Form>

                        ))}

                    </Col>
                    :
                    <Col>
                        {scope_list.filter(s => s.type === 'civil').map(s => (
                            <Form inline key={s.id}>
                                <Form.Label>
                                    <img height="20" alt="" src={s.icon} />
                                </Form.Label>
                                <Form.Check type="radio" name="scope" value={s.id} checked={element.scope === s.id} label={s.label} onChange={(event) => functions.edit(event.currentTarget)} />
                            </Form>

                        ))}

                    </Col>
                }
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.sector')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="sector" value="private" checked={element.sector === 'private'} label={t('contract.labels.scope_private')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="sector" value="public" checked={element.sector === 'public'} label={t('contract.labels.scope_public')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            {element.scope === 'public' ?
                <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                    <Form.Label column sm="2">{t('global.labels.cig')}</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" name="cig" value={element.cig} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Col>
                </Form.Group>
                : ''
            }
            <Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
                <Form.Label column sm="2">{t('contract.labels.creation_date')}</Form.Label>
                <Col sm="5">
                    <DateTime className="mb-2" initialValue={myTimestampToDate(element.creation_date)} dateFormat='DD/MM/YYYY' timeFormat={false} locale="it" closeOnSelect={true} name="creation_date" onChange={(e) => functions.edit({ name: 'creation_date', value: myDateToTimestamp(e) })} />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.status')}</Form.Label>
                <Col sm="8">
                    <Form.Control as="select" value={element.status} name="status" className="mb-2" onChange={(event) => functions.edit({ name: 'status', type: 'select', value: event.target.value })}  >
                        {status_list.map((f, k) => (
                            <option key={k} value={f.id} >{f.label}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Form.Group>

            {isGlobalAdmin ?
                <>
                    <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                        <Form.Label column sm="2" className="text-warning">{t('contract.labels.enable_claudit_trace')}</Form.Label>
                        <Col sm="2">
                            <Form.Check type="radio" name="enable_claudit_trace" value={true} checked={element.enable_claudit_trace} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Check type="radio" name="enable_claudit_trace" value={false} checked={!element.enable_claudit_trace} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                        <Form.Label column sm="2" className="text-warning">{t('contract.labels.claudit_trace_mode')}</Form.Label>
                        <Col sm="2">
                            <Form.Check type="radio" name="claudit_trace_mode" value={'beacon'} checked={element.claudit_trace_mode && element.claudit_trace_mode === 'beacon'} label={t('contract.labels.beacon')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Check type="radio" name="claudit_trace_mode" value={'qrcode'} checked={!element.claudit_trace_mode || element.claudit_trace_mode === 'qrcode'} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Col>
                    </Form.Group>
                </>
                : ''}
            <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                <Form.Label column sm="2" className="text-warning">{t('contract.labels.room_execution_detail_level')}</Form.Label>
                <Col sm="2">
                    <Form.Check disabled={!element.enable_claudit_trace} type="radio" name="room_lastintervention_detail_mode" value={'beacon'} checked={element.room_lastintervention_detail_mode === 'beacon'} label={"ulizza dati da ClauditPoint"} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="room_lastintervention_detail_mode" value={'intervention'} checked={!element.room_lastintervention_detail_mode !== 'beacon'} label={"non visualizzare dati"} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>


            <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                <Form.Label column sm="2">{t('contract.labels.lock_intervention')}</Form.Label>
                <Col sm="2">
                    <Form.Check type="radio" name="lock_intervention_active" value={true} checked={element.lock_intervention_active} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="lock_intervention_active" value={false} checked={!element.lock_intervention_active} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                    {/*                     <Form.Text className="text-muted">
                        {t('contract.labels.active')}
                    </Form.Text> */}
                </Col>
                {element.lock_intervention_active ?
                    <Col sm="3">
                        <InputGroup className="mb-2" >
                            <Form.Control type="number" min="1" max="30" name="lock_intervention_nextdays" value={element.lock_intervention_nextdays} onChange={(event) => functions.edit(event.target)} />
                        </InputGroup>
                        <Form.Text className="text-muted">
                            ultimo giorno del mese successivo per poter modificare
                        </Form.Text>
                    </Col>
                    : ''}
            </Form.Group>
            <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                <Form.Label column sm="2">{t('contract.labels.lock_validation')}</Form.Label>
                <Col sm="2">
                    <Form.Check type="radio" name="lock_validation_active" value={true} checked={element.lock_validation_active} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="lock_validation_active" value={false} checked={!element.lock_validation_active} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                    {/*                     <Form.Text className="text-muted">
                        {t('contract.labels.active')}
                    </Form.Text> */}
                </Col>
                {element.lock_validation_active ?
                    <Col sm="3">
                        <InputGroup className="mb-2" >
                            <Form.Control type="number" min="1" max="30" name="lock_validation_nextdays" value={element.lock_validation_nextdays} onChange={(event) => functions.edit(event.target)} />
                        </InputGroup>
                        <Form.Text className="text-muted">
                            ultimo giorno del mese successivo per poter validare il report interventi
                        </Form.Text>
                    </Col>
                    : ''}
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('contract.labels.execution_mode')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="execution_mode" value="auto" checked={!element.execution_mode || element.execution_mode === 'auto'} label={t('contract.labels.execution_auto')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="execution_mode" value="manual" checked={element.execution_mode === 'manual'} label={t('contract.labels.execution_manual')} onChange={(event) => functions.edit(event.currentTarget)} />
                    {element.enable_claudit_trace ?
                        <Form.Check type="radio" name="execution_mode" value="claudittrace" checked={element.execution_mode === 'claudittrace'} label={t('contract.labels.execution_claudittrace')} onChange={(event) => functions.edit(event.currentTarget)} />
                        : ''}
                </Col>
            </Form.Group>
        </>
    )
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormAmounts({ element, functions, ui_list, t, service_list, canWrite }) {

    const { locale } = useContext(LocaleContext)
    const [risk_area_amount, setRiskareaAmount] = useState(element.risk_area_amount || [])
    const [risk_area_totalamount, setRiskAreaTotalAmount] = useState({ mq: 0, year: 0, contract: 0, size: 0, month: 0 })
    //    const [service_amount, setServiceAmount] = useState(element.service_amount || [])
    //const [service_hour_amount, setServiceHourAmount] = useState(element.service_hour_amount || [])

    const getContractMonths = () => {
        const value = parseInt(differenceInMonths(myTimestampToDate(element.endDate), myTimestampToDate(element.startDate))) + 1
        let value_ext = 0
        if (element.extension_month && element.extension_month_active) {
            value_ext = parseInt(element.extension_month)
        }
        return [value, value_ext]
    }

    const contract_month = getContractMonths()

    const working_day_list = [{ weekday: 1, monthday: 4 },
    { weekday: 2, monthday: 9 },
    { weekday: 3, monthday: 13 },
    { weekday: 4, monthday: 17 },
    { weekday: 5, monthday: 22 },
    { weekday: 6, monthday: 26 },
    { weekday: 7, monthday: 30 }]


    const onChangeRiskAreaAmount = ({ name, value }) => {
        setRiskareaAmount({ ...risk_area_amount, [name]: value })
    }
    /* 
        const onChangeServiceAmount = (el) => {
            setServiceAmount({ ...service_amount, [el.name]: el.value })
        }
        const onChangeServiceRequestAmount = (el) => {
            setServiceAmount({ ...service_amount, [el.name]: el.value })
        } */


    useEffect(() => {
        let temp_total = { mq: 0, year: 0, contract: 0, size: 0, month: 0 }
        try {

            {
                ui_list.risk_area_list.filter(a => Object.keys(element.risk_area_summary).indexOf(a.Id) >= 0).map((f) => {
                    const month_value = getAmountValue((risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size.toFixed(2)).toFixed(2))
                    console.log("RA", month_value)
                    temp_total.size = temp_total.size + element.risk_area_summary[f.Id].size
                    temp_total.mq = temp_total.mq + getAmountValue(risk_area_amount[f.Id])
                    temp_total.month = temp_total.month + month_value
                    temp_total.year = temp_total.year + getAmountValue((month_value * 12).toFixed(2))
                    temp_total.contract = temp_total.contract + getAmountValue((month_value * contract_month[0]).toFixed(2))
                })
            }
        } catch (err) { }
        console.log("risk_area_mount total", temp_total)
        setRiskAreaTotalAmount(temp_total)
        functions.edit({ name: 'risk_area_amount', value: risk_area_amount })

    }, [risk_area_amount])

    return (
        <>
            {/*             <Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.working_days')}</Form.Label>
                <Col sm="8">
                    <Form.Control as="select" value={element.working_days} name="working_days" className="mb-2" onChange={(event) => functions.edit({ name: 'working_days', type: 'select', value: event.target.value })}  >
                        {working_day_list.map((f, k) => (
                            <option key={k} value={f.weekday} >{f.weekday}</option>
                        ))}
                    </Form.Control>
                            <Form.Text className="text-muted">
                                {t('global.labels.weekly')}
                            </Form.Text>
                </Col>
            </Form.Group> */}

            <Card className="mb-3">
                <Card.Header className="h4 text-primary">
                    {t('contract.labels.risk_area_summary')}
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        {element.risk_area_summary && Object.keys(element.risk_area_summary).length > 0 ?
                            <>
                                <Row className="mb-1">
                                    <Col className="bg-secondary text-light ms-1 text-start">{element.type === 'civil' ? t('global.labels.standard_cleaning') : t('global.labels.risk_area')} </Col>
                                    <Col className="bg-secondary text-light ms-1">{t('contract.labels.value')} / m<sup>2</sup> / mese</Col>
                                    <Col className="bg-secondary text-light ms-1">m<sup>2</sup> totali</Col>
                                    <Col className="bg-secondary text-light ms-1">{t('contract.labels.value')} totale al mese</Col>
                                    <Col className="bg-secondary text-light ms-1">{t('contract.labels.value')} totale annuale<br />

                                        <OverlayTrigger
                                            placement="right"
                                            delay={popoverTiming}
                                            overlay={<Tooltip id="button-tooltip">{t('contract.labels.value')}  {t('global.labels.month')} &times; 12</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon="info-circle" />
                                        </OverlayTrigger>
                                    </Col>
                                    <Col className="bg-secondary text-light ms-1"> Importo totale <br /><i>({contract_month[0]} mesi)</i>


                                        <OverlayTrigger
                                            placement="right"
                                            delay={popoverTiming}
                                            overlay={<Tooltip id="button-tooltip">{t('contract.labels.value')} {t('global.labels.month')} &times; {contract_month[0]}</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon="info-circle" />
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                {ui_list.risk_area_list.filter(a => Object.keys(element.risk_area_summary).indexOf(a.Id) >= 0).map((f, k) => (
                                    <Row key={k}>
                                        <Col className="text-start">
                                            <RiskareaBadge riskarea={f} />
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-2" >
                                                <Form.Control type="number" min="0" step="0.1" value={element.risk_area_amount[f.Id]} name={f.Id} onChange={(event) => onChangeRiskAreaAmount(event.currentTarget)} />

                                                <InputGroup.Text>
                                                    &euro;
                                                </InputGroup.Text>

                                            </InputGroup>
                                        </Col>
                                        <Col className="text-end">
                                            {element.risk_area_summary[f.Id].size.toFixed(2)}
                                        </Col>
                                        <Col>
                                            <CurrencyInput
                                                name={"month_" + f.id}
                                                value={getAmountValue(risk_area_amount[f.Id] * getFloatNumber(element.risk_area_summary[f.Id].size).toFixed(2)).toFixed(2)}
                                                allowDecimals={true}
                                                decimalsLimit={2}
                                                decimalSeparator=","
                                                className="form-control text-end"
                                                thousandSeparator=" "
                                                prefix="€"
                                                disabled={true}
                                                precision={2}
                                            />
                                        </Col>
                                        <Col>
                                            <CurrencyInput
                                                name={"year_" + f.id}
                                                placeholder="€0.00"
                                                value={getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size.toFixed(2)).toFixed(2) * 12}
                                                allowDecimals={true}
                                                decimalsLimit={2}
                                                decimalSeparator=","
                                                className="form-control text-end"
                                                thousandSeparator=" "
                                                groupSeparator="."
                                                prefix="€"
                                                disabled={true}
                                                precision={2}
                                            />
                                        </Col>
                                        <Col>
                                            <CurrencyInput
                                                name={"contract_" + f.id}
                                                value={getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size.toFixed(2)).toFixed(2) * contract_month[0]}
                                                allowDecimals={true}
                                                decimalsLimit={2}
                                                decimalSeparator=","
                                                className="form-control text-end"
                                                thousandSeparator=" "
                                                prefix="€"
                                                disabled={true}
                                                precision={2}
                                            />
                                        </Col>
                                    </Row>))
                                }
                            </>
                            : <Row>
                                <Col className="text-start font-italic">
                                    {element.type === 'civil' ?
                                        t('contracts.messages.no_standard_cleaning')
                                        :
                                        t('contracts.messages.no_risk_area')
                                    }
                                </Col>
                            </Row>
                        }

                    </Container>

                </Card.Body>
                {element.risk_area_summary && Object.keys(element.risk_area_summary).length > 0 ?
                    <Card.Footer>
                        <Container fluid>
                            <Row>
                                <Col className="border-end ms-1 text-start">{t('global.labels.total')} </Col>
                                <Col className="border-end ms-1"></Col>
                                <Col className="border-end ms-1 text-end">{risk_area_totalamount.size.toFixed(2)}</Col>
                                <Col className="border-end ms-1">
                                    <CurrencyInput
                                        name='total_contract'
                                        value={risk_area_totalamount.month.toFixed(2)}
                                        allowDecimals={true}
                                        decimalSeparator=","
                                        className="form-control-plaintext text-end text-dark"
                                        thousandSeparator=" "
                                        prefix="€"
                                        disabled={true}
                                        precision={2}
                                    />
                                </Col>
                                <Col className="border-end ms-1">
                                    <CurrencyInput
                                        name='total_contract'
                                        value={risk_area_totalamount.year.toFixed(2)}
                                        allowDecimals={true}
                                        thousandSeparator=","
                                        className="form-control-plaintext text-end text-dark"
                                        groupSeparator=" "
                                        prefix="€"
                                        disabled={true}
                                        precision={2}
                                    />
                                </Col>
                                <Col className="ms-1">
                                    <CurrencyInput
                                        name='total_contract'
                                        value={risk_area_totalamount.contract.toFixed(2)}
                                        allowDecimals={true}
                                        decimalSeparator=","
                                        className="form-control-plaintext text-end text-dark"
                                        groupSeparator=" "
                                        prefix="€"
                                        disabled={true}
                                        precision={2}
                                    />
                                </Col>
                            </Row>

                        </Container>

                    </Card.Footer>
                    : ''}
            </Card>

            <ServiceamountList element={element} functions={functions} t={t} contract_month={contract_month} canWrite={canWrite} service_list={service_list} />
            <ServiceamountHourList element={element} functions={functions} t={t} canWrite={canWrite} service_list={service_list} />


        </>
    )
}






///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormCompany({ elem, functions, t, canWrite }) {
    console.log(elem)
    let element = newcompany
    if (elem && elem.company) {
        element = elem.company
    }
    return (
        <>
            <Form.Group as={Row} id="formSupplierBusinessname" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.businessname')} </Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="name" value={getValue(element, 'name')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formSupplierCode" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.code')}</Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="code" value={getValue(element, 'code')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formSupplierVat" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.vat')}</Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="vat" value={getValue(element, 'vat')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formSupplierCf" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.cf')}</Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="cf" value={getValue(element, 'cf')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="supplierAddress" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.address')} </Form.Label>
                <Col>
                    <Row>
                        <Col>
                            <Form.Control type="text" sub="address" name="street" value={getValue(element.address, 'street')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.street')}
                            </Form.Text>
                        </Col>
                        <Col sm="2">
                            <Form.Control type="text" sub="address" name="zip" value={getValue(element.address, 'zip')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.zip')}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Form.Control type="text" sub="address" name="city" value={getValue(element.address, 'city')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.city')}
                            </Form.Text>
                        </Col>
                        <Col sm="2">
                            <Form.Control type="text" sub="address" name="state" value={getValue(element.address, 'state')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.state')}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Form.Group>
            <PhoneList element={element} functions={functions} t={t} canWrite={canWrite} />
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Email</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="email" value={getValue(element, 'email')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Website</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" placeholder="www.mywebsite.it" name="website" value={getValue(element, 'website')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Pec</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="pec" value={getValue(element, 'pec')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Text className="text-muted">
                        Pec
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">SDI</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="sdi" value={getValue(element, 'sdi')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Note</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="note" value={getValue(element, 'note')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>

        </>
    )
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormReferent({ element, functions, t, ui_list, canWrite }) {

    const { locale } = useContext(LocaleContext)
    const newreferent = {
        firstname: '',
        lastname: '',
        phone_list: [],
        phone: '',
        mobilephone: '',
        fax: '',
        email: '',
        role: {},
        user_id: -1
    }
    const [showReferentEdit, setShowReferentEdit] = useState(false)
    const [newReferentEdit, setNewReferentEdit] = useState(true)
    const [curReferent, setCurReferent] = useState(newreferent)
    //----referent
    const handleCloseReferent = () => setShowReferentEdit(false);
    const handleSaveReferent = () => {
        //FIXME
        if (newReferentEdit) {
            let newreferent_list = element.referent_list || []
            newreferent_list.push(curReferent)
            console.log(newreferent_list)
            functions.edit({ name: 'referent_list', value: newreferent_list })
        } else {
            let editlist = element.referent_list
            //            const c = checkComponentPresence(curReferent, element.referent_list)
            editlist.splice(curReferent.index, 1, curReferent)
            functions.edit({ name: 'referent_list', value: editlist })
        }
        setShowReferentEdit(false);
    }
    const handleDeleteReferent = (el, index) => {
        console.log(el)
        let editlist = element.referent_list
        const c = checkComponentPresence(el, element.referent_list)
        editlist.splice(index, 1)
        functions.edit({ name: 'referent_list', value: editlist })
    }
    const handleShowNewReferent = () => {
        setCurReferent(newreferent)
        setNewReferentEdit(true)
        setShowReferentEdit(true)
    }
    const handleShowEditReferent = (i, index) => {
        console.log('edit', i, index)
        i.index = index
        setCurReferent(i)
        setNewReferentEdit(false)
        setShowReferentEdit(true)
    }
    const onChangeHandlerReferent = (event) => {
        console.log(event)
        let { name, value, type } = event
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        }
        console.log(name, value)
        setCurReferent({ ...curReferent, [name]: value })
    }
    const updateRoleList = (b) => {
        functions.updateData('referent_role_list', b)
    }
    return (
        <>
            <Row>

                {canWrite ?
                    <Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewReferent()}><FontAwesomeIcon icon="plus-square" />{t('suppliers.labels.referent_add')}</Button></Col>
                    : ''}
            </Row>
            <Row className="border-bottom  border-end mb-1">
                {element.referent_list && element.referent_list.length > 0 ?
                    <Col>
                        <Row >
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.name')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.lastname')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.email')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.phone')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('suppliers.labels.role')} </Col>
                            {canWrite ?
                                <Col className="bg-secondary text-light ms-1">{t('global.labels.actions')}</Col>
                                : ''}
                        </Row>
                        {element.referent_list.map((i, key) => (
                            <Row key={key} className="border-bottom mb-1 pt-1">
                                <Col className="font-weight-bold">{i.firstname}</Col>
                                <Col className="font-weight-bold">{i.lastname}</Col>
                                <Col>{i.email}</Col>
                                <Col>{renderPhoneList(i.phone_list)}</Col>
                                <Col>
                                    {getBaseObjectValue(i.role, 'name', locale)}
                                </Col>
                                {canWrite ?
                                    <Col className="text-center">
                                        <ButtonGroup>
                                            <Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditReferent(i, key)} /> </Button>
                                            <Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteReferent(i, key)} /> </Button>
                                        </ButtonGroup>
                                    </Col>
                                    : ''}
                            </Row>))
                        }
                    </Col>
                    : <Col> {t('suppliers.messages.no_referent')}</Col>
                }
            </Row>
            <Modal show={showReferentEdit} onHide={handleCloseReferent} size="xl" className="bg-secondary" backdrop="static">
                <Modal.Header closeButton>
                    {newReferentEdit && <Modal.Title>{t('suppliers.labels.new_referent')}</Modal.Title>}
                    {!newReferentEdit && <Modal.Title>{t('suppliers.labels.edit_referent')}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="firstname" value={curReferent.firstname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.lastname')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="lastname" value={curReferent.lastname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="border-bottom">
                            <Form.Label column sm="2">{t('supliers.labels.referent.role')} </Form.Label>
                            <Col sm="8">
                                <Form.Control as="select" className="mb-2" defaultValue={curReferent.role.Id} name="role" onChange={(event) => onChangeHandlerReferent({ name: 'role', type: 'select', value: getSelectedElement(event.target.value, ui_list.referent_role_list) })} >
                                    <option value="-1" >{t('equipments.labels.select_role')}</option>
                                    {ui_list.referent_role_list.map((i, key) => (
                                        <option key={key} value={i.Id}  >{i.name[locale]}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col sm="2">
                                <ModalBaseObject is_new={true} small={true} cursection={{ name: 'referent_role', list: ui_list.referent_role_list }} t={t} functions={{ setCurSection: updateRoleList }} />
                            </Col>
                        </Form.Group>
                        {/*                         <Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
                            <Form.Label column sm="2">{t('supliers.labels.referent.user')} </Form.Label>
                            <Col sm="8">
                                <Form.Control as="select" className="mb-2" defaultValue={curReferent.user_id} name="user_id"
                                    onChange={(event) => onChangeHandlerReferent({ name: 'user_id', type: 'select', value: getSelectedElement(event.target.value, ui_list.referent_user_list) })} >
                                    <option value="-1" >{t('equipments.labels.select_user')}</option>
                                    {ui_list.referent_user_list && ui_list.referent_user_list.map((i, key) => (
                                        <option key={key} value={i.id}  >{i.displayName}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group> */}
                        <PhoneList element={curReferent} functions={{ edit: onChangeHandlerReferent }} t={t} canWrite={canWrite} />
                        <Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="8">
                                <Form.Control type="email" className="mb-2" name="email" value={curReferent.email} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReferent}>
                        {t('modal.close')}
                    </Button>
                    {canWrite &&
                        <Button className="btn-myprimary" form="companyForm" onClick={handleSaveReferent}>
                            <FontAwesomeIcon icon="save" />{t('modal.save')}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

        </>
    )
}





///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormManagementGroup({ element, functions, t, ui_list, canWrite }) {

    const { locale } = useContext(LocaleContext)
    const newmanagementgroup = {
        firstname: '',
        lastname: '',
        phone_list: [],
        email: '',
        role: {}
    }
    const [showmanagementgroupEdit, setShowmanagementgroupEdit] = useState(false)
    const [newmanagementgroupEdit, setNewmanagementgroupEdit] = useState(true)
    const [curmanagementgroup, setCurmanagementgroup] = useState(newmanagementgroup)
    //----managementgroup
    const handleClosemanagementgroup = () => setShowmanagementgroupEdit(false);
    const handleSavemanagementgroup = () => {
        //FIXME
        if (newmanagementgroupEdit) {
            let newmanagementgroup_list = element.managementgroup_list || []
            newmanagementgroup_list.push(curmanagementgroup)
            console.log(newmanagementgroup_list)
            functions.edit({ name: 'managementgroup_list', value: newmanagementgroup_list })
        } else {
            let editlist = element.managementgroup_list
            //            const c = checkComponentPresence(curmanagementgroup, element.managementgroup_list)
            editlist.splice(curmanagementgroup.index, 1, curmanagementgroup)
            functions.edit({ name: 'managementgroup_list', value: editlist })
        }
        setShowmanagementgroupEdit(false);
    }
    const handleDeletemanagementgroup = (el, index) => {
        console.log(el)
        let editlist = element.managementgroup_list
        const c = checkComponentPresence(el, element.managementgroup_list)
        editlist.splice(index, 1)
        functions.edit({ name: 'managementgroup_list', value: editlist })
    }
    const handleShowNewmanagementgroup = () => {
        setCurmanagementgroup(newmanagementgroup)
        setNewmanagementgroupEdit(true)
        setShowmanagementgroupEdit(true)
    }
    const handleShowEditmanagementgroup = (i, index) => {
        console.log('edit', i, index)
        i.index = index
        setCurmanagementgroup(i)
        setNewmanagementgroupEdit(false)
        setShowmanagementgroupEdit(true)
    }
    const onChangeHandlermanagementgroup = (event) => {
        console.log(event)
        let { name, value, type } = event
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        }
        console.log(name, value)
        setCurmanagementgroup({ ...curmanagementgroup, [name]: value })
    }
    const updateRoleList = (b) => {
        functions.updateData('roles_list', b)
    }
    return (
        <>
            <Form.Group as={Row} className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.principal')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="principal" value={element.principal} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                </Col>
            </Form.Group>
            <Row>

                {canWrite ?
                    <Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewmanagementgroup()}><FontAwesomeIcon icon="plus-square" /> {t('suppliers.labels.managementgroup_add')}</Button></Col>
                    : ''}
            </Row>
            <Row className="border-bottom  border-end mb-1">
                {element.managementgroup_list && element.managementgroup_list.length > 0 ?
                    <Col>
                        <Row >
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.name')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.lastname')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.email')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.phone')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('suppliers.labels.role')} </Col>
                            {canWrite ?
                                <Col className="bg-secondary text-light ms-1">{t('global.labels.actions')}</Col>
                                : ''}
                        </Row>
                        {element.managementgroup_list.map((i, key) => (
                            <Row key={key} className="border-bottom mb-1 pt-1">
                                <Col className="font-weight-bold">{i.firstname}</Col>
                                <Col className="font-weight-bold">{i.lastname}</Col>
                                <Col>{i.email}</Col>
                                <Col>
                                    {renderPhoneList(i.phone_list)}</Col>
                                <Col>
                                    {i.report_reference ?
                                        <FontAwesomeIcon icon="file-alt" />
                                        : ''
                                    }
                                    {i.role.name[locale]}
                                </Col>
                                {canWrite ?
                                    <Col className="text-center">
                                        <ButtonGroup>
                                            <Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditmanagementgroup(i, key)} /> </Button>
                                            <Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeletemanagementgroup(i, key)} /> </Button>
                                        </ButtonGroup>
                                    </Col>
                                    : ''}
                            </Row>))
                        }
                    </Col>
                    : <Col> {t('suppliers.messages.no_managementgroup')}</Col>
                }
            </Row>
            <Modal show={showmanagementgroupEdit} onHide={handleClosemanagementgroup} size="xl" className="bg-secondary" backdrop="static">
                <Modal.Header closeButton>
                    {newmanagementgroupEdit && <Modal.Title>{t('suppliers.labels.new_managementgroup')}</Modal.Title>}
                    {!newmanagementgroupEdit && <Modal.Title>{t('suppliers.labels.edit_managementgroup')}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="firstname" value={curmanagementgroup.firstname} onChange={(event) => onChangeHandlermanagementgroup(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="supplierLastName" className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.lastname')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="lastname" value={curmanagementgroup.lastname} onChange={(event) => onChangeHandlermanagementgroup(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
                            <Form.Label column sm="2">{t('supliers.labels.managementgroup.role')} </Form.Label>
                            <Col sm="8">
                                <Form.Control as="select" className="mb-2" defaultValue={curmanagementgroup.role.Id} name="role" onChange={(event) => onChangeHandlermanagementgroup({ name: 'role', type: 'select', value: getSelectedElement(event.target.value, ui_list.roles_list) })} >
                                    <option value="-1" >{t('equipments.labels.select_role')}</option>
                                    {ui_list.roles_list.map((i, key) => (
                                        <option key={key} value={i.Id}  >{i.name[locale]}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col sm="2">
                                <ModalBaseObject is_new={true} small={true} cursection={{ name: 'roles', list: ui_list.roles_list }} t={t} functions={{ setCurSection: updateRoleList }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                            <Form.Label column sm="2">{t('contract.labels.report_reference')}</Form.Label>
                            <Col sm="2">
                                <Form.Check type="radio" name="report_reference" value={true} checked={curmanagementgroup.report_reference === true} label={t('global.labels.yes')} onChange={(event) => onChangeHandlermanagementgroup(event.currentTarget)} />
                                <Form.Check type="radio" name="report_reference" value={false} checked={!curmanagementgroup.report_reference} label={t('global.labels.no')} onChange={(event) => onChangeHandlermanagementgroup(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <PhoneList element={curmanagementgroup} functions={{ edit: onChangeHandlermanagementgroup }} t={t} canWrite={canWrite} />
                        <Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="8">
                                <Form.Control type="email" className="mb-2" name="email" value={curmanagementgroup.email} onChange={(event) => onChangeHandlermanagementgroup(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosemanagementgroup}>
                        {t('modal.close')}
                    </Button>
                    {canWrite ?
                        <Button className="btn-myprimary" form="companyForm" onClick={handleSavemanagementgroup}>
                            <FontAwesomeIcon icon="save" />{t('modal.save')}
                        </Button>
                        : ''}
                </Modal.Footer>
            </Modal>

        </>
    )
}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormArchive({ element, functions, ui_list, t }) {
    const childRefDelete = useRef()
    const { locale } = useContext(LocaleContext)
    const onArchive = () => {
        const cb = async () => {
            functions.edit({ name: 'is_archived', value: true })
        }
        childRefDelete.current.confirmDelete(t('contract.messages.archive_contract_title'), t('contracts.messages.archive_contract', { contract: element.name }), cb)
    }
    const onUnArchive = () => {
        functions.edit({ name: 'is_archived', value: false })

    }
    return (
        <>
            <DeleteConfirmationSection ref={childRefDelete} />
            {element.is_archived ?
                <Button variant="success" onClick={onUnArchive}>{t('global.labels.undo_archive')}</Button>
                :
                <Button variant="danger" onClick={onArchive}>{t('global.labels.do_archive')}</Button>
            }

        </>
    )
}


const public_data_source = [
    { id: 'last_intervention', label: 'ultimo intervento' },
    { id: 'last_check', label: 'ultimo controllo' },
    { id: 'next_intervention', label: 'prossimo intervento' }
]


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormPublicData({ element, functions, ui_list, t, canWrite }) {

    const updateData = (el) => {
        let s_data = []
        if (element.public_data) {
            s_data = [...element.public_data]
        }
        const c = s_data.indexOf(el)
        if (c >= 0) {
            s_data.splice(c, 1)
        } else {
            s_data.push(el)
        }
        functions.edit({ name: 'public_data', value: s_data })
    }

    return (
        <>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="4">
                    Dati da visualizzare su pagina pubblica del singolo locale
                </Form.Label>
                <Col>
                    {public_data_source.map(s => (
                        <Form.Check type="checkbox" name={s.id} value={s.id} checked={element.public_data && element.public_data.indexOf(s.id) >= 0} label={s.label} onChange={(event) => updateData(s.id)} />
                    ))}
                </Col>
            </Form.Group>
        </>
    )

}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormChecks({ element, functions, ui_list, t, service_list, canWrite }) {
    const check_section = [
        { section: 'autocheck', label: t('contract.labels.autocheck') },
        { section: 'contradictory', label: t('contract.labels.contradictory') },
        { section: 'client_checks', label: t('contract.labels.client_checks') }
    ]



    return (
        <>
            {check_section.map((s, sk) => (
                <OneCheckSection section={s.section} label={s.label} functions={functions} t={t} element={element} ui_list={ui_list} />
            ))}
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">
                    {t('checks.labels.control_mode')}
                </Form.Label>
                <Col>
                    <Form.Check type="radio" name="control_validation_mode" value={'global'} checked={element.control_validation_mode === 'global'} label={t('checks.labels.global_mode')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="control_validation_mode" value={'use_risk_area'} checked={element.control_validation_mode === 'use_risk_area'} label={t('checks.labels.use_risk_area')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Card className="mb-3">
                <Card.Header className="h4 text-primary">
                    {t('global.labels.microbioliogical_check')}
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Form.Group as={Row} className="border-bottom">
                            <Form.Label column sm="2">
                                {t('global.labels.active')}
                            </Form.Label>
                            <Col>
                                <Form.Check type="radio" name="has_microbiological_check" value={true} checked={element.has_microbiological_check} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                                <Form.Check type="radio" name="has_microbiological_check" value={false} checked={!element.has_microbiological_check} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        {element.has_microbiological_check ?
                            <LaboratoryList element={element} functions={functions} t={t} canWrite={canWrite} />
                            : ''}
                    </Container>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header className="h4 text-primary">
                    {t('global.labels.customer_satisfaction')}
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Form.Group as={Row} className="border-bottom">
                            <Form.Label column sm="2">
                                {t('global.labels.active')}
                            </Form.Label>
                            <Col>
                                <Form.Check type="radio" name="has_customer_satisfaction_check" value={true} checked={element.has_customer_satisfaction_check} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                                <Form.Check type="radio" name="has_customer_satisfaction_check" value={false} checked={!element.has_customer_satisfaction_check} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                    </Container>
                </Card.Body>
            </Card>
        </>
    )

}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormTickets({ element, functions, ui_list, t, service_list, canWrite }) {
    const { domain, domain_data } = useContext(DomainContext)

    const newmessagegroup = {
        name: '',
    }
    const [showMessagegroupEdit, setShowMessagegroupEdit] = useState(false)
    const [newMessagegroupEdit, setNewMessagegroupEdit] = useState(true)
    const [curMessagegroup, setCurMessagegroup] = useState(newmessagegroup)

    //----messagegroup
    const handleCloseMessagegroup = () => setShowMessagegroupEdit(false);
    const handleSaveMessagegroup = async () => {
        console.log(domain, curMessagegroup)
        if (newMessagegroupEdit) {
            let return_data = await addMessageGroup(domain, element.id, curMessagegroup)
            let newmessagegroup_list = ui_list.message_group_list
            newmessagegroup_list.push(return_data)
            newmessagegroup_list.sort(compareName)
            console.log(newmessagegroup_list)
            //            ui_list.message_group_list = newmessagegroup_list
            functions.updateData('message_group_list', { list: newmessagegroup_list })
        } else {
            let return_data = await editMessageGroup(domain, element.id, curMessagegroup.id, curMessagegroup)
            let editlist = ui_list.message_group_list
            const c = checkComponentPresence(curMessagegroup, ui_list.message_group_list)
            editlist.splice(c, 1, curMessagegroup)
            functions.updateData('message_group_list', { list: editlist })
        }
        setShowMessagegroupEdit(false);
    }
    const handleDeleteMessagegroup = async (el, index) => {
        console.log("mg", el)
        console.log("CONTRACT", element)
        let editlist = ui_list.message_group_list
        let return_data = await deleteMessageGroup(domain, element.id, el.id)
        console.log(return_data)
        const c = checkComponentPresence(curMessagegroup, ui_list.message_group_list, 'id')
        editlist.splice(index, 1)
        functions.updateData('message_group_list', { list: editlist })
    }
    const handleShowNewMessagegroup = () => {
        setCurMessagegroup(newmessagegroup)
        setNewMessagegroupEdit(true)
        setShowMessagegroupEdit(true)
    }
    const handleShowEditMessagegroup = (i, index) => {
        console.log('edit', i, index)
        i.index = index
        setCurMessagegroup(i)
        setNewMessagegroupEdit(false)
        setShowMessagegroupEdit(true)
    }
    const onChangeHandlerMessagegroup = (event) => {
        let { name, value, type } = event
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        }
        setCurMessagegroup({ ...curMessagegroup, [name]: value })
    }

    return (
        <>
            <Row>

                <Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewMessagegroup()}><FontAwesomeIcon icon="plus-square" />{t('contract.labels.add_message_group')}</Button></Col>
            </Row>
            <Row className="border-bottom  border-end mb-1">
                {ui_list.message_group_list && ui_list.message_group_list.length > 0 ?
                    <Col>
                        <Row >
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.name')} </Col>
                            <Col className="bg-secondary text-light ms-1">{t('global.labels.actions')}</Col>
                        </Row>
                        {ui_list.message_group_list.map((i, key) => (
                            <Row key={key} className="border-bottom mb-1 pt-1">
                                <Col className="font-weight-bold">{i.name}</Col>
                                <Col className="text-center">
                                    <ButtonGroup>
                                        <Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditMessagegroup(i, key)} /> </Button>
                                        <Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteMessagegroup(i, key)} /> </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>))
                        }
                    </Col>
                    : <Col className="font-italic"> {t('contract.messages.no_message_group')}</Col>
                }
            </Row>




            <Modal show={showMessagegroupEdit} onHide={handleCloseMessagegroup} size="xl" className="bg-secondary" backdrop="static">
                <Modal.Header closeButton>
                    {newMessagegroupEdit && <Modal.Title>{t('ticket.labels.new_messagegroup')}</Modal.Title>}
                    {!newMessagegroupEdit && <Modal.Title>{t('ticket.labels.edit_messagegroup')}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="name" value={curMessagegroup.name} onChange={(event) => onChangeHandlerMessagegroup(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMessagegroup}>
                        {t('modal.close')}
                    </Button>
                    <Button className="btn-myprimary" onClick={handleSaveMessagegroup}>
                        <FontAwesomeIcon icon="save" /> {t('modal.save')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}



const default_weight = { 'results': 0, 'process': 0 }
const default_autorenew = { 'results': false, 'process': false }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneCheckSection = ({ section, label, functions, element, t, ui_list }) => {

    const [invalidWeight, setInvalidWeight] = useState(false)
    const [check_weight, setCheckWeight] = useState(default_weight)
    const [check_autorenew, setCheckAutorenew] = useState(default_autorenew)

    const check_total = (ck_weight) => {
        let total_weight = 0
        for (const t in ck_weight) {
            console.log(t)
            if (element[section] && element[section].indexOf(t) >= 0) {
                total_weight += parseInt(ck_weight[t])
            }
        }
        console.log("TOTAL WEIGHT", ck_weight, total_weight)
        if (total_weight !== 100) {
            setInvalidWeight(true)
        } else {
            setInvalidWeight(false)
        }
    }

    const onChangeHandlerWeight = (myname, el) => {

        const tmp_check_weight = { ...check_weight }
        console.log(tmp_check_weight)
        tmp_check_weight[myname] = el.value

        setCheckWeight(tmp_check_weight)
        check_total(tmp_check_weight)
        functions.edit({ name: 'check_weight', value: { ...element.check_weight, [section]: tmp_check_weight } })
    }

    const onChangeHandlerAutorenew = (myname, el) => {
        let vl = false
        if (el.value === 'true') {
            vl = true
        } else if (el.value === 'false') {
            vl = false
        }
        const tmp_check_autorenew = { ...check_autorenew }
        tmp_check_autorenew[myname] = vl
        console.log("AUTORENEW", section, tmp_check_autorenew, vl)

        setCheckAutorenew(tmp_check_autorenew)
        functions.edit({ name: 'check_autorenew', value: { ...element.check_autorenew, [section]: tmp_check_autorenew } })
    }



    useEffect(() => {
        const ck_weight = element.check_weight && element.check_weight[section] ? element.check_weight[section] : default_weight
        check_total(ck_weight)
        setCheckWeight(ck_weight)
        const ck_autorenew = element.check_autorenew && element.check_autorenew[section] ? element.check_autorenew[section] : default_autorenew
        setCheckAutorenew(ck_autorenew)

    }, [])

    return (
        <Card className="mb-3">
            <Card.Header className="h4 text-primary">
                {label}
            </Card.Header>
            <Card.Body>
                <Container fluid>
                    <Form.Group as={Row} className="border-bottom mb-1 pb-1">
                        <Col>
                            <Form.Check type="checkbox" name={section} value="results" checked={element[section] && element[section].indexOf('results') >= 0} label={t('contract.labels.checkresults')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Col>
                        {console.log("AR", check_autorenew)}
                        <Col>
                            {element[section] && element[section].indexOf('results') >= 0 ?
                                <>
                                    <InputGroup>
                                        <Form.Control type="number" min="0" max="100" name="check_weights" value={check_weight['results']} className={invalidWeight ? 'bg-danger text-light border-danger' : ''} onChange={(event) => onChangeHandlerWeight('results', event.target)} />

                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>

                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        {t('checks.labels.weight_coefficient')}
                                    </Form.Text>
                                    <Form.Group as={Row} className="border-top">
                                        <Form.Text className="text-muted">
                                            {t('global.labels.check_autorenew')}
                                        </Form.Text>
                                        <Col>
                                            <Form.Check type="radio" name={`${section}results_check_autorenew`} value={true} checked={check_autorenew['results']} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerAutorenew('results', event.target)} />
                                            <Form.Check type="radio" name={`${section}results_check_autorenew`} value={false} checked={!check_autorenew || check_autorenew['results'] === false} label={t('global.labels.no')} onChange={(event) => onChangeHandlerAutorenew('results', event.target)} />
                                        </Col>
                                    </Form.Group>
                                </>
                                : ''}
                        </Col>
                        <Col className="border-start">
                            <Form.Check type="checkbox" name={section} value="process" checked={element[section] && element[section].indexOf('process') >= 0} label={t('contract.labels.checkprocess')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Col>
                        <Col>
                            {element[section] && element[section].indexOf('process') >= 0 ?
                                <>
                                    <InputGroup>
                                        <Form.Control type="number" min="0" max="100" name="check_weights" value={check_weight['process']} className={invalidWeight ? 'bg-danger text-light border-danger' : ''} onChange={(event) => onChangeHandlerWeight('process', event.target)} />

                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>

                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        {t('checks.labels.weight_coefficient')}
                                    </Form.Text>
                                    <Form.Group as={Row} className="border-top">
                                        <Form.Text className="text-muted">
                                            {t('global.labels.check_autorenew')}
                                        </Form.Text>
                                        <Col>
                                            <Form.Check type="radio" name={`${section}process_check_autorenew`} value={true} checked={check_autorenew['process']} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerAutorenew('process', event.target)} />
                                            <Form.Check type="radio" name={`${section}process_check_autorenew`} value={false} checked={!check_autorenew || !check_autorenew['process']} label={t('global.labels.no')} onChange={(event) => onChangeHandlerAutorenew('process', event.target)} />
                                        </Col>
                                    </Form.Group>
                                </>
                                : ''}
                        </Col>
                    </Form.Group>


                </Container>
            </Card.Body>
        </Card>
    )
}

