import React, { useRef, useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Badge, OverlayTrigger, Popover, } from 'react-bootstrap';
import { format, setDay, differenceInCalendarDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import { roomStatus_dict, roomStatus_list } from "../components/constants/global_definitions"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import EditContext from "../components/contexts/edit.js"
import GlobalDataContext from './contexts/globaldataContext.js';


import { getValue, checkComponentPresence, myTimestampToDate, rgba2hex, getFixedNumber } from "../util/ui_utils.js"
import { getRoomStatus, MyBadge, RenderItemFileBadge, renderItemNote, RoomInfoBadge, LevelSpacer } from './StructureGraphicElements.js'

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalRoomItem from './ModalRoomItem'


import { deleteRoom as deleteRoom2 } from '../Firebase2/Storage2/dbRoom'


const now = new Date()
//const contractId = sessionStorage.getItem('contractId')
const section = 'room'


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Interventions({ curintervent, functions, item }) {
	return (
		<Row>
			{curintervent.map((i, key) => (
				<Col sm="1" key={key}>
					<OneIntervent title={i.description} text={getInterventionContent(i)} mycontainer={document} />
				</Col>
			))}
			<Col sm="1">
				<Button className="me-1" variant="outline-info" size="sm" onClick={() => functions.show(item)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			</Col>
		</Row>
	)
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function getInterventionContent(i) {
	return (
		<>
			<Row>
				<Col>
					{i.start} - {i.end}  [
					<span>{i.weekdays.map(a => (
						<>
							{format(
								setDay(new Date(), a), 'ccc', { locale: it })
							}
							&nbsp;
						</>))}</span>]
				</Col>
			</Row>
		</>
	)
}





///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function OneIntervent({ title, text, mycontainer }) {
	const [show, setShow] = useState(false);
	//  const [target, setTarget] = useState(null);

	const handleClick = (event) => {
		setShow(!show);
		//    setTarget(event.target);
	};

	return (
		<>
			<OverlayTrigger
				trigger="focus"
				placement="bottom"
				container={mycontainer.current}
				overlay={
					<Popover id="tooltip-top" content="html">
						<Popover.Header as="h3">{title}</Popover.Header>
						<Popover.Body>
							{text}
						</Popover.Body>
					</Popover>
				}
			>
				<Button variant="outline-secondary" size="sm" onClick={handleClick}><FontAwesomeIcon icon="check" /></Button>
			</OverlayTrigger>
		</>
	);
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddButton = ({ selectedWorkareas, onClickAdd, ispresent, other_present_list, item }) => {
	const edit = useContext(EditContext)

	//console.log("ADD BUTTOn refresh", item.id, ispresent, edit.workareaRoomList.filter(r => r.id === item.id).length>0, edit.workareaRoomList)



	if (selectedWorkareas && selectedWorkareas.length > 0) {
		const w_list = other_present_list

		const pres = edit.workareaRoomList.filter(r => r.id === item.id).length > 0

		return (
			<Col sm="1" className="p-1">
				{pres ?
					<Button size="sm" variant="secondary" onClick={onClickAdd}><FontAwesomeIcon className="text-warning" icon="check" />&nbsp;<FontAwesomeIcon className="text-warning" icon="plus-square" />{w_list.length > 0 ? <PresencePopover workarea_list={w_list} /> : ''}</Button>
					:
					<Button size="sm" variant="primary" onClick={onClickAdd}><FontAwesomeIcon className="text-warning" icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" />{w_list.length > 0 ? <PresencePopover workarea_list={w_list} /> : ''}</Button>
				}
			</Col>
		)

	} else {
		return <></>
	}
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const PresencePopover = ({ workarea_list }) => {
	const renderPopover = (workarea_list) => {
		return (
			<Popover id="popover-basic" className="bg-primary border border-primary">
				<Popover.Header>
					Altre aree di lavoro in cui è presente
				</Popover.Header>
				<Popover.Body>
					<Container fluid>
						{workarea_list.map((w, k) => (

							<Row key={k}>
								<Col>
									{w}
								</Col>
							</Row>
						))}
					</Container>
				</Popover.Body>
			</Popover>
		)
	}

	return (
		<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderPopover(workarea_list)}
		>
			<FontAwesomeIcon icon="cog" />
		</OverlayTrigger>
	)
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderStatusPopover = (status, t) => {
	const status_list = {
		suspended: {
			id: 'suspended',
			label: t('structure.labels.status_type_suspension')
		},
		decommissioned: {
			id: 'decommissioned',
			label: t('structure.labels.status_type_decommission')
		}

	}

	try {

		return (
			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Body>
					<Row>
						<Col>
							{status_list[status.value]}
						</Col>
					</Row>
					<Row>
						<Col>
							{format(myTimestampToDate(status.startDate), 'dd/MM/yyyy')}

						</Col>
						{status.use_end ?
							<Col>
								{format(myTimestampToDate(status.endDate), 'dd/MM/yyyy')}
							</Col>
							: ''}
					</Row>
				</Popover.Body>
			</Popover>

		)
	} catch (err) {

		return (

			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Body >
					<Row>
						<Col>
							{status_list[status.value]}
						</Col>
					</Row>
					<Row>
					</Row>
				</Popover.Body>
			</Popover>


		)
	}
}


function checkOtherPresence(other_workarea_list, room_id) {
	let w_name_list = []
	for (const w of other_workarea_list) {
		console.log(w.room_list, room_id)
		if (w.room_list && w.room_list.filter(r => r.id === room_id).length > 0) {
			w_name_list.push(w.name)
		}
	}
	console.log(w_name_list)
	return w_name_list
}




////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ListRoomItem({ item, functions, level, is_summary, parent, setParent, selectedWorkareas, curWorkarea, t, searchTerm, ui_list, structureElements, canWrite, isVariation }) {

	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)
	const edit = useContext(EditContext)
	const { contract, roomtype_dict } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid


	let children = null;
	let present = false
	const [ispresent, setIsPresent] = useState(present)
	const [open, setOpen] = useState(true);
	const [curItem, setCurItem] = useState(item)
	const [rowHover, toggleHover] = useState(false)
	const [is_unused, setIsUnused] = useState(true)

	let other_present_list = []
	if (selectedWorkareas && selectedWorkareas.length > 0) {
		other_present_list = checkOtherPresence(edit.editList.filter(ww => ww.id !== selectedWorkareas[0]), item.id)
	}


	const status_dict = roomStatus_dict({ t })


	const param_dict = {
		status: { label: t('global.labels.status'), type: 'list', param_dict: status_dict },
		risk_area: {
			label:
				contract.type === 'civil' ?
					t('global.labels.standard_cleaning')
					:
					t('global.labels.risk_area')
			, type: 'baselist', param_dict: ui_list.risk_area_dict
		},
		//		glass_percentage: { label: t('structure.labels.glass_percentage'), type: 'value' },
		size: { label: t('structure.labels.size'), type: 'value' },
		cdc: { label: t('structure.labels.cdc'), type: 'value' }
	}
	/* 
	  if (curWorkarea) {
    
		const a = curWorkarea.room_list.filter(r => r.id === item.id)
    
		if (a.length > 0) {
		  present = true
		  console.log("IIIIIIIIIIIIISPRESENT")
		} else {
		  present = false
		}
	  }
    
    
    
    
	  useEffect(() => {
		const a = curWorkarea.room_list.filter(r => r.id === item.id)
		if (a.length > 0) {
		  present = true
		} else {
		  present = false
		}
		console.log("present update_refresh", curWorkarea.room_list, present)
		setIsPresent(present)
    
	  }, [curWorkarea]) */





	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteRoom2(domain, contractId, item.parent_list[0], item.parent_list[1], item.parent_list[2], item.parent_list[3], curItem.id, curItem)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let p_childs = [...parent.childs]
				const c = checkComponentPresence(curItem, parent.childs, 'id')
				p_childs.splice(c, 1)
				/* 			for (let c = 0; c < parent.childs.length; c++) {
						if (parent.childs[c].Id === curItem.Id) {
						  console.log(p_childs)
						  p_childs.splice(c, 1, curItem)
						}
					  } */
				setParent({ ...parent, 'childs': p_childs })
			}
		}
		childRefDelete.current.confirmDelete(t('structure.messages.delete_room_title'), t('structure.messages.delete_room', { name: el.name }), cb)
	}




	const getParamValue = (e) => {

		const p = param_dict[e.param]

		if (e.prev_value) {
			if (p.type === 'value') {
				return (`${e.prev_value} -> ${e.value}`)
			} else if (p.type === 'list') {
				return (p.param_dict[e.value].label)
			} else {
				return (p.param_dict[e.value.Id].name[locale])
			}
		} else {
			if (p.type === 'value') {
				return (e.value)
			} else if (p.type === 'list') {
				return (p.param_dict[e.value].label)
			} else {
				return (p.param_dict[e.value.Id].name[locale])
			}
		}
	}


	const getPlanningLength = (e, index) => {
		const start_index = index + 1
		for (let p = start_index; p < curItem.planning_event_list.length; p++) {
			const cur_p = curItem.planning_event_list[p]
			if (cur_p.param === e.param) {
				return differenceInCalendarDays(myTimestampToDate(cur_p.startDate), myTimestampToDate(e.startDate))
			}
		}
		return ''


	}



	const onClickAdd = () => {
		functions.addToWorkarea(item)
		//    setIsPresent(true)
	}


	const searchName = () => {
		try {
			if (String(curItem.name).toLowerCase().indexOf(searchTerm) >= 0 || (curItem.roomtype && curItem.roomtype.name && curItem.roomtype.name.toLowerCase().indexOf(searchTerm) >= 0)) {
				return true
			}

		} catch (err) {
			console.log(curItem)
			return true
		}
	}


	const filterUnused = () => {
		console.log("FILTER", edit.filterUnusedRoom)
		if (!edit.filterUnusedRoom) {
			return true
		} else {
			console.log(ispresent, edit.editList, item.id, edit.editList.filter(r => r.id === item.id).length > 0)
			if (edit.workareaRoomList.filter(r => r.id === item.id).length > 0 || other_present_list.length > 0) {
				return false
			}
			else {
				return true
			}
		}
	}
	useEffect(() => {
		const unused = filterUnused()
		setIsUnused(unused)
	}, [edit.filterUnusedRoom])



	useEffect(() => {
		if (item.not_conformed) {
			setCurItem(item)
		} else {
			const rt = roomtype_dict[item.roomtype.id]
			setCurItem({
				...item,
				'risk_area': rt.risk_area,
				'floor': rt.floor,
				'furniture_list': rt.furniture_list,
				'need_waxing': rt.need_waxing || false,
				'economic_material_list': rt.economic_material_list,
				'environment_element_list': rt.environment_element_list,
			})

		}

	}, [])




	if (is_unused && (!searchTerm || searchTerm.length === 0 || searchName())) {
		//console.log("render", item.id)
		let myroomList = JSON.parse(sessionStorage.getItem('myroomList') || '[]')
		myroomList.push(curItem.id)
		sessionStorage.setItem('myroomList', JSON.stringify(myroomList))
		//let newEditList = [...roomcontext.roomGlobal] || []
		//newEditList.push(curItem)
		//roomcontext.setRoomGlobal(newEditList)
		const room_status = getRoomStatus(curItem, t, {}, true)
		//console.log(curItem.id, room_status)
		if (isVariation) {

			console.log(structureElements.event_list)

			return (<>
				<Row className="element_row">
					<Col className="border-bottom border-secondary">
						<Row>
							<Col>
								<LevelSpacer level={4} end_item={true} />
								{!curItem.end_item ? [<Button
									size="sm"
									variant="secondary"
									onClick={() => setOpen(!open)}
									aria-controls="collapseRow"
									aria-expanded={open}
									className="align-bottom"
									disabled={!children ? 'disabled' : ''}
								>
									{open ? <FontAwesomeIcon icon="chevron-circle-up" /> : <FontAwesomeIcon icon="chevron-circle-down" />}
								</Button>] : <span className="levelbox me-5 " style={{ backgroundColor: '#99c99f' }}>&nbsp;</span>
								}

								<RoomInfoBadge parent_list={curItem.parent_list} />
								{curItem.status === 'suspended' ?
									<span className="text-danger">
										<FontAwesomeIcon icon="hourglass" />
										{/*                 <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderStatusPopover(room_status[1], t)}>
                  <FontAwesomeIcon icon="hourglass" />
                </OverlayTrigger> */}
										{curItem.name}
										&nbsp;<i>{getValue(roomtype_dict[curItem.roomtype.id], 'name')}</i>&nbsp;
									</span>

									: (curItem.status === 'decommissioned' ?
										<span style={{ textDecoration: 'line-through' }}>
											<FontAwesomeIcon icon="exclamation-circle" />
											{curItem.name}
											<span className="text-secondary"> &nbsp;<i>{getValue(roomtype_dict[curItem.roomtype.id], 'name')}</i>&nbsp;</span>
										</span>

										:
										<>
											{curItem.name}
											<span className="text-secondary"> &nbsp;<i>{getValue(roomtype_dict[curItem.roomtype.id], 'name')}</i>&nbsp;</span>
										</>

									)

								}


								{curItem.note && curItem.note.length > 0 ?
									<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderItemNote(curItem.note)}>
										<Badge size="sm" bg="info"><FontAwesomeIcon icon="comment-alt" /></Badge>
									</OverlayTrigger>
									: ''}

							</Col>
						</Row>
						<Row>
							<Col>
								{curItem.planning_event_list && curItem.planning_event_list.length > 0 ?
									<Col>
										<Row className="p-1 text-light">
											<Col>

											</Col>
											<Col className="bg-secondary text-light ms-1">{t('global.labels.param')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('global.labels.value')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('global.labels.date')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('global.labels.time_length')} ({t('global.labels.days')})</Col>
											<Col className="bg-secondary text-light ms-1">{t('global.labels.user')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('global.labels.planned')} </Col>
										</Row>
										{curItem.planning_event_list.map((e, pk) => (

											<Row className="element_row" key={pk}>
												<Col>
												</Col>
												<Col>
													{param_dict[e.param].label}
												</Col>
												<Col>
													{getParamValue(e)}
												</Col>
												<Col className={myTimestampToDate(e.startDate) > now ? 'bg-secondary text-light' : ''}>
													{format(myTimestampToDate(e.startDate), 'dd/MM/yyyy')}
													{e.note && e.note.length > 0 ?
														<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderItemNote(e.note)}>
															<Badge size="sm" bg="info"><FontAwesomeIcon icon="comment-alt" /></Badge>
														</OverlayTrigger>
														: ''}
												</Col>
												<Col>
													{getPlanningLength(e, pk)}
												</Col>
												<Col>
													{e.userName}
												</Col>
												<Col>
													{format(myTimestampToDate(e.creationDate), 'dd/MM/yyyy')}
												</Col>
											</Row>

										))}
									</Col>
									: ''}
							</Col>

						</Row>

					</Col>
					<Col sm="1" className="border-bottom border-secondary text-center">
						{!is_summary && curItem.media_list && curItem.media_list.length > 0 ?
							<RenderItemFileBadge media_list={curItem.media_list} section={section} position="left-start" />
							: ''}
					</Col>
					<Col sm="1" className="border-bottom border-secondary text-center">
						{curItem.cdc}
					</Col>
				</Row>
			</>)
		} else {


			return (
				<>
					<DeleteConfirmationSection ref={childRefDelete} />
					<AlertSection ref={childRefAlert} />
					<Row onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)} className="element_row">

						<AddButton selectedWorkareas={selectedWorkareas} onClickAdd={onClickAdd} ispresent={ispresent} item={curItem} other_present_list={other_present_list} />

						<Col className="border-bottom border-secondary">
							<LevelSpacer level={4} end_item={true} />
							 <span className="levelbox me-5 " style={{ backgroundColor: '#99c99f' }}>&nbsp;</span>
							

							{edit.editMultiple ?
								[checkComponentPresence(curItem, edit.editList, 'id') === -1 ?
									<Button variant="outline-info" className="me-1" size="sm" onClick={() => edit.toggleEditList(curItem, curItem.parent_list[3], parent, setParent)}><FontAwesomeIcon icon="check" /></Button>
									:
									<Button variant="info" className="me-1" size="sm" onClick={() => edit.toggleEditList(curItem, curItem.parent_list[3], parent, setParent)}><FontAwesomeIcon icon="check" /></Button>
								]
								: ''}
							<RoomInfoBadge parent_list={curItem.parent_list} />
							{curItem.status === 'suspended' ?
								<span className="text-danger">
									<FontAwesomeIcon icon="hourglass" />

									{/*                 <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderStatusPopover(room_status[1], t)}>
                  <FontAwesomeIcon icon="hourglass" />
                </OverlayTrigger> */}
									{curItem.name}
									&nbsp;<i>{getValue(roomtype_dict[curItem.roomtype.id], 'name')}</i>&nbsp;
								</span>

								: (curItem.status === 'decommissioned' ?
									<span style={{ textDecoration: 'line-through' }}>
										<FontAwesomeIcon icon="exclamation-circle" />
										{curItem.name}
										<span className="text-secondary"> &nbsp;<i>{getValue(roomtype_dict[curItem.roomtype.id], 'name')}</i>&nbsp;</span>
									</span>

									:
									<>
										{curItem.name}
										<span className="text-secondary"> &nbsp;<i>{getValue(roomtype_dict[curItem.roomtype.id], 'name')}</i>&nbsp;</span>
									</>

								)

							}
							{/* {curItem.next_update && getMyDate(curItem.next_update > now) ? */}

							{curItem.next_update ?
								<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="auto" overlay={
									<Popover id="popover-basic" className="bg-info border border-info">
										<Popover.Body className="bg-info text-light">
											{t('global.messages.next_update')}: {format(myTimestampToDate(curItem.next_update), 'dd/MM/yyyy')}
										</Popover.Body>
									</Popover>
								}>
									<Badge bg="warning" text="dark">
										<FontAwesomeIcon icon="exchange-alt" />
										{curItem.planning_event_list && curItem.planning_event_list.filter(p => myTimestampToDate(p.startDate) > new Date()).length > 0 ?
											<>
												{curItem.planning_event_list.filter(p => myTimestampToDate(p.startDate) > new Date()).length}
											</>
											: ''}
									</Badge>
								</OverlayTrigger>
								: ''}

							{curItem.note && curItem.note.length > 0 ?
								<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderItemNote(curItem.note)}>
									<Badge size="sm" bg="info"><FontAwesomeIcon icon="comment-alt" /></Badge>
								</OverlayTrigger>
								: ''}


						</Col>
						<Col sm="1" className="border-bottom border-secondary text-center">
							{!is_summary && curItem.media_list && curItem.media_list.length > 0 ?
								<RenderItemFileBadge media_list={curItem.media_list} section={section} position="left-start" />
								: ''}
						</Col>
						<Col sm="1" className="border-bottom border-secondary text-center">
							{curItem.cdc}
						</Col>
						<Col sm="1" className="border-bottom border-secondary text-center">
							{getFixedNumber(curItem.size)}
						</Col>
						<Col sm="1" className="border-bottom border-secondary text-center">
							{contract.risk_area_amount[curItem.risk_area.Id] ?
								<>{(contract.risk_area_amount[curItem.risk_area.Id] * curItem.size / 30).toFixed(2)} <span className="text-secondary">€ </span>
								</>
								: <></>}
						</Col>
						<Col sm="2" className="text-center border-bottom border-secondary">
							{curItem.risk_area && curItem.risk_area.Id ?
								<Row>
									<Col className="text-end">
										<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="auto" overlay={
											<Popover id="popover-basic" className="bg-info border border-info">
												<Popover.Body className="bg-info text-light">
													{getValue(ui_list.risk_area_dict[curItem.risk_area.Id]?.name, locale)}
												</Popover.Body>
											</Popover>
										}>
											<MyBadge color={rgba2hex(ui_list.risk_area_dict[curItem.risk_area.Id]?.color)} >{getValue(ui_list.risk_area_dict[curItem.risk_area.Id]?.name, locale)}</MyBadge>
										</OverlayTrigger>
									</Col>
									{/* {is_summary ?
										<Col>
											{getValue(ui_list.risk_area_dict[curItem.risk_area.Id]?.name, locale)}
										</Col>
										: ''} */}
								</Row>
								: ''}
						</Col>
						{is_summary ?
							<Col className="border-bottom border-secondary" sm="1">
								{curItem.interventions &&
									<Interventions curintervent={curItem.interventions} functions={functions} item={curItem}></Interventions>
								}
							</Col>
							:
							<Col sm="2" className="text-end border-bottom border-secondary">
								<ButtonGroup size="sm">
									<ModalRoomItem item={curItem} setItem={setCurItem} is_new={false} t={t} hover={rowHover} parent={parent} setParent={setParent} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} />
									{canWrite && rowHover && !edit.editMultiple ? <>
										<Button variant="outline-danger" size="sm" onClick={() => handleDelete(curItem)}><FontAwesomeIcon icon='trash' /> </Button>
									</>
										: ''
									}
								</ButtonGroup>
							</Col>
						}
					</Row>
				</>

			);
		}
	} else {
		return (<></>)
	}
}




export default translate()(ListRoomItem)