import React, { useState, useEffect, useRef, createRef, useContext } from 'react';

import { Modal, Container, Row, Col, Form, Button, ButtonGroup, Spinner, Popover, OverlayTrigger, Badge, Tooltip, InputGroup, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { endOfWeek, format, getDay, setDay, startOfWeek, subDays, isSameDay, addDays } from 'date-fns'
// pick a date util library
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import 'react-day-picker/lib/style.css';
import { it } from 'date-fns/locale'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'


import { serviceType_list } from "../components/constants/global_definitions"

import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"



import { createEventId } from '../event-utils'
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import { compareName, myTimestampToDate, getValue, getSelectedElementB, getSelectedElement, getElementCount, rgba2hex, checkComponentPresence, propComparator, getQualificationParam, compareStartDateParam } from "../util/ui_utils.js"
import { ItemProgress, ServiceBadge, RiskareaBadge, RoomInfoBadge, Frequency, AddListButton, EmployerIcon } from '../components/StructureGraphicElements.js'


import firebase from 'firebase/compat/app'


import { getEventRange as getEventRange2, editEvent, addEvent, addEventBatch } from '../Firebase2/Storage2/dbWorkshiftAgenda'
import { getEmployerList } from '../Firebase2/Storage2/dbEmployers';
import { getWorkContractList as getWorkContractList2, } from "../Firebase2/Storage2/dbWorkContract"
import { getWorkareaList as getWorkareaList2 } from '../Firebase2/Storage2/dbWorkarea';




const popoverTiming = { show: 500, hide: 100 }


const selectedColor = 'green'
let curdate = { start: new Date(), end: new Date() }
const newEvent = {
	start: new Date(),
	end: new Date(),
	id: createEventId(),
	extendedProps: {
		subject: '',
		location: '',
		shared: -1,
		reminder: false,
		reminder_time: 15,
		note: '',
		periodic_data: {
			date_list: [],
			start: '6:00',
			end: '8:00'
		}
	}
};



let department_dict = {}
let used_service_list = []
let intervention_dict = {}

let permission_department_list = []
let permission_structure_list = []

let load_page = false

let filterAppliedList = { type: [], intervention: [], service: [], structure: [], pavillon: [], floor: [], department: [], employer: [], qualification: [] }
const defaultfilter = { type: [], intervention: [], service: [], structure: [], pavillon: [], floor: [], department: [], employer: [], qualification: [] }

const slot_duration_list = [
	'0:15:00',
	'0:30:00',
	'1:00:00',
	'2:00:00',
	'4:00:00',
	'24:00:00',
]

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OperatorWorkshiftView = ({ t, uData }) => {

	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, service_dict, } = useContext(GlobalDataContext)

	const { initContract } = InitContractHelper()
	const calendarRef = createRef()
	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid
	const service_type = serviceType_list({ t })


	const [title, setTitle] = useState('')
	const [showEdit, setShow] = useState(false);
	const [showOverlap, setShowOverlap] = useState(false)
	const [showEditEvent, setShowEdit] = useState(false);
	const [is_new, setNew] = useState(true);
	const [curEvent, setCurEvent] = useState(newEvent);
	const [currentEvents, setCurrentEvents] = useState([]);
	const [originalCurrentEvents, setOriginalCurrentEvents] = useState([]);
	const [loading, setLoading] = useState(true)
	//  const [intervention_dict, setInterventionDict] = useState({})
	const [ui_list, setUiList] = useState({})
	const [workarea_list, setWorkareaList] = useState({})
	const [_filterAppliedList, setFilterAppliedList] = useState(defaultfilter)
	//  const [department_dict, setDepartmentDict] = useState({})
	//  const [used_service_list, setUsedServiceList] = useState([])
	const [canWrite, setWritePage] = useState(false)
	const [mydata, setData] = useState([])
	const [options, setOptions] = useState({})
	const [resources, setResources] = useState([])
	const [selected_event, setSelectedEvent] = useState([])
	const [workarea_dict, setWorkareaDict] = useState({})
	const [colors, setColors] = useState([])
	const [editMode, setEditMode] = useState('single')
	const [zoomIndex, setZoomIndex] = useState(1)
	const [globalEdit, setGlobalEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [showPreAction, setShowPreAction] = useState(false)
	const [service_list_id, setServiceListId] = useState([])
	const [showAllEmployers, setShowAllEmployers] = useState(false)
	const [invalid_events, setInvalidEvents] = useState([])
	const [curEmployer, setCurEmployer] = useState()
	const [minTime, setMinTime] = useState('06:00:00')
	const [maxTime, setMaxTime] = useState('21:00:00')

	const [calEnd, setCalEnd] = useState()




	const intervention_color = {
		'periodic_intervention': 'lightgrey',
		'ordinary_intervention': 'grey'
	}


	useEffect(() => {
		resetSelect()
	}, [editMode])



	const handleCloseViewEvent = () => {
		setShowEdit(false)
	}


	const handleClosePre = () => {
		setShowPreAction(false)
	}

	const handleCloseOverlap = () => {
		setShowOverlap(false)
	}



	const handleEventSave = async (employer_list) => {
		if (editMode === 'single') {
			const _event = { ...curEvent, start: firebase.firestore.Timestamp.fromDate(curEvent.start), end: firebase.firestore.Timestamp.fromDate(curEvent.end) }
			await editEvent(domain, contractId, curEvent.id, _event)

		} else {
			for (const ev of selected_event) {

				const newev = {
					...newEvent,
					id: ev.id,
					start: firebase.firestore.Timestamp.fromDate(ev.start),
					end: firebase.firestore.Timestamp.fromDate(ev.end),
					extendedProps: { ...ev.extendedProps, employer_list: employer_list }
				}
				await editEvent(domain, contractId, newev.id, newev)
			}

		}
		try {
			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err, calendarRef)
		}
		resetSelect()
		setShowEdit(false)
	}




	const handleDateSelect = (selectInfo) => {
		console.log("handleDateSelect - selectInfo:", selectInfo)
		//    setCurEvent({ ...newEvent, start: selectInfo.start, end: selectInfo.end })
		//  setNew(true)
		//    setShow(true)
	}


	const onChangeHandler = (event) => {
		const { name, value } = event;
		if (name !== 'start' && name !== 'end') {
			console.log(name, value)
			let newprops = { ...curEvent.extendedProps, [name]: value }
			setCurEvent({ ...curEvent, extendedProps: newprops })
		} else {
			setCurEvent({ ...curEvent, [name]: value })
			console.log("newEventDate", curEvent)
		}
	}


	const getEventList = async (el) => {
		console.log("start data", el)
		if (el.start >= curdate.start && el.end <= curdate.end) {
			console.log('*************keep data')
		} else {
			curdate = el
		}
		setLoading(true)
		let items = []

		let temp_workarea_dict = { ...intervention_dict }
		//		let temp_department_dict = { ...department_dict }
		let temp_service_list = [...used_service_list]
		//console.log(temp_department_dict)
		//    console.log(temp_intervention_dict)
		let event_list = []
		let dataset = []
		let datavalue = []

		if (contract && domain.length > 0) {

			let return_data = await getEventRange2(domain, contractId, firebase.firestore.Timestamp.fromDate(el.start), firebase.firestore.Timestamp.fromDate(el.end))
			return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention'))
			console.log("FILTER", filterAppliedList)
			for (const event of return_data) {
				let insert = false
				console.log()
				event.extendedProps.start = event.start
				event.extendedProps.end = event.end
				event.start = myTimestampToDate(event.start)
				event.end = myTimestampToDate(event.end)
				if (event.extendedProps.workarea_id) {
					const response = getSelectedElement(event.extendedProps.workarea_id, workarea_list, 'id')
					if (response) {
						event.resourceId = response.id

						event.extendedProps.name = response.name
						event.extendedProps.workarea_data = response
						event.extendedProps.summary = "Totale Locali: " + response.room_count
					}
				} else {
					event.extendedProps.name = "BOH"  // FIXME
					event.extendedProps.summary = "DB OLD DATA"

				}
				if (true) {    //insert) {
					datavalue.push([
						event.extendedProps.name,
						event.extendedProps.intervention_data.name,
						myTimestampToDate(event.start),
						myTimestampToDate(event.end),
					])
					event_list.push(event)
				}
			}
			console.log('RETURN DATA filtered', event_list)
			used_service_list = temp_service_list
			//department_dict = temp_department_dict
			intervention_dict = temp_workarea_dict

		}

		setWorkareaDict(temp_workarea_dict)
		setCurrentEvents(event_list)
		console.log("END LOADING")
		setLoading(false)
		//		setOriginalCurrentEvents(event_list)
		return event_list
	}


	useEffect(() => {
		console.log("UPDATE nESOURCES", resources.length)
	}, [resources])



	const handleEventClick = (clickInfo) => {
		if (!globalEdit) {
			console.log("myevent", clickInfo.event.extendedProps)
			if (clickInfo.event.extendedProps.type === 'ordinary_intervention') {
				setCurEvent({
					...newEvent,
					id: clickInfo.event.id,
					start: clickInfo.event.start,
					end: clickInfo.event.end,
					extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
				})
				setShowEdit(true)
			} else if (clickInfo.event.extendedProps.type === 'periodic_intervention') {
				setCurEvent({
					...newEvent,
					id: clickInfo.event.id,
					start: clickInfo.event.start,
					end: clickInfo.event.end,
					extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
				})
				setShowEdit(true)
			}
		} else {
			let _selected_event = [...selected_event]
			const overlap = checkOperatorOverlap(clickInfo.event, selected_event)
			const c = checkComponentPresence(clickInfo.event, selected_event, 'id')
			if (c === -1) {
				if (overlap) {
					childRefAlert.current.addAlert('danger', 'Stai selezionato giri carrello con orari sovrapposti')
				} else {
					_selected_event.push(
						clickInfo.event,
					)
					clickInfo.event.setProp('backgroundColor', selectedColor)

				}
			} else {
				_selected_event.splice(c, 1)
				clickInfo.event.setProp('backgroundColor', intervention_color[clickInfo.event.extendedProps.type])
			}
			setSelectedEvent(_selected_event)
		}
	}



	const resetSelect = () => {
		for (const ev of selected_event) {
			try {
				ev.setProp('backgroundColor', intervention_color[ev.extendedProps.type])
			} catch (err) {

			}
		}
		setSelectedEvent([])

	}


	const handleEvents = (events) => {
		setCurrentEvents(events)
	}


	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		let calendarApi = calendarRef.current.getApi()
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		calendarApi.refetchEvents()
	}


	const initValues = async () => {
		console.log('OPERATOR ASSIGNG uData', uData)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('operatorassignment') !== -1)) {
			setWritePage(true)
		}
		let userData = uData
		if (userData && userData.contract_dict) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].structure) {
				if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
					permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
					console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
				}
				if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
					permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
					//structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				}
			}
		}
		let db_workarea_list = await getWorkareaList2(domain, contractId)
		if (!userData.isAdmin) {
			if (permission_structure_list.length > 0) {

				db_workarea_list = db_workarea_list.filter(w => w.structure_list.some(d => permission_structure_list.indexOf(d) !== -1))
				console.log("FILTERED structure", db_workarea_list)
				if (permission_department_list.length > 0) {
					db_workarea_list = db_workarea_list.filter(w => (w.room_list.length === 0 || w.department_list.some(d => permission_department_list.indexOf(d) !== -1)))

				}
			}
		}
		db_workarea_list.sort(compareName)
		setWorkareaDict(db_workarea_list)

		let _contract_list = await getWorkContractList2(domain, contractId)
		let _employer_list = await getEmployerList(domain, contractId, {})
		_employer_list = _employer_list.sort(propComparator('surname'))
		const _contract_dict = Object.assign({}, ..._contract_list.map((x) => ({ [x.id]: x })));
		const _employer_dict = Object.assign({}, ..._employer_list.map((x) => ({ [x.id]: x })));
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, service_dict, roomtype_list, roomtype_dict, employer_list: _employer_list, employer_dict: _employer_dict, contract_list: _contract_list })
		getEventList({ start: startOfWeek(new Date(), { weekStartsOn: 1, locale: it }), end: endOfWeek(new Date(), { weekStartsOn: 1, locale: it }) })
		load_page = true
		setLoading(false)
		//let calendarApi = calendarRef.current.getApi()
		//calendarApi.refetchEvents()

	}

	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("OPERATOR ASSIGNMENT reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("OPERATOR ASSIGNMENT init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()
		}
	}, [])




	useEffect(() => {
		console.log('OPERATOR ASSIGNMENT loadingcontract', contract)
		if (contract && loading) {
			initValues()
		}
	}, [contract])


	useEffect(() => {
		if (calendarRef && calendarRef.current) {
			let calendarApi = calendarRef.current.getApi()
			setTitle(calendarApi.view.title)
		}
	}, [calendarRef])

	useEffect(() => {
		let _service_list_id = []
		for (const ev of selected_event) {
			if (_service_list_id.indexOf(ev.extendedProps.intervention_data.service.id) === -1) {
				_service_list_id.push(ev.extendedProps.intervention_data.service.id)
			}
		}
		setServiceListId(_service_list_id)

	}, [selected_event])


	const filterRoom = (info, _room) => {
		const room = structureElements.all_room_dict[_room.id]
		const wd = info.event.extendedProps.intervention_data.custom_weekdays && info.event.extendedProps.intervention_data.custom_weekdays[room.id] ? info.event.extendedProps.intervention_data.custom_weekdays[room.id] : getSelectedElementB(info.event.extendedProps.intervention_data.service.id, ui_list.roomtype_dict[room.roomtype.id].service_list, 'service', 'id').weekdays
		return wd.indexOf(getDay(info.event.start).toString()) !== -1
	}





	// --------------------------------------------------------
	const renderEventContent = (info) => {
		if (info.timeText.length === 2) {
			info.timeText = info.timeText + ":00"
		}
		let evtId = "event-" + info.event.id;
		let tt = ''
		let room_count = ''
		try {
			tt = format(myTimestampToDate(info.event.extendedProps.start), 'HH:mm', { locale: it }) + " - " + format(myTimestampToDate(info.event.extendedProps.end), 'HH:mm', { locale: it })
		} catch (err) {
			//console.log(info.event.start, info.event.end)
		}
		if (info.event.extendedProps.type === 'ordinary_intervention') {
			try {
				room_count = info.event.extendedProps.intervention_data.room_list.filter(_room => filterRoom(info, _room)).length
			} catch (err) {
				console.error(err)
			}
		} else {
			room_count = info.event.extendedProps.intervention_data.room_list.length
		}

		const popover = (
			<Popover id="popover-basic">
				<Popover.Header as="h3">{info.event.extendedProps.type === 'ordinary_intervention' ? t('workarea.labels.ordinary_intervention') : t('workarea.labels.periodic_intervention')}:&nbsp;{info.event.extendedProps.intervention_data.name}</Popover.Header>
				<Popover.Body>
					<Container>
						<Row>
							<Col>
								<ServiceBadge service={service_dict[info.event.extendedProps.intervention_data.service.id]} freq={true} />
							</Col>
						</Row>
						<Row>
							<Col>
								{tt}
								&nbsp;|&nbsp;
								<EmployerIcon operating_units={info.event.extendedProps.intervention_data.operating_units} employer_list={info.event.extendedProps.employer_list} />
							</Col>
						</Row>
						{info.event.extendedProps.employer_list && info.event.extendedProps.employer_list.length > 0 ?
							<>
								{info.event.extendedProps.employer_list.map((e, k) => (

									<Row key={k} className="bg-info text-light">
										<Col>
											<FontAwesomeIcon icon="user" />&nbsp;
											{e.register}&nbsp;
											{e.lastname} &nbsp;
											{e.firstname}
										</Col>
									</Row>
								))}
							</>
							: ''}
						<Row>
							<Col>
								{info.event.extendedProps.department_list ?
									<>
										{info.event.extendedProps.department_list.map((d, k) => (
											<Badge bg="light" text="dark" key={k} className="me-1">
												{structureElements.all_department_dict[d].name}
											</Badge>
										))}
									</>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{room_count}&nbsp;locali
							</Col>
						</Row>
						<Row>
							<Col>
								{info.event.extendedProps.intervention_data.risk_area_summary ? <ItemProgress ui_list={ui_list} risk_list={info.event.extendedProps.intervention_data.risk_area_summary} item={info.event.extendedProps.intervention_data} /> : ''}
							</Col>
						</Row>
					</Container>
				</Popover.Body>
			</Popover>
		);






		if (info.event.extendedProps.type === 'ordinary_intervention') {
			try {

				return (
					<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" delay={popoverTiming} overlay={popover}>
						<div className="fc-content" id={evtId}>
							{info.event.extendedProps.edited ? <Badge bg="warning" text="dark"> <FontAwesomeIcon icon="pencil-alt" /></Badge> : ''}
							<EmployerIcon operating_units={info.event.extendedProps.intervention_data.operating_units} employer_list={info.event.extendedProps.employer_list} />
							{format(myTimestampToDate(info.event.extendedProps.start), 'HH:mm')} - {format(myTimestampToDate(info.event.extendedProps.end), 'HH:mm')}
							{globalEdit && info.event.extendedProps.employer_list && info.event.extendedProps.employer_list.length > 0 ?
								<>
									{info.event.extendedProps.employer_list.map((e, k) => (

										<Row key={k}>
											<Col>
												<FontAwesomeIcon icon="user" />
												{e.lastname} {e.firstname.length > 0 ? <>{e.firstname[0]}.</> : ''}
											</Col>
										</Row>
									))}
								</>
								: ''}
						</div>
					</OverlayTrigger>
				)
			} catch (err) {
				return (
					<>
						<b>{info.timeText}</b>
						<br />
					</>
				)

			}
		} else if (info.event.extendedProps.type === 'periodic_intervention') {

			return (
				<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" delay={popoverTiming} overlay={popover}>
					<div className="fc-content text-dark" id={evtId}>
						{info.event.extendedProps.edited ? <Badge bg="warning" text="dark"> <FontAwesomeIcon icon="pencil-alt" /></Badge> : ''}
						{info.event.extendedProps.intervention_data ?
							<EmployerIcon operating_units={info.event.extendedProps.intervention_data.operating_units} employer_list={info.event.extendedProps.employer_list} />
							: ''}
						{format(myTimestampToDate(info.event.extendedProps.start), 'HH:mm')} - {format(myTimestampToDate(info.event.extendedProps.end), 'HH:mm')}
						{globalEdit && info.event.extendedProps.employer_list && info.event.extendedProps.employer_list.length > 0 ?
							<>
								{info.event.extendedProps.employer_list.map((e, k) => (

									<Row key={k} >
										<Col>
											<FontAwesomeIcon icon="user" />
											{e.lastname}
										</Col>
									</Row>
								))}
							</>
							: ''}
					</div>

				</OverlayTrigger>
			)
		}
		else {
			return (
				<>
					<b>{info.timeText}</b>
					<i>{info.event.title}</i>
				</>
			)

		}

	}



	const checkOperatorOverlap = (event, event_list, operator_id) => {
		const ev_filter = event_list.filter(e => isSameDay(myTimestampToDate(e.extendedProps.start), myTimestampToDate(event.extendedProps.start)))
		console.log("event filter day", ev_filter)
		let ev_filter_b = ev_filter
		if (operator_id) {
			ev_filter_b = ev_filter.filter(e => e.extendedProps.employer_list && checkComponentPresence({ id: operator_id }, e.extendedProps.employer_list, 'id') >= 0)
		}
		ev_filter_b = ev_filter_b.map(e => ({ start: myTimestampToDate(e.extendedProps.start), end: myTimestampToDate(e.extendedProps.end) }))
		console.log("event filter operator", ev_filter_b)
		if (ev_filter_b.length === 0) {
			return false
		} else {
			ev_filter_b.push({ start: myTimestampToDate(event.extendedProps.start), end: myTimestampToDate(event.extendedProps.end) })
			console.log(ev_filter_b)
			ev_filter_b = ev_filter_b.sort(compareStartDateParam)
			let result = ev_filter_b.reduce((result, current, idx, arr) => {

				// get the previous range
				if (idx === 0) { return result; }

				let previous = arr[idx - 1];

				// check for any overlap
				let previousEnd = previous.end.getTime();
				let currentStart = current.start.getTime();
				let overlap = (previousEnd >= currentStart);
				console.log(overlap)

				// store the result
				if (overlap) {
					// yes, there is overlap
					result.overlap = true;
					// store the specific ranges that overlap
					result.ranges.push({
						previous: previous,
						current: current
					})
				}

				return result;

				// seed the reduce  
			}, { overlap: false, ranges: [] });
			console.log(result)
			return result.overlap
		}
		//		return ev_filter_b.length > 0
	}

	const handleAddEl = (el, force) => {
		console.log("ADD EL", el)
		let calendarApi = calendarRef.current.getApi()
		console.log(calendarApi.currentDataManager)
		let _currentEvents = [...currentEvents]
		let _invalid_events = []
		for (const sev of selected_event) {

			const ce = checkComponentPresence(sev, _currentEvents, 'id')
			console.log(ce, sev.id)
			let _ev = { ...sev }
			console.log(_ev)
			let editlist = _currentEvents[ce].extendedProps.employer_list
			if (!force && checkOperatorOverlap(sev, currentEvents, el.id)) {
				_invalid_events.push(sev)
			} else {
				if (editlist) {
					const c = checkComponentPresence(el, editlist, 'id', el.id)
					if (c >= 0) {
					} else {
						editlist.push({ ...el })
					}
				} else {
					editlist = [{ id: el.id, register: el.register, firstname: el.firstname, lastname: el.lastname, qualification_id: el.qualification_id, work_contract_id: el.work_contract_id }]
				}
				console.log(_ev)
				let _extp = { ..._ev.extendedProps }

				try {

					_extp.employer_list = editlist
					_extp.edited = true
					//_ev.extendedProps = _extp
					sev.setExtendedProp('employer_list', editlist)
					sev.setExtendedProp('edited', true)
					//A			_currentEvents[ce] = _ev
				} catch (err) { console.log(err) }
			}
		}
		if (_invalid_events.length > 0) {
			setCurEmployer(el)
			setInvalidEvents(_invalid_events)
			setShowOverlap(true)
		}
		if (force) {
			setShowOverlap(false)
		}
		setEditedElement(true)
		//		setSelectedEvent(selected_event)
		//		setCurrentEvents(_currentEvents)
	}


	const handleRemoveEl = (el,) => {
		console.log("ADD EL", el)
		let calendarApi = calendarRef.current.getApi()
		console.log(calendarApi.currentDataManager)
		let _currentEvents = [...currentEvents]
		for (const sev of selected_event) {

			const ce = checkComponentPresence(sev, _currentEvents, 'id')
			let editlist = _currentEvents[ce].extendedProps.employer_list
			if (editlist) {
				console.log(editlist, el)
				const c = checkComponentPresence(el, editlist, 'id')
				console.log("element index", c)
				if (c >= 0) {
					editlist.splice(c, 1)
				}
				console.log("new list", editlist)
				sev.setExtendedProp('employer_list', editlist)
				sev.setExtendedProp('edited', true)
			}
		}
		setEditedElement(true)
		//		setSelectedEvent(selected_event)
		//		setCurrentEvents(_currentEvents)
	}


	const OneEmployer = ({ i, k }) => {
		return (
			<Row key={k} className="element_row border-bottom p-1">
				<Col sm="2" className="p-0 text-center">
					<AddListButton i={i} add_action={handleAddEl} element_list={[]} increment={false} />
					<Button variant="outline-danger" size="sm" onClick={() => handleRemoveEl(i)}><FontAwesomeIcon icon="trash" /></Button>
				</Col>
				<Col>{i.lastname} {i.firstname} {i.register && i.register.length > 0 ? <>[{i.register}]</> : ''}</Col>
				<Col>{getQualificationParam(i, ui_list.contract_list, 'name')}</Col>
			</Row>
		)
	}




	function getEventClassName(arg) {
		return ['bigborder']
		/* 		if (editMode === 'multi' && checkComponentPresence(arg.event, selected_event, 'id') >= 0) {
				} else {
					return []
				} */
	}
	function getSlotLabelClassName(arg) {
		if (globalEdit) {
			return ['hiddenhour']
		} else {
			return []

		}
		/* 		if (editMode === 'multi' && checkComponentPresence(arg.event, selected_event, 'id') >= 0) {
				} else {
					return []
				} */
	}



	//######/////####////calenda API external call
	function handleCalendarNext() {
		let calendarApi = calendarRef.current.getApi()
		console.log(calendarApi)
		calendarApi.next()
	}



	function handleCalendarPrev() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.prev()
	}


	function handleCalendarHome() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.today()
	}


	function handleCalendarChangeView(view) {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.changeView(view)
		calendarApi.refetchEvents()
	}



	const zoomPlus = () => {
		if (zoomIndex > 0) {
			setZoomIndex(zoomIndex - 1)
		}
	}


	const zoomMinus = () => {
		if (zoomIndex < slot_duration_list.length - 2) {
			setZoomIndex(zoomIndex + 1)
		}
	}



	const renderResourceLabel = (info) => {
		const intervention = intervention_dict[info.resource.id]
		if (intervention) {

			return (
				<>
					<b>{intervention.name}</b><br />
					{globalEdit ?
						<ButtonGroup>

							<OverlayTrigger
								placement="auto"
								delay={{ show: 250, hide: 150 }}
								overlay=
								{<Tooltip id="button-tooltip">seleziona tutta la riga</Tooltip>}
							>
								<Button size="sm" variant="info" onClick={() => selectAllInterventionEvent(info.resource.id)}><FontAwesomeIcon icon="check-square" /></Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement="auto"
								delay={{ show: 250, hide: 150 }}
								overlay=
								{<Tooltip id="button-tooltip">deseleziona tutta la riga</Tooltip>}
							>
								<Button size="sm" variant="info" onClick={() => unselectAllInterventionEvent(info.resource.id)}><FontAwesomeIcon icon="square" /></Button>
							</OverlayTrigger>
						</ButtonGroup>

						:
						<ServiceBadge service={service_dict[intervention.service.id]} freq={true} />

					}
				</>
			)
		} else {
			return (
				<>
					ERRORE
				</>
			)
		}
	}



	const selectAllInterventionEvent = (intervention_id) => {

		console.log(calendarRef)
		let calendarApi = calendarRef.current.getApi()
		const filtered_ev_list = calendarApi.getEvents().filter(m => m.extendedProps.intervention_data.id === intervention_id)
		let _sel_event = [...selected_event]
		const _sel_event_id = _sel_event.map(e => e.id)
		for (const ev of filtered_ev_list) {
			if (_sel_event_id.indexOf(ev.id) === -1) {
				_sel_event.push(ev)
				try {
					ev.setProp('backgroundColor', selectedColor)
				} catch (err) {
					console.error(err)
				}
			}
		}
		setSelectedEvent(_sel_event)
	}



	const unselectAllInterventionEvent = (intervention_id) => {
		let calendarApi = calendarRef.current.getApi()
		const filtered_ev_list = calendarApi.getEvents().filter(m => m.extendedProps.intervention_data.id === intervention_id)
		console.log(filtered_ev_list)
		let index_id_list = []
		let _sel_event = [...selected_event]
		const _sel_event_id = _sel_event.map(e => e.id)
		for (const ev of filtered_ev_list) {
			if (_sel_event_id.indexOf(ev.id) >= 0) {
				index_id_list.push(_sel_event_id.indexOf(ev.id))
				try {
					ev.setProp('backgroundColor', intervention_color[ev.extendedProps.type])
				} catch (err) {
					console.error(err)
				}
			} else {
				console.log(ev.id, _sel_event_id.indexOf(ev.id))
			}
		}
		console.log(index_id_list)
		for (const ev_index of index_id_list.reverse()) {
			_sel_event.splice(ev_index, 1)
		}
		setSelectedEvent(_sel_event)
	}



	const renderGroupLabelContent = (info) => {
		return (<>{workarea_dict[info.groupValue]}</>)
	}



	const handleEnableGlobalEdit = () => {
		let calendarApi = calendarRef.current.getApi()
		for (let ev of calendarApi.getEvents()) {
			ev.setAllDay(true)
		}
		setMinTime('00:00:00')
		setMaxTime('24:00:00')
		setZoomIndex(5)
		setCalEnd(calendarApi.view.end)
		setGlobalEdit(true)
		//setShowPreAction(true)
	}


	const onCloseGlobalEdit = async (save) => {
		if (save) {
			for (let ev of currentEvents.filter(e => e.extendedProps.edited)) {
				delete ev.extendedProps.edited
				//				let return_data =  await editRoomType2(domain, contractId, el.id, el)
				const newev = {
					...newEvent,
					id: ev.id,
					start: ev.extendedProps.start,
					end: ev.extendedProps.end,
					extendedProps: { ...ev.extendedProps }
				}
				console.log(ev.extendedProps)
				const return_data = await editEvent(domain, contractId, newev.id, newev)
				//const return_data = 'test'
				if (return_data && return_data.error) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					childRefAlert.current.addAlert('success', t('global.messages.saved_ok'))
				}
				ev.setExtendedProp('edited', false)
			}
			onCloseGlobalEdit(false)
		} else {
			try {
				setEditedElement(false)
				let calendarApi = calendarRef.current.getApi()
				for (let ev of calendarApi.getEvents()) {
					ev.setProp('backgroundColor', intervention_color[ev.extendedProps.type])
					ev.setAllDay(false)
					ev.setStart(myTimestampToDate(ev.extendedProps.start))
					ev.setEnd(myTimestampToDate(ev.extendedProps.end))
				}
				setGlobalEdit(false)
				setZoomIndex(4)
				setMinTime('05:00:00')
				setMaxTime('21:00:00')
				resetSelect()

			} catch (err) {
				console.log(calendarRef, err)

			}

		}
	}



	const correctWorkTiming = (employer, cref) => {
		if (employer.timing === 'permanentjob') {
			return true
		} else {
			if (myTimestampToDate(employer.work_expiration) >= calEnd) {
				return true
			} else {
				return false
			}
		}
	}

	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row>
					<Col sm="6">
						<PageTitle title={t('global.pages.operator_assignment')} counter={<b>{title}</b>} />
						{loading ?
							<>
								<Spinner animation="border" role="status" />&nbsp;Caricamento in corso
							</>
							: ''}
					</Col>
					{/* 					<Col>
						<InputGroup>
							
								<InputGroup.Text>
									Modifica giri carrello selezionati
							</InputGroup.Text>
							
							
								{editMode === 'multi' && selected_event.length > 0 ?
									<>
										{selected_event.length > 0 ?
											<>
												<Button variant="info" size="sm" onClick={() => setShowEdit(true)} ><FontAwesomeIcon icon="pencil-alt" />{selected_event.length}</Button>
												<Button variant="outline-info" size="sm" onClick={resetSelect} ><FontAwesomeIcon icon="times" /></Button>
											</>
											: ''}
									</>
									: ''
								}
								<ModalCopyAssignment t={t} calendarRef={calendarRef} workarea_dict={workarea_dict} resources={resources} />
							

						</InputGroup>
					</Col> */}
					<Col className="text-end" >
						<ModalFilter t={t} defaultfilter={defaultfilter} calendarRef={calendarRef} ui_list={ui_list} className="me-2" />
						<ButtonGroup className="ms-2 me-2">
							<Button variant="secondary" size="sm" onClick={handleCalendarPrev} ><FontAwesomeIcon icon="angle-left" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarHome} ><FontAwesomeIcon icon="home" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarNext} ><FontAwesomeIcon icon="angle-right" /></Button>
						</ButtonGroup>
						{!globalEdit ?
							<>
								<ButtonGroup className="me-2">
									<Button variant="secondary" size="sm" onClick={zoomPlus} disabled={zoomIndex === 0}><FontAwesomeIcon icon="search-plus" /></Button>
									<Button variant="secondary" size="sm" onClick={zoomMinus} disabled={zoomIndex === slot_duration_list.length - 1}><FontAwesomeIcon icon="search-minus" /></Button>
								</ButtonGroup>
								<ButtonGroup>
									<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('resourceTimelineDay')}>{t('global.labels.day')}</Button>
									<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('resourceTimelineWeek')}>{t('global.labels.week')}</Button>
									<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('resourceTimelineMonth')}>{t('global.labels.month')}</Button>
								</ButtonGroup>
							</>
							: ''}
					</Col>
					{canWrite ?
						<Col>

							{globalEdit ?
								<>
									{selected_event.length > 0 ?
										<InputGroup>

											<Button variant="outline-info" size="sm" onClick={resetSelect} >
												{selected_event.length} &nbsp;
												<FontAwesomeIcon icon="times" />
											</Button>


											<Button variant="secondary" className="me-1" onClick={() => onCloseGlobalEdit(false)}><FontAwesomeIcon icon='times' /> {t('global.labels.close')}</Button>


											<Button variant={editedElement ? "warning" : "primary"} onClick={() => onCloseGlobalEdit(true)}><FontAwesomeIcon icon='check' />{editedElement ? <FontAwesomeIcon icon="save" /> : ''} {t('global.labels.save')}</Button>

										</InputGroup>
										:
										<ButtonGroup>
											<Button variant="secondary" className="me-1" onClick={() => onCloseGlobalEdit(false)}><FontAwesomeIcon icon='times' /> {t('global.labels.close')}</Button>
										</ButtonGroup>
									}
								</>
								:
								<Button variant="info" onClick={() => handleEnableGlobalEdit()}><FontAwesomeIcon icon='pencil-alt' />  {t('interventions.buttons.enable_edit')}</Button>
							}
						</Col>
						: ''}
				</Row>

			</Container>


			<Container fluid id="domContainer">
				<DeleteConfirmationSection ref={childRefDelete} />
				<AlertSection ref={childRefAlert} />
				<Row>
					{/*
          <Col sm="2">
            <HelpButton title='Istruzioni' text='<ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
          ' mycontainer={myref} />
            {renderSidebar()}

          </Col>
          */}
					<Col>

						{contract ?
							<>

								<FullCalendar

									ref={calendarRef}
									schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
									plugins={[interactionPlugin, resourceTimelinePlugin]}
									headerToolbar={true}
									buttonText={
										{
											today: 'oggi',
											month: 'mese',
											week: 'settimana',
											day: 'giorno',
											list: 'lista'
										}

									}
									firstDay="1"
									locale='it'
									views={{
										timelineTenDay: {
											type: 'timeline',
											duration: { days: 10 }
										},
									}}
									resourceAreaHeaderContent="area di lavoro"
									//resources={workarea_list}
									nowIndicator={true}
									initialView='resourceTimeline'
									eventResize={false}
									editable={false}
									scrollTime="00:00:00"
									selectable={false}
									height='auto'
									slotDuration={slot_duration_list[zoomIndex]}
									resourceAreaWidth="15%"
									//resourceGroupLabelContent={renderGroupLabelContent}
									//									resourceLabelContent={renderResourceLabel}
									eventContent={renderEventContent}
									eventClassNames={getEventClassName}
									slotLabelClassNames={getSlotLabelClassName}
									duration={{ days: 7 }}
									initialEvents={getEventList}
									select={handleDateSelect}
									eventClick={handleEventClick}
									slotMinTime={minTime}
									slotMaxTime={maxTime}
									eventsSet={handleEvents}
									stickyFooterScrollbar={true}
									stickyHeaderDates={true}
								/* 
								eventAdd={function(){}}
								eventChange={function(){}}
								eventRemove={function(){}}
								*/
								/>
							</>
							: <></>}
					</Col>
					{globalEdit ?
						<Col sm="4">
							{selected_event.length === 0 ?
								<i>
									seleziona almeno un giro carrello per procedere con l'assegnazione degli operatori
								</i>
								:
								<>
									<Row>
										<Col className="text-info">{t('training.labels.available_employers')}</Col>
										<Col>
											<ModalFilterEmployer t={t} defaultfilter={defaultfilter} calendarRef={calendarRef} ui_list={ui_list} className="me-2" setFilterAppliedList={setFilterAppliedList} />
											<Button onClick={() => setShowAllEmployers(!showAllEmployers)} size="sm" variant="outline secondary">{showAllEmployers ? <FontAwesomeIcon icon="eye-slash" /> : <><FontAwesomeIcon icon="eye" />Tutti</>}</Button>
										</Col>
									</Row>
									{ui_list.employer_list && ui_list.employer_list.filter(e => correctWorkTiming(e, calendarRef) && (showAllEmployers || ServiceCompatibility(e, service_list_id, ui_list)).length > 0) ?
										<>
											<Row >
												<Col className="bg-secondary text-light ms-1" sm="2">&nbsp;</Col>
												<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
												<Col className="bg-secondary text-light ms-1">{t('employers.labels.qualification')} </Col>
											</Row>
											{ui_list.employer_list.filter(e => correctWorkTiming(e, calendarRef) && (showAllEmployers || _filterAppliedList.qualification.indexOf(e.qualification_id) >= 0 || ServiceCompatibility(e, service_list_id, ui_list))).map((i, key) => (
												<OneEmployer i={i} key={key} />
											))}
										</>
										: <Col className="font-italic"> {t('employers.messages.no_event_employer_availablemulti')}</Col>
									}
								</>}
						</Col>
						: ''}
				</Row>




				<ModalInfoEvent t={t} selected_event={selected_event} editMode={editMode} newEdit={is_new} curEvent={curEvent} handleSave={handleEventSave} handleClose={handleCloseViewEvent} showEdit={showEditEvent} onChangeHandler={onChangeHandler} ui_list={ui_list} calendarRef={calendarRef} />


				<Modal show={showOverlap} onHide={handleCloseOverlap} backdrop="static" className="bg-secondary" style={{ zIndex: 50000 }} centered size="lg">
					<Modal.Header>
						<Modal.Title>
							<FontAwesomeIcon icon="exclamation-triangle" /> Sovrapposizione oraria
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-3">
							<Col>
								Il dipendente {curEmployer ? <>{curEmployer.lastname} {curEmployer.firstname}</> : ''} non può essere aggiunto {invalid_events.length === 1 ? 'al seguente' : 'ai seguenti'}turni in quanto già assegnato a dei turni con orario incompatibile
							</Col>
						</Row>
						{invalid_events.map((e, k) => (
							<Row key={k} className="border-botttom mb-1 pb-1">
								<Col>
									{e.extendedProps.workarea_name}<br />
									<b>{e.extendedProps.name}</b>
								</Col>
								<Col className="text-center">
									<i>{format(myTimestampToDate(e.extendedProps.start), 'dd/MM')}<br /></i>
									{format(myTimestampToDate(e.extendedProps.start), 'HH:mm')} - {format(myTimestampToDate(e.extendedProps.end), 'HH:mm')}
								</Col>
							</Row>
						))}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseOverlap}>{t('modal.close')}</Button>
						<Button variant="outline-primary" onClick={() => handleAddEl(curEmployer, true)}>inserisci comunque</Button>
					</Modal.Footer>
				</Modal>




				<Modal show={showPreAction} onHide={handleClosePre} backdrop="static" className="bg-secondary" style={{ zIndex: 50000 }} centered size="lg">
					<Modal.Header>
						<Modal.Title>
							seleziona gli interventi che vuoi modificare
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{Object.keys(workarea_dict).map((wk, k) => (
							<Row key={wk}>
								<Col>
									{workarea_dict[wk]}
								</Col>
								<Col>
									{resources.filter(r => r.group_id === wk).map((r, rk) => (
										<Row key={rk}>
											<Col>
												<Button key={k} size="sm" variant={filterAppliedList.intervention.indexOf(r.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('intervention', r.id)}>
													{r.title}
												</Button>
											</Col>
										</Row>
									))}
								</Col>
							</Row>

						))}
						<Row>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClosePre}>{t('modal.close')}</Button>

					</Modal.Footer>
				</Modal>
			</Container>

			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list: currentEvents, name: t('navs.registries.interventions') })} />
		</>
	)
}







/* 
//////////////////////////////////////////////////////////////////////////////////////////////////
const FilterButton = ({ d, filterAppliedList, department_dict, updateAppliedFilter, structureElements, show_inactive }) => {
	try {
		if (show_inactive) {

			return (

				<Button className="m-1" size="sm" variant={filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>
					{getValue(getSelectedElement(department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}:
					{department_dict[d].name}
				</Button>
			)
		} else {
			return (

				<Button size="sm" className={filterAppliedList.department.indexOf(department_dict[d].id) !== -1 ? '' : 'd-none'}
					variant={filterAppliedList.department.indexOf(structureElements.all_department_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
					onClick={(e) => updateAppliedFilter('department', structureElements.all_department_dict[d].id)}>
					{structureElements.all_department_dict[d].name} <FontAwesomeIcon icon="times" />
				</Button>
			)

		}
	} catch (err) {
		return (

			<Button className="m-1" size="sm" variant="outline-secondary" disabled >
				{d}
			</Button>
		)

	}

} */






////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilter = ({ t, defaultfilter, event_list, calendarRef, ui_list }) => {
	const { service_dict, structureElements } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)


	const service_type = serviceType_list({ t })


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			filterAppliedList = { ...filterAppliedList, [section]: [] }
		} else {
			filterAppliedList = (defaultfilter)
		}
		let calendarApi = calendarRef.current.getApi()
		calendarApi.refetchEvents()
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		let calendarApi = calendarRef.current.getApi()
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		calendarApi.refetchEvents()
	}



	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				<Button size="sm" className={filterAppliedList.type.indexOf('ordinary_intervention') !== -1 ? '' : 'd-none'}
					variant={filterAppliedList.type.indexOf('ordinary_intervention') === -1 ? "outline-info" : "secondary"}
					onClick={(e) => updateAppliedFilter('type', 'ordinary_intervention')}>
					{t('global.pages.ordinaryintervention')} <FontAwesomeIcon icon="times" />
				</Button>
				<Button size="sm" className={filterAppliedList.type.indexOf('periodic_intervention') !== -1 ? '' : 'd-none'} variant={filterAppliedList.type.indexOf('periodic_intervention') === -1 ? "outline-info" : "secondary"} onClick={(e) => updateAppliedFilter('type', 'periodic_intervention')}>{t('global.pages.programmedintervention')} <FontAwesomeIcon icon="times" /></Button>
				{filterAppliedList.type.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}> <FontAwesomeIcon icon="times" /></Button>
					: ''}
				{filterAppliedList.pavillon.length > 0 ?
					<>
						{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.pavillon.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.pavillon.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('pavillon', d)}>
								{structureElements.all_pavillon_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.pavillon.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.department.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="xl">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>

						<Row>
							<Col>
								{t('global.labels.type')}
							</Col>
						</Row>
						<Row>
							<Col>
								<ButtonGroup>
									{service_type.filter(s => s.id === 'ordinary' || s.id === 'periodic').map((g, k) => (
										<Button key={k} size="sm" variant={filterAppliedList.type.indexOf(g.intervention_id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('type', g.intervention_id)}>
											<FontAwesomeIcon icon={g.icon} /> {g.label}
										</Button>
									))}
									{filterAppliedList.type.length > 0 ?
										<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}><FontAwesomeIcon icon="times" /></Button>
										: ''}
								</ButtonGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.department')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_department_dict).map((d, k) => (
									<Button className="m-1" key={k} size="sm" variant={filterAppliedList.department.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('department', d)}>
										<i>{getValue(getSelectedElement(structureElements.all_department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}</i> |
										{structureElements.all_department_dict[d].name}
									</Button>
								))}
								{filterAppliedList.department.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						{ui_list.employer_list ?
							<>
								<Row>
									<Col>
										{t('global.labels.employer')}
									</Col>
								</Row>
								<Row>
									<Col>
										{ui_list.employer_list.map((d, k) => (
											<Button className="m-1" key={k} size="sm" variant={filterAppliedList.employer.indexOf(d.id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('employer', d.id)}>
												<i>{d.lastname} {d.firstname}</i>
											</Button>
										))}
										{filterAppliedList.employer.length > 0 ?
											<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('employer')}><FontAwesomeIcon icon="times" /></Button>
											: ''}
									</Col>
								</Row>
							</>
							: ''}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}










////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilterEmployer = ({ t, defaultfilter, event_list, calendarRef, ui_list, setFilterAppliedList }) => {

	const [showFilter, setShowFilter] = useState(false)


	const [curFilter, setCurFilter] = useState(defaultfilter)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			filterAppliedList = { ...filterAppliedList, [section]: [] }
		} else {
			filterAppliedList = (defaultfilter)
		}
		setCurFilter(filterAppliedList)
		setFilterAppliedList(filterAppliedList)
		//		let calendarApi = calendarRef.current.getApi()
		//		calendarApi.refetchEvents()
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		//		let calendarApi = calendarRef.current.getApi()
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		setCurFilter(filterAppliedList)
		setFilterAppliedList(filterAppliedList)
		//		calendarApi.refetchEvents()
	}



	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.qualification.length > 0 ?
					<>
						{/* 						{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.pavillon.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.pavillon.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('pavillon', d)}>
								{structureElements.all_pavillon_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))} */}
						{filterAppliedList.qualification.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('qualification')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="xl">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.employerfilters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>

						<Row>
							<Col>
								{t('global.labels.qualification')}
							</Col>
							<Col>
							</Col>
						</Row>
						{ui_list.contract_list.map(c => (
							<Row key={c.id}>
								<Col>
									{c.qualification_list.map(q => (
										<Button key={q.id} size="sm" variant={filterAppliedList.qualification.indexOf(q.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('qualification', q.id)}>
											{q.name}
										</Button>
									))}
									{filterAppliedList.qualification.length > 0 ?
										<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('qualification')}><FontAwesomeIcon icon="times" /></Button>
										: ''}
								</Col>
							</Row>
						))}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}














//////////////////////////////////////////////////////////////////////////////////////////////////
const ServiceCompatibility = (employer, service_id_list, ui_list) => {

	const service_list = getQualificationParam(employer, ui_list.contract_list, 'service_list')
	if (service_list && service_id_list.length > 0) {
		for (const s of service_id_list) {
			if (service_list.indexOf(s) === -1) {
				return false
			}
		}
		return true
	} else {
		return false
	}
}




// --------------------------------------------------------
const OneRoomRow = ({ room, index, intervention_type, intervention_data, ui_list, start }) => {
	const { structureElements, risk_area_dict, roomtype_dict } = useContext(GlobalDataContext)
	const _room = structureElements.all_room_dict[room.id]
	const i = getSelectedElementB(intervention_data.service.id, ui_list.roomtype_dict[_room.roomtype.id].service_list, 'service', 'id')
	const wd = intervention_data.custom_weekdays && intervention_data.custom_weekdays[room.id] ? intervention_data.custom_weekdays[room.id] : i.weekdays
	return (
		<Row key={room.id + index} className={intervention_type === 'periodic_intervention' || (start && wd.indexOf(getDay(start).toString()) !== -1) ? 'border-bottom border-start pb-1 mt-1 bg-light border-success' : 'border-bottom pb-1 mt-1 text-secondary'} >
			<Col>
				{start && wd.indexOf(getDay(start).toString()) !== -1 ?
					<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={<Tooltip>locale presente da programmazione nel giorno selezionato</Tooltip>}>
						<FontAwesomeIcon icon="check" />
					</OverlayTrigger>
					: ''}
				<RoomInfoBadge parent_list={_room.parent_list} />
				{_room.name}
			</Col>
			<Col className="text-muted">
				{_room.roomtype.name}
			</Col>
			<Col sm="2">
				{/*           {renderFrequency(room.roomtype.id, curEvent.extendedProps.intervention_data.service.id, ui_list, getDay(curEvent.start).toString())} */}
				<Frequency roomtype_id={_room.roomtype.id} service_id={intervention_data.service.id} roomtype_dict={roomtype_dict} customFrequency={intervention_data.custom_weekdays && intervention_data.custom_weekdays[room.id] ? intervention_data.custom_weekdays[room.id] : undefined} />
			</Col>
			<Col sm="1">{_room.size}</Col>
			<Col sm="2 text-center">
				<RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
			</Col>
			<Col sm="1">
				{getValue(_room, 'cdc')}
			</Col>
		</Row>
	)

}



//////////////////////////////////////////////////////////////////////////////////////////////////
const compareEmployerList = (_arr1, _arr2) => {
	if (_arr1.length !== _arr2.length) {
		return false
	}
	for (let i = 0; i < _arr2.length; i++) {
		if (_arr1[i] !== _arr2[i]) {
			return false
		}
	}
	return true
}



///////////////////////////////////////////////////////////////////////////////////
const ModalCopyAssignment = ({ t, calendarRef, workarea_dict, resources }) => {
	const { contractId, service_dict } = useContext(GlobalDataContext)
	const { domain } = useContext(DomainContext)
	const [showCopy, setShowCopy] = useState(false)
	const [selected_intervention, setSelectedInterventionList] = useState([])


	const handleCloseCopy = () => {
		setShowCopy(false)
	}


	const handleDoCopy = async () => {
		let calendarApi = calendarRef.current.getApi()
		const { start, end } = calendarApi.currentDataManager.data.dateProfile.activeRange
		const filtered_ev_dest_list = calendarApi.getEvents().filter(m => selected_intervention.indexOf(m.extendedProps.intervention_data.id) >= 0)
		let return_data = await getEventRange2(domain, contractId, subDays(start, 7), subDays(end, 7))
		return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' && selected_intervention.indexOf(e.extendedProps.intervention_data.id) >= 0))
		for (const source_ev in return_data) {
			const source_weekday = getDay(source_ev.start)
			for (const dest_ev in filtered_ev_dest_list) {
				if (dest_ev.extendedProps.intervention_data.id === source_ev.extendedProps.intervention_data.id && getDay(dest_ev.start) === source_weekday) {
					dest_ev.employer_list = source_ev.employer_list
					const _event = { ...dest_ev, start: firebase.firestore.Timestamp.fromDate(dest_ev.start), end: firebase.firestore.Timestamp.fromDate(dest_ev.end) }
					await editEvent(domain, contractId, dest_ev.id, _event)
				}
			}
		}
		setShowCopy(false)
	}


	const CopyDays = ({ intervention_id, intervention_wd }) => {
		let calendarApi = calendarRef.current.getApi()
		const { start, end } = calendarApi.currentDataManager.data.dateProfile.activeRange
		let datearray = []
		let cDate = start
		while (cDate < end) {
			datearray.push(String(getDay(cDate)))
			cDate = addDays(cDate, 1)
		}
		return (
			<Row key={intervention_id}>
				{datearray.map(d => (
					<Col key={intervention_id + d}>
						{intervention_wd.indexOf(d) >= 0 ?
							<>
								<Form.Check type="checkbox" className="ms-2" name="params" value={d} checked={intervention_id !== -1} label="" />
								{format(setDay(new Date(), d), 'cccc', { locale: it })}
							</>
							: ''}
					</Col>
				))}

			</Row>

		)
	}



	const setSelectedList = (el) => {
		let { value, } = el
		let oldcheck = []
		try {
			oldcheck = [...selected_intervention]
		} catch (err) { }
		if (el.checked) {
			if (oldcheck.indexOf(value) < 0) {
				oldcheck.push(value)
			}
		} else {
			if (oldcheck.indexOf(value) >= 0) {
				oldcheck.splice(oldcheck.indexOf(value), 1)
			}
		}
		value = oldcheck
		setSelectedInterventionList(oldcheck)

	}

	return (
		<>
			<Button size="sm" variant="outline-info" onClick={() => setShowCopy(true)}>{t('employer.labels.copy_assignment')}</Button>
			<Modal show={showCopy} onHide={handleCloseCopy} backdrop="static" className="bg-secondary" style={{ zIndex: 50000 }} centered size="lg">
				<Modal.Header>
					<Modal.Title>
						Copia assegnazione interventi ordinari dal periodo precedente
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>nome</Col>
						<Col>
							<Row>
							</Row>
						</Col>
					</Row>
					{resources.map((r, k) => (
						<Row key={k}>
							<Col>
								<i>{r.workarea_name}</i><br />
								<ServiceBadge service={service_dict[intervention_dict[r.id].service.id]} freq={true} /><br />
								{r.name}
							</Col>
							<Col>
								<CopyDays intervention_id={r.id} intervention_wd={intervention_dict[r.id].weekdays} />
							</Col>
						</Row>
					))}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseCopy}>{t('modal.close')}</Button>
					{selected_intervention.length > 0 ?
						<Button variant="primary" disabled onClick={handleDoCopy}>{t('employer.labels.copy')}</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}









// --------------------------------------------------------
const ModalInfoEvent = ({ t, newEdit, event, curEvent, handleSave, handleClose, showEdit, ui_list, onChangeHandler, editMode, selected_event, calendarRef, canWrite }) => {
	const { service_dict, contract } = useContext(GlobalDataContext)

	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [showAll, setShowAll] = useState(false)
	const [_intervention_dict, setInterventionDict] = useState({})
	const [employer_list, setEmployerList] = useState(curEvent.extendedProps.employer_list)

	const handleCloseComposition = () => setShowComponentEdit(false);
	const handleOpenComposition = () => {
		setShowComponentEdit(true)
	}

	useEffect(() => {
		if (showEdit) {

			console.log("SHOW")
			setShowAll(false)
			setShowComponentEdit(false)
		}
	}, [showEdit])

	useEffect(() => {
		console.log("update employer list", employer_list)

	}, [employer_list])



	const OneAssignedEmployer = ({ i, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="p-1">
				<Col>{i.lastname} {i.firstname} {i.register && i.register.length > 0 ? <>[{i.register}]</> : ''}</Col>
				<Col>{getQualificationParam(i, ui_list.contract_list, 'name')}</Col>
				<Col sm="1" >
				</Col>
			</Row>
		)

	}




	try {
		return (
			<>


				<Modal show={showEdit} onHide={handleClose} size="xl">
					<Modal.Header closeButton>
						<Modal.Title>
							{format(curEvent.start, 'cccc dd/MM (HH:mm', { locale: it })} - {format(curEvent.end, 'HH:mm)', { locale: it })} | {getValue(curEvent.extendedProps.intervention_data, 'name')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Card className="mb-2">
							<Card.Header className="text-primary h5">
								{t('training.labels.employer_list')}&nbsp;
								{curEvent.extendedProps.intervention_data ?
									<EmployerIcon operating_units={curEvent.extendedProps.intervention_data.operating_units} employer_list={curEvent.extendedProps.employer_list} />
									: ''}
							</Card.Header>
							<Card.Body>
								<Container>
									<Row className="border-bottom mb-2 pb-2">
										{curEvent.extendedProps.employer_list && curEvent.extendedProps.employer_list.length > 0 ?
											<Col className="border-end">
												<Row >
													<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
													<Col className="bg-secondary text-light ms-1">{t('employers.labels.qualification')} </Col>
													{canWrite ?
														<Col className="bg-secondary text-light ms-1 text-center ps-1 pe-1" sm="1">{t('global.labels.actions')} </Col>
														: ''}
												</Row>
												{curEvent.extendedProps.employer_list.map((i, key) => (
													<OneAssignedEmployer i={i} key={key} k={key} showComponentEdit={showComponentEdit} element_list={curEvent.employer_list} />
												))}

											</Col>
											: <Col className="font-italic"> {t('employers.messages.no_event_employer')}</Col>
										}

									</Row>

								</Container>

							</Card.Body>
						</Card>
						<Row>
							<Col>
								{curEvent.extendedProps.type === 'ordinary_intervention' ?
									(t('workarea.labels.ordinary_intervention'))
									: (t('workarea.labels.periodic_intervention'))
								}
							</Col>
							<Col>
								{curEvent.extendedProps.intervention_data && curEvent.extendedProps.intervention_data.service ?
									<ServiceBadge service={service_dict[curEvent.extendedProps.intervention_data.service.id]} freq={true} />
									: ''}
							</Col>
						</Row>
						{curEvent.extendedProps.intervention_data && curEvent.extendedProps.intervention_data.room_list && curEvent.extendedProps.intervention_data.room_list.length > 0 ?
							<Card className="mt-2">
								<Card.Header>
									{t('workarea.labels.roomlist')}
								</Card.Header>
								<Card.Body>
									<Container fluid>
										<Row className="p-1 mb-1">
											<Col className="ms-1 bg-secondary text-light" >{t('global.labels.name')}</Col>
											<Col className="ms-1 bg-secondary text-light" >{t('structures.labels.room_type')}</Col>
											<Col className="ms-1 bg-secondary text-light" sm="2" ></Col>
											<Col className="ms-1 bg-secondary text-light text-center" sm="1" >m<sup>2</sup></Col>
											<Col className="ms-1 bg-secondary text-light text-center" sm="2" >
												{contract && contract.type === 'civil' ?
													t('global.labels.standard_cleaning_short')
													:
													t('global.labels.risk_area_short')
												}
											</Col>
											<Col className="ms-1 bg-secondary text-light" sm="1" >Cdc</Col>
										</Row>
										{curEvent.extendedProps.intervention_data.room_list.map((room, index) => (
											<OneRoomRow key={index} room={room} intervention_type={curEvent.extendedProps.type} start={curEvent.start} ui_list={ui_list} intervention_data={curEvent.extendedProps.intervention_data} />

										))}
									</Container>
								</Card.Body>
							</Card>
							: ''
						}





					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		)
	} catch (err) {
		return (
			<Modal show={showEdit} onHide={handleClose} size="xl">
				<Modal.Header closeButton>
					<Modal.Title>
						{format(myTimestampToDate(curEvent.start), 'cccc dd/MM ', { locale: it })}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{console.log(err)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		)

	}

}















export default translate()(OperatorWorkshiftView)
