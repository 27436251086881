import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputGroup, Container, FormControl, Row, Col, Button, ButtonGroup, Spinner, Image } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import { translate } from 'react-polyglot'
import { format, subDays } from 'date-fns'
//import CurrencyInput from 'react-currency-input-field';
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import { compareName, myTimestampToDate } from "../util/ui_utils.js"

import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import { SearchSection } from "../components/SearchSection"
import ModalClauditlicence from '../components/ModalClauditlicence'


import { getDomainList as getDomainList2 } from '../Firebase2/Storage2/dbDomain';
import { getSystemGroupList as getSystemGroupList2 } from '../Firebase2/Storage2/dbGroup';
import { licence_status_dict } from '../components/constants/global_definitions.js';





const contractId = sessionStorage.getItem('contractId')

const newclauditlicence = {
	name: '',
	code: '',
	logo: '',
	basemail: '',
	/* 	startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(), */
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(elem_list, filterList, searchTerm) {
	if (elem_list) {
		const filt = elem_list.filter(i => (filterList.length === 0 || (filterList.indexOf(i.licence_status) >= 0)))
			.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
		if ((filterList.length === 0 && searchTerm.length < 2) || filt.length === elem_list.length) {
			return elem_list.length
		} else {
			return filt.length + "/" + elem_list.length
		}
	} else {
		return undefined

	}
}


const ClauditlicenceView = ({ mylocation, t }) => {

	const _licence_status_dict = licence_status_dict({ t })

	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [curElement, setCurrentElement] = useState(newclauditlicence)
	const [searchTerm, setSearchTerm] = useState('')
	const [ui_list, setUiList] = useState({})
	const [filterAppliedList, setFilterAppliedList] = useState({ status: ['active'], type: [] })

	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}


	const handleChangeSearch = (s) => {
		console.log(s)
		if (s.value.length > 0) {
			setSearchTerm(s.value)
		} else {
			setSearchTerm('')
		}
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}

	const checkDate = (i) => {
		try {
			let final_date = i.endDate.toDate()
			if (i.warning_days) {
				final_date = subDays(final_date, parseInt(i.warning_days))
			}
			if (new Date() >= i.endDate.toDate()) {
				return 1
			} else if (new Date() >= final_date) {
				return 0
			} else {
				return -1
			}

		} catch (err) {
			console.log(err, i)
			return -1

		}

	}

	const openDomain = (domain) => {
		//window.open(domain.domain + '.claudit.net/#contractdashboard')
		window.open(domain.domain + '.testdev.eurika.net/#contractdashboard')
	}

	const checkDomain = (mydomain) => {
		const a = element_list.filter(f => (f.domain === mydomain))
		if (a.length > 0 && mydomain !== 'news' && mydomain !== 'admin' && mydomain !== 'catalog') {
			return true
		} else {
			return false
		}
	}

	const initValues = async () => {
		setLoading(true)
		let newlist = await getDomainList2(contractId)
		let glist = await getSystemGroupList2()
		let receiver_group_list = []
		let group_dict = {}
		glist.map(g => (group_dict[g.id] = g))
		newlist = newlist.filter(c => c.id !== 'news' && c.id !== 'catalog')
		newlist.sort(compareName)
		console.log("source clauditlicence_list", newlist)
		setUiList({ group_list: glist, group_dict: group_dict, receiver_group_list })
		setElementList(newlist)
		setLoading(false)
	}

	useEffect(() => {
		initValues()
	}, [])

	return (
		<div>
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col className="h3">
						<PageTitle title={t('global.pages.clauditlicences')} admin={true} />
					</Col>
					<Col>
						<ButtonGroup size="sm" className="me-1">
							<Button disabled variant="secondary"><FontAwesomeIcon icon="filter" /></Button>
							{Object.entries(_licence_status_dict).map((s, k) => (
								<Button key={k} size="sm" disabled={element_list && element_list.filter(f => (f.licence_status === s[0])).length === 0}
									variant={filterAppliedList.status.indexOf(s[0]) === -1 ? 'outline-secondary' : "secondary"}
									onClick={(e) => updateAppliedFilter('type', s[0])}>
									<FontAwesomeIcon icon={s[1].icon} />
									{s[1].label}
								</Button>
							))}
						</ButtonGroup>
					</Col>
					<Col sm={3}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }} sm="2">
						<ModalClauditlicence is_new={true} t={t} item={element_list} setItem={setElementList} ui_list={ui_list} checkDomain={checkDomain} />
					</Col>
				</Row>
				{element_list && element_list.length > 0 &&
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1" sm="1" md="1">{t('global.labels.code')}</Col>
						<Col className="bg-secondary ms-1" sm="1" md="1">Stato</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.domain')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.name')}</Col>
						<Col className="bg-secondary ms-1" sm="2">{t('global.labels.start')}</Col>
						<Col className="bg-secondary ms-1" sm="2">{t('global.labels.end')}</Col>
						<Col className="bg-secondary ms-1 text-center" sm="2" md="1">{t('global.labels.actions')}</Col>
					</Row>
					}

			</Container>
			<Container fluid>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						{element_list
							.filter(i => filterAppliedList.type.length === 0 || filterAppliedList.indexOf(i.licence_status) >= 0)
							.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
								<Row key={key} className={"p-1 border-bottom" + (i.domain === 'admin' ? ' bg-danger text-light' : '')}>
									<Col sm="1" md="1">
										{i.code}
									</Col>
									<Col>
										{i.licence_status === 'archived' && <FontAwesomeIcon icon="archive" />}
										{i.licence_status === 'demo' && <FontAwesomeIcon icon="magic" />}
										{i.licence_status === 'active' && <FontAwesomeIcon icon="check" />}
									</Col>
									<Col>
										{i.domain}<span className="text-secondary">.claudit.net</span>
									</Col>
									<Col>
										<Row>
											<Col>
												{i.name}
											</Col>
											<Col sm="2">
												{i.logo && i.logo.length > 0
													?
													<Image style={{ maxWidth: '50px' }} src={i.logo} fluid className="me-1" />
													: ''}
											</Col>
										</Row>
									</Col>
									{i.id !== 'admin' ?
										<>
											<Col sm="2">
												{format(myTimestampToDate(i.startDate), 'dd/MM/yyyy')}
											</Col>
											<Col sm="2" className={` ` + (checkDate(i) === 0 ? "text-warning" : (checkDate(i) === 1 ? "text-danger" : "text-info"))}>
												{format(myTimestampToDate(i.endDate), 'dd/MM/yyyy')}
											</Col>
										</>
										: <>
											<Col sm="4"></Col>
										</>}
									<Col className="text-center" sm="2" md="1">
										<ButtonGroup>
											{i.id !== 'admin' ?
												<Button variant="outline-primary" size="sm" onClick={() => openDomain(i)}><FontAwesomeIcon icon='external-link-alt' /></Button>
												: ''}
											<ModalClauditlicence t={t} item={i} setItem={onChangeHandler} ui_list={ui_list} item_list={element_list} setParentList={setElementList} index={key} checkDomain={checkDomain} />
										</ButtonGroup>
									</Col>

								</Row>
							))}
					</>
					: <> {t('licence.messages.no_clauditlicence')}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(element_list, filterAppliedList.status, searchTerm) + " " + t('global.pages.clauditlicences')} />
		</div>
	);
}


export default translate()(ClauditlicenceView)


