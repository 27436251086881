import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, ButtonGroup, Row, Col, Form, Button, Card, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"


import EditContext, { EditProvider } from "../components/contexts/edit.js"
import SearchContext, { SearchProvider } from "../components/contexts/searchContext"
import { SearchSection } from "../components/SearchSection"
import DomainContext from '../components/contexts/domainContext'
import { RoomProvider } from "../components/contexts/roomList.js"
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"


import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import ModalTimeAnalisys from "../components/ModalTimeAnalisys"
import ModalWorkshiftAssignment from "../components/modal/ModalWorkshiftAssignment"
import ListStructureGroupItem from "../components/ListStructureGroupItem.js"
import { ModalWorkarea, newworkarea, sumChildrenData } from '../components/modal/ModalWorkarea.js';

import { getSelectedElement, checkComponentPresence, rgba2hex, compareName, getFloatNumber, getValue, getStructureFromWorkareas } from "../util/ui_utils.js"
import { ItemProgress, ServiceBadge, RiskareaBadge, ContainerInfoPopover, RoomInfoBadge, ModalInitButton, OrderButton } from '../components/StructureGraphicElements.js'



import { editWorkarea as editWorkarea2, getWorkareaList as getWorkareaList2, deleteWorkarea as deleteWorkarea2 } from '../Firebase2/Storage2/dbWorkarea';
import {
	getPavillonList as getPavillonList2,
	getAllFloorInStructure as getAllFloorInStructure2,
	getAllDepartmentInStructure as getAllDepartmentInStructure2,
} from '../Firebase2/Storage2/dbBaseCompany';
import {
	getAllRoomList as getAllRoomList2
} from '../Firebase2/Storage2/dbRoom';
import { ModalUnassignedRoom } from '../components/modal/ModalUnassignedRoom.js';


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//############################################################################################################################################
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//let referent_role_list = []

const defaultfilter = { service: [], structure: [], pavillon: [], floor: [], department: [] }


//const contractId = sessionStorage.getItem('contractId')

/* let contract = {}
try {
	contract = JSON.parse(sessionStorage.getItem('contract'))
} catch (err) { }

 */
let all_floor_dict = {}
let all_pavillon_dict = {}
let all_department_dict = {}

let load_page = false
let permission_department_list = []
let permission_structure_list = []
let permission_workarea_list = []


function getElementCount(elem_list, filterAppliedList, searchTerm) {
	if (elem_list) {
		const filt = elem_list.filter(w => checkWorkareaServiceFilter(w, filterAppliedList))
			.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
		if ((filterAppliedList.service.length === 0 && searchTerm.length < 2) || filt.length === elem_list.length) {
			return elem_list.length
		} else {
			return filt.length + "/" + elem_list.length
		}
	} else {
		return undefined

	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkWorkareaServiceFilter(w, filterAppliedList) {
	let ispresent = false
	if (w.service_summary && w.service_summary.some(d => filterAppliedList.service.indexOf(d.id) !== -1)) {
		//if (w.service_summary && w.service_summary.filter(s => s.id === d).length > 0) {
		ispresent = true
	}
	return ispresent
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkStructureFilter(i, filterstructure) {
	let ispresent = false
	console.log("-----------  -------------       ---", i, filterstructure)
	if (i.structure_list && i.structure_list.some(r => (filterstructure.indexOf(r) >= 0))) {
		ispresent = true
	}
	return ispresent
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkPavillonFilter(i, filterpavillon) {
	let ispresent = false
	console.log("-----------  -------------       ---", i, filterpavillon)
	if (i.pavillon_list && i.pavillon_list.some(r => (filterpavillon.indexOf(r) >= 0))) {
		ispresent = true
	}
	return ispresent
}







////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const WorkareaView = ({ t, uData }) => {
	return (
		<SearchProvider>
			<EditProvider>
				<Workarea t={t} uData={uData} />
			</EditProvider>
		</SearchProvider>

	)
}








////////////////////////////////////////////////////////////////////////////////////////////////////////
////
//// ----> WorkareaView function
////////////////////////////////////////////////////////////////////////////////////////////////////////
const Workarea = ({ t, uData }) => {

	const { domain, domain_data } = useContext(DomainContext)
	const search = useContext(SearchContext)
	let userData = uData
	const edit = useContext(EditContext)
	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()

	const { contract, structureElements, roomtype_dict, all_workarea_dict, risk_area_list, risk_area_dict, service_dict, operation_dict, setReverseOrder } = useContext(GlobalDataContext)

	const [structure_list, setStructureList] = useState([])
	//	const [showEditIntervention, setShowEditIntervention] = useState(false);
	//	const [showEdit, setShow] = useState(false)
	const [curWorkarea, setCurWorkarea] = useState(newworkarea)
	const [ui_list, setUiList] = useState({})
	const [element_list, setElementList] = useState([])
	const [loading, setLoading] = useState(true)
	const [checkedWA, setCheckedWA] = useState([])
	const [curStructure, setCurStructure] = useState()
	const [loadingStructure, setLoadingStructure] = useState(false)

	const [searchTerm, setSearchTerm] = useState('')
	const [firstOpenStructure, setFirstOpenStructure] = useState(true)
	const [filterAppliedList, setFilterAppliedList] = useState(defaultfilter)

	//	const [showEditWorkareaCart, setShowEditWorkareaCart] = useState(false)
	const [canWrite, setWritePage] = useState(false)
	const [showProcess, setShowProcess] = useState(false)
	const [sourcestructureElements, setSourceStructureElements] = useState({
		pavillon_list: []
	})



	const initValues = async () => {
		console.log("WORKAREA udata", userData)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('workareas') !== -1)) {
			setWritePage(true)
		}
		if (contract && domain.length > 0) {
			contractId = contract.id
			setLoading(true)
			let _structure_list = structureElements.structure_list
			userData = uData
			if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
				if (userData.contract_dict[contractId][0].mode === 'workarea') {
					permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
					permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
					console.log("permission_structure_list", permission_structure_list)
					_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				} else {
					if (userData.contract_dict[contractId][0].structure) {
						if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
							permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
							console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
						}
						if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
							console.log("ADD STRUCTURE PERMISSION")
							permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
							_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
						}
						console.log("permission structure_list", permission_structure_list)
					}
				}
			}
			setStructureList(_structure_list)
			//const floor_list = await getAllFloorName(contractId)

			//		let department_dict = Object.assign({}, ...department_list.map((x) => ({ [x.id]: x })));

			all_pavillon_dict = structureElements.all_pavillon_dict //JSON.parse(sessionStorage.getItem('all_pavillon_dict'))
			//			all_floor_dict = structureElements.all_floor_dict //JSON.parse(sessionStorage.getItem('all_floor_dict'))
			//all_department_dict = structureElements.all_department_dict //JSON.parse(sessionStorage.getItem('all_department_dict'))

			let db_workarea_list = await getWorkareaList2(domain, contractId)

			setUiList({
				...ui_list, pavillon_dict: all_pavillon_dict, service_dict, operation_dict,
				roomtype_dict, risk_area_dict, risk_area_list, all_room_dict: structureElements.all_room_dict,
			})
			console.log(userData,)
			if (!userData.isAdmin) {
				if (permission_workarea_list.length > 0) {
					db_workarea_list = db_workarea_list.filter(w => permission_workarea_list.indexOf(w.id) >= 0)
				} else if (permission_structure_list.length > 0) {
					db_workarea_list = db_workarea_list.filter(w => w.structure_list.some(d => permission_structure_list.indexOf(d) !== -1))
					console.log("FILTERED structure", db_workarea_list)
					if (permission_department_list.length > 0) {
						db_workarea_list = db_workarea_list.filter(w => (w.room_list.length === 0 || w.department_list.some(d => permission_department_list.indexOf(d) !== -1)))
					}
				}
			}
			db_workarea_list.sort(compareName)
			setElementList(db_workarea_list)
			edit.setEditList(db_workarea_list)
			setLoading(false)
		} else {

		}
	}

	useEffect(() => {
		load_page = false
		async function loadData() {
			let contractId = cid
			console.log("WORKAREEA reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("WORKAREA init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])




	useEffect(() => {
		console.log('WORKAREA loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	/* 
		const handleChangeSearch = (s) => {
			console.log(s)
			if (s.value.length > 1) {
				setSearchTerm(s.value)
			} else {
				setSearchTerm('')
	
			}
		}
	
	
	 */









	const handleShowEditWorkareaRoom = (w) => {
		setFirstOpenStructure(true)
		edit.setWorkareaRoomList(w.room_list)
		edit.setEditGlobal(true)
		setCheckedWA([w.id])
		setCurWorkarea(w)
		sessionStorage.setItem('myroomList', JSON.stringify([]))
	}

	const onSelectStructure = async (el) => {
		setFirstOpenStructure(false)
		console.log(el)
		edit.setFilterUnusedRoom(false)
		setLoadingStructure(true)
		if (el) {
			const pavillon_list = await getPavillonList2(domain, contractId, el.id)
			const floor_list = await getAllFloorInStructure2(el.id)
			const department_list = await getAllDepartmentInStructure2(el.id)
			const room_list = await getAllRoomList2({ structureId: el.id })
			setSourceStructureElements({ pavillon_list, floor_list, department_list, room_list })
		}
		setCurStructure(el)
		setLoadingStructure(false)

	}








	const handleAddToWorkarea = (room) => {
		setShowProcess(true)
		console.log(room)
		let editworkareas = [...element_list]
		if (room.end_item) {
			console.log('is end ( =is room)', editworkareas.length)
			for (let wa = 0; wa < editworkareas.length; wa++) {
				//{ 'name': 'o', code: 'bg1', groups: ['azienda 1', 'padiglione A', 'piano1'] },
				if (checkedWA.indexOf(String(editworkareas[wa].id)) >= 0) {
					let editroomlist = editworkareas[wa].room_list
					console.log(editroomlist)

					if (!checkRoomPresence(room, editroomlist)) {
						console.log("OLD roomLIST", editroomlist)
						//						editroomlist.push({ name: el.name, id: el.code, groups: [], id: el.id })
						//						editroomlist.push({ ...room, groups: [] })
						editroomlist.push({ id: room.id, name: room.name, roomtype: { id: room.roomtype.id }, parent_list: room.parent_list, cdc: room.cdc || '', size: room.size, groups: [] })
						editworkareas[wa].room_list = editroomlist
						const wa_data = sumChildrenData(editworkareas[wa], ui_list)
						editworkareas[wa].size = wa_data.size
						editworkareas[wa].risk_area_summary = wa_data.risk_area_summary
						editworkareas[wa].service_summary = wa_data.service_summary
						editworkareas[wa].structure_list = wa_data.structure_list
						editworkareas[wa].pavillon_list = wa_data.pavillon_list
						editworkareas[wa].floor_list = wa_data.floor_list
						editworkareas[wa].department_list = wa_data.department_list
						console.log("NEW roomLIST", editroomlist)
						console.log(editworkareas)

						setElementList(editworkareas)
						edit.setWorkareaRoomList(editroomlist)
						editWorkarea2(domain, contractId, editworkareas[wa].id, editworkareas[wa])
					} else {
						childRefAlert.current.addAlert('danger', t('workareas.messages.room_present'))
					}
				}
			}
		} else {
			// department, floor, pavillon, structure
			for (var wa = 0; wa < editworkareas.length; wa++) {
				if (checkedWA.indexOf(String(editworkareas[wa].id)) >= 0) {
					let editroomlist = editworkareas[wa].room_list
					let room_list = collect_room_list(room, structureElements)
					console.log("ELEM ROOM LIST", room, room_list)
					for (let i = 0; i < room_list.length; i++) {

						if (!checkRoomPresence(room, editroomlist)) {
							const r = room_list[i]
							console.log("OLD LIST", editroomlist)
							editroomlist.push({ id: r.id, name: r.name, roomtype: { id: r.roomtype }, parent_list: r.parent_list, cdc: r.cdc || '', size: r.size, groups: [] })

						}
					}
					editworkareas[wa].room_list = editroomlist
					console.log("NEW LIST", editroomlist)
					const wa_data = sumChildrenData(editworkareas[wa], ui_list)
					editworkareas[wa].size = wa_data.size
					editworkareas[wa].risk_area_summary = wa_data.risk_area_summary
					editworkareas[wa].service_summary = wa_data.service_summary
					editworkareas[wa].structure_list = wa_data.structure_list
					editworkareas[wa].pavillon_list = wa_data.pavillon_list
					editworkareas[wa].floor_list = wa_data.floor_list
					editworkareas[wa].department_list = wa_data.department_list
					console.log("NEW roomLIST", editroomlist)
					console.log(editworkareas)
					setElementList(editworkareas)
					edit.setWorkareaRoomList(editroomlist)
					editWorkarea2(domain, contractId, editworkareas[wa].id, editworkareas[wa])
				}
			}
			console.log(room)
		}
		setShowProcess(false)
	}


	const handleRemoveRoom = async ({ wa, room }) => {
		console.log("delete", wa, room)
		let editworkareas = [...element_list]
		const c = checkComponentPresence(wa, element_list, 'id')
		console.log(c)
		const cr = checkComponentPresence(room, element_list[c].room_list, 'id')
		editworkareas[c].room_list.splice(cr, 1)
		edit.setWorkareaRoomList(editworkareas[c].room_list)
		/* 		const same_structure = editworkareas[c].room_list.map(r => r.parent_list[0] === room.parent_list[0])
				const same_department = editworkareas[c].room_list.map(r => r.parent_list[3] === room.parent_list[3])
				if (same_structure.length === 0) {
					const index = editworkareas[c].structure_list.indexOf(room.parent_list[0])
					if (index > 0) {
						editworkareas[c].structure_list.splice(index, 1)
					}
				}
				if (same_department.length === 0) {
					const index = editworkareas[c].department_list.indexOf(room.parent_list[3])
					if (index > 0) {
						editworkareas[c].department_list.splice(index, 1)
					}
				} */
		const wa_data = sumChildrenData(editworkareas[c], ui_list)
		editworkareas[c].size = wa_data.size
		editworkareas[c].risk_area_summary = wa_data.risk_area_summary
		editworkareas[c].service_summary = wa_data.service_summary
		editworkareas[c].structure_list = wa_data.structure_list
		editworkareas[c].pavillon_list = wa_data.pavillon_list
		editworkareas[c].floor_list = wa_data.floor_list
		editworkareas[c].department_list = wa_data.department_list
		await editWorkarea2(domain, contractId, editworkareas[c].id, editworkareas[c])
		setElementList(editworkareas)

	}


	const handleDeleteWorkarea = (el) => {
		const cb = async () => {
			const editlist = [...element_list]
			const c = checkComponentPresence(el, editlist, 'id')
			editlist.splice(c, 1)
			setElementList(editlist)
			let return_data = await deleteWorkarea2(domain, contractId, el.id)
			console.log("delete", return_data, el.id)
		}
		console.log(el)
		childRefDelete.current.confirmDelete(t('workareas.messages.delete_workarea_title'), t('workarea.messages.delete_workarea', { name: el.name }), cb)
	}



	const handleClearWorkarea = async (wa) => {
		console.log("clear", wa)
		let editworkareas = [...element_list]
		const c = checkComponentPresence(wa, element_list, 'id')
		console.log(c)
		editworkareas[c].room_list = []
		edit.setWorkareaRoomList(editworkareas[c].room_list)
		editworkareas[c].size = 0
		editworkareas[c].risk_area_summary = []
		editworkareas[c].service_summary = []
		editworkareas[c].structure_list = []
		editworkareas[c].pavillon_list = []
		editworkareas[c].floor_list = []
		editworkareas[c].department_list = []
		await editWorkarea2(domain, contractId, editworkareas[c].id, editworkareas[c])
		setElementList(editworkareas)


	}

	const onCloseGlobalEdit = () => {
		setCheckedWA([])
		edit.setEditGlobal(false)
	}

	/* 
		const updateWokareaStructureData = () => {
			for (const room of curWorkarea.room_list) {
				if (curWorkarea.structure_list) {
					if (curWorkarea.structure_list.indexOf(room.parent_list[0] !== -1)) {
						curWorkarea.structure_list.push(room.parent_list[0])
					}
					if (curWorkarea.department_list.indexOf(room.parent_list[3] !== -1)) {
						curWorkarea.department_list.push(room.parent_list[3])
					}
	
				} else {
					curWorkarea.structure_list = [room.parent_list[0]]
					curWorkarea.department_list = [room.parent_list[3]]
				}
			}
		}
	 */









	return (
		<>

			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			<Container className="titleStickyTop" fluid>
				<Row>
					<Col sm="6">
						<PageTitle title={t('global.pages.working_area')} />
					</Col>
					{element_list.length > 0 ?
						<>
							<Col sm="2">
								<ModalFilter t={t} ui_list={ui_list} filterAppliedList={filterAppliedList} setFilterAppliedList={setFilterAppliedList} element_list={element_list} />
							</Col>
							<Col className="text-center" sm="1">
								<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
							</Col>
						</>
						: ''}
					{canWrite ?
						<Col className="text-end">
							<ModalUnassignedRoom t={t} />
							{element_list.length > 0 ?
								(edit.editGlobal ?
									<>
										<Button variant="secondary" onClick={() => onCloseGlobalEdit()}><FontAwesomeIcon icon='check' />{t('globals.buttons.close')}</Button>
									</>
									: ''
								)
								: ''}
							&nbsp;
							{!edit.editGlobal ?
								<ModalWorkarea is_new={true} canWrite={canWrite} element_list={element_list} ui_list={ui_list} t={t} functions={{ edit: setElementList, editroom: handleShowEditWorkareaRoom }} />
								: ''}
						</Col>
						: ''}
				</Row>
				{element_list.length > 0 ?
					<>
						{edit.editGlobal ?
							<Row>
								<Col className="border-bottom border-info text-center m-1" sm="5">
									<b>{t('workareas.labels.workarea_room_list')}</b>
								</Col>
								<Col className="border-bottom border-info text-center m-1 text-info">
									<b>{t('workareas.labels.room_insertion')}</b>
								</Col>
							</Row>
							:
							<Row className="p-1 text-light">
								<Col className="bg-secondary" >{t('workareas.labels.name')}</Col>
								{checkedWA.length === 0 ?
									<>
										<Col className="bg-secondary ms-1 text-center p-0" sm="1" >m<sup>2</sup></Col>
										<Col className="bg-secondary ms-1 text-center" sm="2">
											{contract.type === 'civil' ?
												t('global.labels.standard_cleaning')
												:
												t('global.labels.risk_area')
											}
										</Col>
									</>
									: ''}
								<Col className="bg-secondary ms-1 text-center" sm="2">{t('global.labels.action')}</Col>
							</Row>
						}
					</>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status" />&nbsp;Caricamento in corso
						</Col>
					</Row>
					:
					<Row className="v-100">

						<Col sm={checkedWA.length > 0 ? "5" : ''} className="v-100">
							{element_list.length > 0 ?
								<>
									{element_list
										.filter(i => (
											(filterAppliedList.service.length === 0 ||
												checkWorkareaServiceFilter(i, filterAppliedList))
											&& (filterAppliedList.structure.length === 0 ||
												checkStructureFilter(i, filterAppliedList.structure))
											&& (filterAppliedList.pavillon.length === 0 ||
												checkPavillonFilter(i, filterAppliedList.pavillon))
										))
										.filter(i => (search.searchTerm.length === 0 || (i.name.toLowerCase().indexOf(search.searchTerm.toLowerCase()) !== -1)))

										.map((w, key) => (

											<Row key={key} className={"p-1 border-bottom border-dark rounded mb-4 " + (edit.editGlobal && checkedWA.indexOf(String(w.id)) < 0 ? 'd-none' : '')}>
												<Col>
													<Row >
														<Col className="font-weight-bold">
															{w.name}
															&nbsp;
															{showProcess ?
																'Importazione IN CORSO'
																: ''
															}
														</Col>
														{checkedWA.length === 0 ?
															<>
																{w.service_summary ?
																	<Col>
																		{Object.keys(w.service_summary).map((s_index, index) => (
																			<span key={index} className="badge border me-1 border-secondary text-dark" >
																				<ServiceBadge service={ui_list.service_dict[w.service_summary[s_index].id]} freq={true} />
																			</span>
																		))}
																	</Col>
																	: ''}

																<Col sm="1" className="text-center">
																	{w.room_list.length > 0 ? <>{w.size.toFixed(2)}</> : ''}
																</Col>
																<Col sm="2">
																	{w.risk_area_summary && w.room_list.length > 0 ? <ItemProgress ui_list={ui_list} risk_list={w.risk_area_summary} item={w} mode='mq' use_room_count={true} /> : ''}
																</Col>
																<Col sm="2 p-0 text-center">
																	<ButtonGroup className="workarea-buttons">
																		{canWrite ?
																			<Button variant="outline-info" size="sm" onClick={(i) => handleShowEditWorkareaRoom(w)} ><FontAwesomeIcon icon="building" /></Button>
																			: ''}
																		<ModalWorkarea is_new={false} canWrite={canWrite} ui_list={ui_list} t={t} element={w} element_list={element_list} functions={{ edit: setElementList, editroom: handleShowEditWorkareaRoom }} />
																		{canWrite ?
																			<Button variant="danger" size="sm" onClick={(i) => handleDeleteWorkarea(w)}><FontAwesomeIcon icon='trash' /> </Button>
																			: ''}
																		<ModalWorkshiftAssignment ui_list={ui_list} t={t} element={w} element_list={element_list} functions={{ edit: setElementList }} canWrite={canWrite} />
																	</ButtonGroup>
																</Col>
															</>
															: <Col>{w.room_list && w.room_list.length > 0 ? <Button variant="danger" size="sm" onClick={() => handleClearWorkarea(w)}><FontAwesomeIcon icon="trash" /> Rimuovi tutti i locali</Button> : ''}</Col>

														}
													</Row>

													{w.room_list && w.room_list.length > 0 ?
														<RoomList w={w} ui_list={ui_list} canWrite={canWrite} functions={{ remove: handleRemoveRoom }} checkedWA={checkedWA} t={t} structure_list={structure_list} />
														: <Row>
															<Col sm="1">
															</Col>
															<Col className="font-italic">
																{t('workareas.messages.no_room')}
															</Col>
														</Row>
													}

												</Col>
											</Row>
										))}
								</>
								: <Row>
									<Col className="font-italic">
										{t('workeareas.messages.no_workearea')}
									</Col>
								</Row>}
						</Col>
						{checkedWA.length > 0
							?

							<Col sm="7" className="v-100 editSection">
								<Row>
									<Col sm="4">
										{t('structure.labels.structure')}
									</Col>
									<Col>
										<Form.Control as="select" className="mb-2" name="structureId" onChange={(event) => onSelectStructure(getSelectedElement(event.target.value, structure_list, 'id'))} >
											<option value="-1" >{t('global.labels.select_structure')}</option>
											{structure_list.map((i, key) => (
												<option key={key} value={i.id}  >{i.name}</option>
											))}
										</Form.Control>

									</Col>
								</Row>
								{!firstOpenStructure ?
									<>
										{!loadingStructure ?
											<>
												<Row>
													<Col sm="3">
														<ButtonGroup>

															<Button variant="info" onClick={() => edit.setFilterUnusedRoom(true)} ><FontAwesomeIcon icon="filter" /> {t('workareas.buttons.filter_unused')}</Button>
															{edit.filterUnusedRoom ?
																<Button variant="outline-info" onClick={() => edit.setFilterUnusedRoom(false)}><FontAwesomeIcon icon="times" /></Button> : ''}
														</ButtonGroup>
													</Col>
													<Col sm="2">
														<OrderButton />
													</Col>

													<Col>
														<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
													</Col>
												</Row>
												<Row className="p-1 text-light">
													<Col className="bg-info ms-1" sm="1">{t('global.labels.add')}</Col>
													<Col className="bg-info ms-1" >{t('workareas.labels.name')}</Col>
													<Col className="bg-info ms-1" sm="1" >m<sup>2</sup></Col>
													<Col className="bg-info ms-1" sm="2">
														{contract.type === 'civil' ?
															t('global.labels.standard_cleaning')
															:
															t('global.labels.risk_area')
														}
													</Col>
													<Col className="bg-info ms-1" sm="1" ></Col>
												</Row>
												<Row className="border-bottom border-info">
													<Col className="source_list_container">
														<OneStructure structure={curStructure} curWorkarea={curWorkarea} checkedWA={checkedWA} handleAddToWorkarea={handleAddToWorkarea} searchTerm={searchTerm} ui_list={ui_list} structureElements={sourcestructureElements} permission_department_list={permission_department_list} />
													</Col>
												</Row>
											</>
											:
											<Row>
												<Col>
													<Spinner animation="border" role="status">
														<span className="sr-only">Loading...</span>
													</Spinner>
												</Col>
											</Row>
										}
									</> :
									''
								}
							</Col>
							: ''
						}
					</Row>


				}


				<Modal show={showProcess} size="sm" backdrop="static" className="bg-secondary">
					<Modal.Body>
						Inserimento in corso
					</Modal.Body>
				</Modal>


			</Container>
			{!loading &&
				<Footer count_text={getElementCount(element_list, filterAppliedList, searchTerm) + " " + t('global.labels.workareas')} />
				}


		</>
	);
}






//////////////////////////////////////////////////////////////////////////////////////////////////////////////
const RoomList = ({ checkedWA, w, ui_list, functions, canWrite, t, structure_list }) => {
	//const edit = useContext(EditContext)
	const { structureElements } = useContext(GlobalDataContext)


	const [showRooms, setShowRooms] = useState()

	const errorRoomList = w.room_list.filter(r => !structureElements.all_room_dict[r.id]).length

	useEffect(() => {
		//console.log(checkedWA)
		if (checkedWA[0] === w.id) {
			setShowRooms(true)
		} else { setShowRooms(false) }

	}, [checkedWA])

	return (

		<Row className="p-0 mt-1">
			<Card key={w.id} as={Col}>
				<Card.Header className="p-1">
					<Row>
						<Col onClick={() => setShowRooms(!showRooms)} as={Button} className="btn-light text-start ps-4">
							{t('workarea.labels.roomlist')}
							<ContainerInfoPopover w={w} t={t} />
							{errorRoomList > 0 &&
								<OverlayTrigger
									placement="auto"
									delay={{ show: 250, hide: 100 }}
									overlay={
										<Tooltip  >
											{errorRoomList} locali non più presenti
										</Tooltip>}
								>
									<FontAwesomeIcon icon="exclamation-triangle" />
								</OverlayTrigger>
							}
						</Col>
						{checkedWA.length === 0 ?
							<>
								<Col sm="2" className="text-center">
									<ModalTimeAnalisys element={w} ui_list={ui_list} t={t} is_workarea={true} />
									{/* 									<ModalWorkshiftAssignment functions={{edit:alert}} element={w} ui_list={ui_list} t={t} is_workarea={true} canWrite={canWrite}/> */}
								</Col>
							</>
							: ''}
					</Row>
				</Card.Header>
				{showRooms ?
					<Card.Body>
						<Row className="p-1 section_title mb-1">
							<Col className="ms-1 bg-light text-secondary" sm="2" >{t('global.labels.number')}</Col>
							<Col className="ms-1 bg-light text-secondary" sm="3">{t('structures.labels.room_type')}</Col>
							<Col className="ms-1 bg-light text-secondary" >{t('structures.labels.service_list')}</Col>
							

							{checkedWA.length === 0 ?
								<>
									<Col className="ms-1 bg-light text-secondary" sm="3" ></Col>
									<Col className="ms-1 bg-light text-secondary" sm="1" >CdC</Col>
									
								</>
								: ''
							}
							<Col className="ms-1 bg-light text-secondary" sm="1"></Col>
						</Row>
						<Row>
							<Col className={checkedWA[0] === w.id ? 'source_list_container' : ''}>
								{w.room_list.map((room, index) => (
									<OneRoom key={room.id} t={t} index={index} room={room} workarea={w} ui_list={ui_list} checkedWA={checkedWA} handleRemoveRoom={functions.remove} canWrite={canWrite} />

								))}
							</Col>
						</Row>
					</Card.Body>
					: ''}
			</Card>
		</Row>
	)

}









/////////////////////////////////////////////////////////////////////////////////////////////////////
const OneRoom = ({ room, index, workarea, ui_list, checkedWA, handleRemoveRoom, canWrite, t }) => {
	const { roomtype_dict, risk_area_dict, structureElements } = useContext(GlobalDataContext)
	const [roomExist, setRoomExist] = useState(structureElements.all_room_dict[room.id] ? true : false)
	const _room = structureElements.all_room_dict[room.id] || room

	const rt_name = _room.roomtype ? roomtype_dict[_room.roomtype.id].name : '--'

	return (
		<Row key={index} className="element_row border-bottom">
			<Col sm="2">
				<RoomInfoBadge parent_list={_room.parent_list} structure_list={ui_list.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
				{!roomExist &&
					<OverlayTrigger
						placement="auto"
						delay={{ show: 250, hide: 100 }}
						overlay={
							<Tooltip  >
								Locale non più presente
							</Tooltip>}
					>
						<FontAwesomeIcon icon="exclamation-triangle" />
					</OverlayTrigger>
				}
				{_room.name}
			</Col>
			<Col sm="3">
				<span className="text-muted">
					{rt_name}
				</span>
			</Col>
			<Col>
				{renderServiceSummary(_room, ui_list, t)}
			</Col>
			{checkedWA.length === 0 ?
				<>
					<Col sm="1" className="text-center">{getFloatNumber(_room.size)}</Col>
					<Col sm="2" className="text-center">
						{_room.risk_area &&
							<RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
						}
					</Col>
					<Col sm="1">
						{_room.cdc}
					</Col>
				</>
				: ''
			}
			<Col sm="1" className="p-0 text-center">
				{canWrite ?
					<Button variant="outline-danger" size="sm" onClick={() => handleRemoveRoom({ wa: workarea, room: _room })}><FontAwesomeIcon icon='trash' /> </Button>
					: ''}
			</Col>
		</Row>

	)
}



/////////////////////////////////////////////////////////////////////////////////////////////////////

const renderServiceSummary = (cur_c, ui_list, t) => {
	if (cur_c.roomtype) {
		const roomtype = ui_list.roomtype_dict[cur_c.roomtype.id]
		if (roomtype && roomtype.service_list) {
			return (
				<>
					<Row>
						<Col>
							{roomtype.service_list.map((i, k) => (
								<span key={k} className="badge border me-1 border-secondary text-dark">
									<ServiceBadge service={ui_list.service_dict[i.service.id]} freq={true} />
								</span>
							))}
						</Col>
					</Row>
				</>
			)
		} else {
			return (<i>{t('structure.messages.no_services')}</i>)

		}
	} else {
		return (<i>{t('structure.messages.no_roomtype')}</i>)
	}
}


/////////////////////////////////////////////////////////////////////////////////////////////////////
const checkRoomPresence = (el, el_list) => {
	for (var i = 0; i < el_list.length; i++) {
		console.log('check presence', el.id, el_list[i])
		if (el_list[i].id === el.id) {
			return true
		}
	}
	return false
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
const collect_room_list = (el, structureElements) => {
	let _room_list = []
	if (el.level === 0) {
		if (structureElements.pavillon_list) {
			_room_list = structureElements.room_list.filter(p => p.parent_list[0] === el.id)
		}

	} else if (el.level === 1) {
		if (structureElements.floor_list) {
			_room_list = structureElements.room_list.filter(p => (p.parent_list && p.parent_list[1] === el.id))
		}

	} else if (el.level === 2) {
		if (structureElements.department_list) {
			_room_list = structureElements.room_list.filter(p => (p.parent_list && p.parent_list[2] === el.id))
		}

	} else if (el.level === 3) {
		_room_list = structureElements.room_list.filter(p => (p.parent_list && p.parent_list[3] === el.id))
	}
	console.log(_room_list)

	return _room_list
}



/////////////////////////////////////////////////////////////////////////////////////////////////////
function OneStructure({ structure, checkedWA, handleAddToWorkarea, curWorkarea, searchTerm, ref, ui_list, structureElements, permission_department_list }) {
	const { contractId } = useContext(GlobalDataContext)
	const [curStructure, setCurStructure] = useState(structure)
	const [loading,] = useState(false)

	const onChangeHandlerStructure = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curStructure[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curStructure[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurStructure({ ...curStructure, [name]: value })
	}

	return (
		<RoomProvider>
			{loading ? <Spinner animation="border" role="status">
				<span className="sr-only">Loading...</span>
			</Spinner> : ''}
			<ListStructureGroupItem ref={ref} item={structure} is_summary={true} parent={structure} level={0} setParent={onChangeHandlerStructure} searchTerm={searchTerm} curWorkarea={curWorkarea} selectedWorkareas={checkedWA} functions={{ addToWorkarea: handleAddToWorkarea }} structureId={structure.id} contractId={contractId} ui_list={ui_list} structureElements={structureElements} permission_department_list={permission_department_list} />
		</RoomProvider>
	)

}








///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalFilter = ({ t, ui_list, filterAppliedList, setFilterAppliedList, element_list }) => {

	const { service_dict, structureElements } = useContext(GlobalDataContext)
	const [showFilter, setShowFilter] = useState(false)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	function checkWorkareaPresence(d) {
		for (const w of element_list) {
			if (w.service_summary && w.service_summary.filter(s => s.id === d).length > 0) {
				return true
			}

		}
		return false
	}

	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.service.length > 0 ?
					<>
						{Object.keys(ui_list.service_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.service.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.service.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('service', d)}>
								{ui_list.service_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.service.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('service')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.structure.length > 0 ?
					<>
						{Object.keys(structureElements.all_structure_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.structure.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.structure.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('structure', d)}>
								{structureElements.all_structure_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.structure.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.pavillon.length > 0 ?
					<>
						{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.pavillon.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.pavillon.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('pavillon', d)}>
								{structureElements.all_pavillon_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.pavillon.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
								{t('global.labels.filter_services')}
							</Col>
						</Row>
						<Row>
							{ui_list.service_dict ?
								<>
									{Object.keys(ui_list.service_dict).map((d, k) => (
										<Col sm="4" key={k}>
											<Button key={k} block size="sm" className="ms-1 mb-1 text-start" disabled={!checkWorkareaPresence(d)} variant={filterAppliedList.service.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('service', d)}>
												<ServiceBadge service={ui_list.service_dict[d]} freq={true} />
											</Button>
										</Col>
									))}
								</>
								: ''
							}
						</Row>
						<Row>
							<Col>
								{t('global.labels.structure')}
							</Col>
						</Row>
						<Row>
							<Col>
								{console.log(structureElements.all_structure_dict)}
								{Object.keys(structureElements.all_structure_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.structure.indexOf(structureElements.all_structure_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('structure', structureElements.all_structure_dict[d].id)}>
										{structureElements.all_structure_dict[d].name}
									</Button>
								))}
								{filterAppliedList.structure.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{console.log(structureElements.all_pavillon_dict)}
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
										disabled={filterAppliedList.structure.indexOf(structureElements.all_pavillon_dict[d].parent_list[0]) === -1}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}




export default translate()(WorkareaView)