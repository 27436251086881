//import firebaseconfig from './firebaseIndex'
import firebase from 'firebase/compat/app'

export const authMethods = {

    // firebase helper methods go here... 
    signup: (email, password, setErrors, setToken) => {

        //-------keep for mainteance--------------deprecated




        /*         firebase.auth().createUserWithEmailAndPassword(email, password)
                    .then(async res => {
                        const token = await Object.entries(res.user)[5][1].b
        
        
                        await localStorage.setItem('userId', res.user.uid)
                        //set token to session 
                        await localStorage.setItem('token', token)
                        //grab token from local storage and set to state. 
                        setToken(window.localStorage.token)
                        
                        // DEBUG: console.log(res)
                    })
                    .catch(err => {
                        setErrors(prev => ([...prev, err.message]))
                        console.error(err)
                    }) */

    },


    signin: (email, password, setErrors, setToken, setTwofactorErrors) => {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(async res => {
                console.log("AUTH res.user", res.user)

                //const token = await Object.entries(res.user)[5][1].b
                const token = await firebase.auth().currentUser.getIdToken(true)
                console.log("-----new token ### token", token)
                //DEBUG: console.log("SIGNIN: res.user=", res.user)
                //DEBUG: console.log("token:", token)
                //DEBUG: console.log("rtoken:", res.user.refreshToken)
                //DEBUG: console.log("uid:", res.user.uid)


                await localStorage.setItem('userId', res.user.uid)
                //set token to session 
                await localStorage.setItem('token', token)
                await localStorage.setItem('AuthToken', token)
                //grab token from local storage and set to state. 
                setToken(window.localStorage.token)
                // DEBUG: console.log(res)
            })
            .catch(error => {
                console.error(error)
                if (error.code == 'auth/multi-factor-auth-required') {

                    const resolver = error.resolver;
                    setTwofactorErrors(resolver)
                    // Ask user which second factor to use.

                } else if (error.code == 'auth/wrong-password') {
                    setErrors(prev => ([...prev, error.message]))
                }else{
                    console.log("error.code", error.code)
                    setErrors(prev => ([...prev, error.message.replace("Firebase", "Auth")]))
                }


            })
    },

    signinTwofactor: async (res, setToken) => {
        const token = await firebase.auth().currentUser.getIdToken(true)
        console.log("-----new token ### token", token)
        //DEBUG: console.log("SIGNIN: res.user=", res.user)
        //DEBUG: console.log("token:", token)
        //DEBUG: console.log("rtoken:", res.user.refreshToken)
        //DEBUG: console.log("uid:", res.user.uid)


        await localStorage.setItem('userId', res.user.uid)
        //set token to session 
        await localStorage.setItem('token', token)
        //grab token from local storage and set to state. 
        setToken(window.localStorage.token)
    },

    signout: (setErrors, setToken) => {
        // signOut is a no argument function
        firebase.auth().signOut().then(res => {
            //remove the token
            localStorage.removeItem('token')
            //set the token back to original state
            setToken(null)

            localStorage.removeItem('userId')
            localStorage.removeItem('userData')
        })
            .catch(err => {
                //there shouldn't every be an error from firebase but just in case
                setErrors(prev => ([...prev, err.message]))
                //whether firebase does the trick or not i want my user to do there thing.
                localStorage.removeItem('token')
                setToken(null)
                localStorage.removeItem('userId')
                localStorage.removeItem('userData')
                console.error(err.message)
            })
    },

}