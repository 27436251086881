import { firestore } from '../firebaseIndex'

// LIGHT VERSION....
//
// from service list:
// -name
// -id
// -type
//
// patient_unit
// has_patient_unit
// has_microbiological_check



///////////////////////////////////////////////////////////////////////////////
export const addRoomType = async (domainId, contractId, data) => {
    try {

        // --- full version ---
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .add(data);

        const newdata = {
            ...data,
            id: res.id,
        }


        // --- light version ---
        let lightservicelist = [];
        if (data.service_list) {
            lightservicelist = data.service_list.map(service_elem => {
                const single_light_service = {
                    service: {
                        name: service_elem.service.name,
                        id: service_elem.service.id,
                        type: service_elem.service.type
                    }
                }
                return single_light_service
            })
        }

        const lightdata = {
            id: res.id,
            name: data.name,
            ...data.lqa && { lqa: data.lqa },
            ...data.patient_unit && { patient_unit: data.patient_unit },
            ...data.has_patient_unit && { has_patient_unit: data.has_patient_unit },
            ...data.has_microbiological_check && { has_microbiological_check: data.has_microbiological_check },
            service_list: lightservicelist,
        }

        const reslight = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypeslight')
            .doc(res.id)
            .set(lightdata);


        return newdata
    } catch (e) {
        console.log("addRoomType error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getRoomTypeById = async (domainId, contractId, roomTypeId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .doc(roomTypeId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id: res.id,
        }
        return data;
    } catch (e) {
        console.log("getRoomTypeById error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getRoomTypeList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .get();

        console.log("RES", res)
        const roomTypeList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return roomTypeList;

    } catch (e) {
        console.log("getRoomTypeList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getRoomTypeLightList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypeslight')
            .get();

        console.log("RES", res)
        const roomTypeList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return roomTypeList;

    } catch (e) {
        console.log("getRoomTypeLightList error: ", e)
        return { error: e }
    }
}


// ****************************************************************************
// Get roomType list with pagination
export const getRoomTypeList_page = async (domainId, contractId, query_cursor, limit = 25) => {
    try {
        let query = firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')

        // if there is a pivot for query starting point
        if (query_cursor && query_cursor !== undefined) {
            query = query.startAfter(query_cursor)
        }

        const res = await query
            .limit(limit)
            .get();

        // DEBUG: console.log("RES", res)

        let last = null;
        if (res.docs.length > 0) {    // there is one or more results
            last = res.docs[res.docs.length - 1];
        }

        const roomTypeList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return {
            roomTypeList: roomTypeList,
            query_cursor: last
        };

    } catch (e) {
        console.log("getRoomTypeList_page error: ", e)
        return { error: e }
    }
}






///////////////////////////////////////////////////////////////////////////////
export const editRoomType = async (domainId, contractId, roomTypeId, data) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .doc(roomTypeId)
            .set(data);



        // --- light version ---
        let lightservicelist = [];
        if (data.service_list) {
            lightservicelist = data.service_list.map(service_elem => {
                const single_light_service = {
                    service: {
                        name: service_elem.service.name,
                        id: service_elem.service.id,
                        type: service_elem.service.type
                    }
                }
                return single_light_service
            })
        }

        const lightdata = {
            id: roomTypeId,
            name: data.name,
            ...data.lqa && { lqa: data.lqa },
            ...data.patient_unit && { patient_unit: data.patient_unit },
            ...data.has_patient_unit && { has_patient_unit: data.has_patient_unit },
            ...data.has_microbiological_check && { has_microbiological_check: data.has_microbiological_check },
            service_list: lightservicelist,
        }
        console.log("lightData: ", lightdata);

        const reslight = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypeslight')
            .doc(roomTypeId)
            .set(lightdata);



        return res
    } catch (e) {
        console.log("editRoomType error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteRoomType = async (domainId, contractId, roomTypeId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .doc(roomTypeId).delete();

        const res2 = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypeslight')
            .doc(roomTypeId)
            .delete();

        return res
    } catch (e) {
        console.log("deleteRoomType error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////




// ****************************************************************************
// PROCESS EXISTING ROOMTYPE
export const processRoomTypeCollection = async (domainId, contractId) => {
    try {
        let query = firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
        const res = await query.get();


        let count = 0

        for (const doc of res.docs) {
            const data = doc.data()

            // --- light version ---
            let lightservicelist = [];
            if (data.service_list) {
                lightservicelist = data.service_list.map(service_elem => {
                    const single_light_service = {
                        service: {
                            name: service_elem.service.name,
                            id: service_elem.service.id,
                            type: service_elem.service.type
                        }
                    }
                    return single_light_service
                })
            }

            const lightdata = {
                id: data.id,
                name: data.name,
                ...data.lqa && { lqa: data.lqa },
                ...data.patient_unit && { patient_unit: data.patient_unit },
                ...data.has_patient_unit && { has_patient_unit: data.has_patient_unit },
                ...data.has_microbiological_check && { has_microbiological_check: data.has_microbiological_check },
                service_list: lightservicelist,
            }

            console.log("lightData: ", lightdata);

            const reslight = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('structures')
                .doc('global')
                .collection('roomtypeslight')
                .doc(data.id)
                .set(lightdata);

            count = count + 1
            console.log("counter: ", count)
        }

        console.log("PROCESS COMPLETED [", count, "]")
        return { status: "OK" };

    } catch (e) {
        console.log("processRoomTypeCollection error: ", e)
        return { error: e }
    }
}


// ----------------------------------------------------------------------------------------------------
// PROCESS EXISTING ROOMTYPE for IMAGES!!!
export const processRoomTypeCollection_for_images = async (domainId, contractId) => {
    try {


        // --- BUILD IMAGE DICTIONARY ---
        let image_dict = {}
        let queryImages = firestore.collection('GLOBAL_IMAGES');
        const resImages = await queryImages.get();

        for (const imgSnapshot of resImages.docs) {
            const imgData = imgSnapshot.data()
            image_dict[imgData.media_tracker.source_name] = {
                Id: imgSnapshot.id,
                media: imgData.media_tracker.url,
                description: imgData.description
            }
        }
        console.log(image_dict)


        let query = firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
        const res = await query.get();

        let count = 0
        for (const doc of res.docs) {
            const data = doc.data()
            console.log("roomtype_id:", doc.id, "   image_ref: ", data.image_reference)
            const new_image = image_dict[data.image_reference]

            if (new_image) {
                const newdata = {
                    ...data,
                    id: doc.id,
                    image_reference: new_image.media,
                    image_reference_data: new_image
                }

                console.log(">>>>>>>>>>>>>>>>")
                console.log("\timage_ref: ", data.image_reference, "\n\tnewdata: ", newdata)
                console.log("DOMAINS/",domainId, "/contracts/",contractId,"/structures/global/roomtypes/",data.id)
                try {
                    
                    const res2 = await firestore.collection('DOMAINS')
                        .doc(domainId).collection('contracts')
                        .doc(contractId)
                        .collection('structures')
                        .doc('global')
                        .collection('roomtypes')
                        .doc(doc.id)
                        .set(newdata);
                    console.log("\n\tRES2: ", res2)
                    count = count + 1
                    console.log("\n\t >>> counter: ", count)
                } catch (err2) {
                    console.log("processRoomTypeCollection_for_images - write data error: ", err2)
                    return { error: err2 }
                }
            }
        }

        console.log("PROCESS COMPLETED [", count, "]")
        return { status: "OK" };

    } catch (e) {
        console.log("processRoomTypeCollection_for_images error: ", e)
        return { error: e }
    }
}



// ----------------------------------------------------------------------------------------------------
// PROCESS EXISTING ROOMTYPE for IMAGES!!!
export const processRoomTypeCollection_for_images2 = async (domainId, contractId) => {
    try {


        // --- BUILD IMAGE DICTIONARY ---
        let image_dict = {}
        let queryImages = firestore.collection('GLOBAL_IMAGES');
        const resImages = await queryImages.get();

        for (const imgSnapshot of resImages.docs) {
            const imgData = imgSnapshot.data()
            image_dict[imgSnapshot.id] = {
                Id: imgSnapshot.id,
                media: imgData.media_tracker.url,
                description: imgData.description
            }
        }
        console.log(image_dict)


        let query = firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
        const res = await query.get();

        let count = 0
        for (const doc of res.docs) {
            const data = doc.data()
            console.log("roomtype_id:", doc.id, "   image_ref: ", data.image_id)

            if (data.image_id) {
                const new_image = image_dict[data.image_id]

                const newdata = {
                    ...data,
                    image_reference: new_image.media,
                    image_reference_data: new_image
                }

                console.log("newdata: ", newdata)

                const res2 = await firestore.collection('DOMAINS')
                    .doc(domainId).collection('contracts')
                    .doc(contractId)
                    .collection('structures')
                    .doc('global')
                    .collection('roomtypes')
                    .doc(data.id)
                    .set(newdata);

                count = count + 1
                console.log("counter: ", count)
            }
        }

        console.log("PROCESS COMPLETED [", count, "]")
        return { status: "OK" };

    } catch (e) {
        console.log("processRoomTypeCollection error: ", e)
        return { error: e }
    }
}