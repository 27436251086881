import React, { useState, useEffect, useRef, useContext, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Badge, Container, InputGroup, ButtonGroup, Row, Col, Form, Button, OverlayTrigger, Popover, ListGroup, Spinner, Accordion, Card, Tooltip } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format, addDays, differenceInDays, differenceInMinutes, getHours, getMinutes, setHours, setMinutes, addYears, addHours, set, toDate, } from 'date-fns'
import { it } from 'date-fns/locale'
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { DndProvider, } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
/* import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';
import 'moment/locale/de'; */
import { RRule } from 'rrule'

import { frequency_dict } from "../components/constants/global_definitions"

import SearchContext, { SearchProvider } from "../components/contexts/searchContext"
import DomainContext from '../components/contexts/domainContext'
import RoomContext, { RoomProvider } from '../components/contexts/roomList.js';
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import { SearchSection } from "../components/SearchSection"
import Footer from "../components/Footer"
import ModalInterventionCart from "../components/ModalInterventionCart"
import ModalTimeAnalisys from "../components/ModalTimeAnalisys"
import { RoomEditRowSource, EditRoomListContainer, OneRoom } from "../components/RoomSelector"

import FrequencySelector from '../components/FrequencySelector'
//import EditContext, { EditProvider } from "../components/contexts/edit.js"
import { getSelectedElement, checkComponentPresence, myTimestampToDate, myDateToTimestamp, getFloatNumber, rgba2hex, getTimeValue, compareName, getValue, getFormattedDate, setMyDate, toDateTime, getFixedNumber, onlyUnique, propComparator, makeid, dateInRange, getStructureFromWorkareas } from "../util/ui_utils.js"
import { ItemProgress, WeekDays, ServiceBadge, ModalInitButton, renderActivityPeriodListPopover, OrderButton } from '../components/StructureGraphicElements.js'


import firebase from 'firebase/compat/app'


import { addIntervention, editIntervention, getInterventionById, deleteIntervention } from '../Firebase2/Storage2/dbIntervention'
import { editWorkarea, getWorkareaList } from '../Firebase2/Storage2/dbWorkarea';
import { addEventBatch, deleteEventBatch, deleteFutureEvents } from '../Firebase2/Storage2/dbAgenda';
import { getProductList } from '../Firebase2/Storage2/dbWarehouse'
import { getDpiList } from '../Firebase2/Storage2/dbDpi'
import {
	getEconomicalMaterialList
} from '../Firebase2/Storage2/dbRoomMaterial';
import { getEmployerList as getEmployerList2 } from '../Firebase2/Storage2/dbEmployers';
import { getWorkContractList as getWorkContractList2 } from "../Firebase2/Storage2/dbWorkContract"
import InterventionContext, { InterventionProvider } from '../components/contexts/interventionContext';





//const contractId = sessionStorage.getItem('contractId')
const companyId = "0"



const MAX_EVENT_DB_INSERT = 100


let intervention_dict = {}
let update_hour = false

let load_page = false

const defaultfilter = { service: [], structure: [], pavillon: [], room_department: [], room_pavillon: [], room_floor: [] }

const new_intervention = {
	name: '',
	room_list: [],
	group_name: '',
	group_id: -1,
	start: firebase.firestore.Timestamp.fromDate(set(new Date(), { hours: 8, minutes: 0, seconds: 0 })),
	end: firebase.firestore.Timestamp.fromDate(set(new Date(), { hours: 10, minutes: 0, seconds: 0 })),
	weekdays: [],
	service: {},
	frequency_mode: 'ordinary',
	operating_units: 1,
	max_check_delay: 0,
	operation_list: [],
	intervention_length: 1,
	size: 0,
	use_activity_period: false,
	cart_element_list: [],
	periodic_data: {
		date_list: [],
		start: '6:00',
		end: '8:00'
	}
}





const convertWeekdays = (weekdays) => {
	let array = toNumbers(weekdays)
	return array.map(d => (d + 6) % 7)
}

/// foter helper function
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(int_dict, filterList, t, int_count) {
	const ik = Object.keys(int_dict)
	const filt = ik.reduce(function (filtered, d) {
		if (int_dict[d].service && int_dict[d].service.id && filterList.indexOf(int_dict[d].service.id) !== -1) {
			filtered.push(int_dict[d])
		}
		return filtered
	}, [])
	if (filterList.length === 0 || filt.length === ik.length) {
		return (<>{int_count} {t('global.labels.interventions')}</>)
	} else {
		return (<>{filt.length}/{ik.length} {t('global.labels.interventions')}</>)
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkWorkareaInterventionServiceFilter(w, filterAppliedList, _intervention_dict, intervention_type) {
	let ispresent = false

	//	console.log("interventiontype", intervention_type)
	if (intervention_type === 'ordinary') {
		if (w.intervention_list && w.intervention_list.filter(f => typeof f === 'string').length > 0 && _intervention_dict) {
			w.intervention_list.filter(f => typeof f === 'string').map(f => {
				if (_intervention_dict[f].service && _intervention_dict[f].service.id && filterAppliedList.service.indexOf(_intervention_dict[f].service.id) !== -1) {
					ispresent = true
				}
			})
		}

	} else {
		if (w.periodicintervention_list && w.periodicintervention_list.filter(f => typeof f === 'string').length > 0 && _intervention_dict) {
			w.periodicintervention_list.filter(f => typeof f === 'string').map(f => {
				console.log(_intervention_dict, f)
				if (_intervention_dict[f].service && _intervention_dict[f].service.id && filterAppliedList.service.indexOf(_intervention_dict[f].service.id) !== -1) {
					ispresent = true
				}
			})
		}

	}
	return ispresent
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkInterventionServiceFilter(i, filterservice, intervention_id) {
	let ispresent = false
	if (i.service && i.service.id && filterservice.indexOf(i.service.id) !== -1) {
		console.log(filterservice, i.service.id, intervention_id, i)
		ispresent = true
	}
	return ispresent
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkStructureFilter(i, filterstructure) {
	let ispresent = false
	console.log("-----------  -------------       ---", i, filterstructure)
	if (i && i.structure_list && i.structure_list.some(r => (filterstructure.indexOf(r) >= 0))) {
		ispresent = true
	}
	return ispresent
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkPavillonFilter(i, filterpavillon) {
	let ispresent = false
	console.log("-----------  -------------       ---", i, filterpavillon)
	if (i.pavillon_list && i.pavillon_list.some(r => (filterpavillon.indexOf(r) >= 0))) {
		ispresent = true
	}
	return ispresent
}






/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function filterPermissionData(w, structure_list, department_list) {
	//console.log("filter permission", structure_list, department_list)
	let ispermitted = false
	if (structure_list.length === 0) {
		ispermitted = true
	} else {
		if (department_list.length > 0) {
			if (w.department_list.filter(e => department_list.indexOf(e) !== -1).length > 0) {
				ispermitted = true
			}

		} else {
			if (w.structure_list.filter(e => structure_list.indexOf(e) !== -1).length > 0) {
				ispermitted = true
			}

		}
		/* if (department_list.length > 0) {
			console.log(w.department_list, department_list,w.department_list.some(d => department_list.filter(p => p === d) !== -1) )
			ispermitted = w.department_list.some(d => department_list.filter(p => p === d) !== -1)

		} else {
			ispermitted = w.structure_list.some(d => structure_list.filter(p => p === d !== -1))
			//ispermitted = w.structure_list.some(d => structure_list.filter(p => p.id === d.id)!== -1 )
		} */
	}
	return ispermitted

}


const toNumbers = arr => arr.map(Number)









/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// 
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const InterventionContainerView = ({ t, uData, intervention_type }) => {
	return (
		<InterventionProvider>
			<SearchProvider>
				<Interventions t={t} uData={uData} intervention_type={intervention_type} />
			</SearchProvider>
		</InterventionProvider>

	)
}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Interventions = ({ t, uData, intervention_type }) => {
	const { domain, domain_data } = useContext(DomainContext)

	const { contract, structureElements, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict, all_workarea_dict } = useContext(GlobalDataContext)
	const childRefAlert = useRef()
	const childRefDelete = useRef()
	let { cid } = useParams();
	let contractId = cid

	const { initContract } = InitContractHelper()
	//	const [structure_list, setStructureList] = useState([])
	let _structure_list = structureElements.structure_list


	//	const [ui_list, setUiList] = useState({})
	const [workarea_list, setWorkareaList] = useState([])
	const [loading, setLoading] = useState(true)
	const [canWrite, setWritePage] = useState(false)
	const [yieldRead, setYieldRead] = useState(false)
	const [intervention_count, setInterventionCount] = useState(0)
	const [globalEdit, setGlobalEdit] = useState(false)
	const [filterAppliedList, setFilterAppliedList] = useState(defaultfilter)
	const [searchTerm, setSearchTerm] = useState('')
	const {
		//                intervention_dict, updateInterventionDict,
		//				update_hour, setUpdateHour,
		intervention_dict_ref,
		ui_list, setUiList
	} = useContext(InterventionContext)


	const onCloseGlobalEdit = () => {
		setGlobalEdit(false)
	}


	const handleEnableGlobalEdit = () => {
		setGlobalEdit(true)

	}






	useEffect(() => {
		load_page = false
		async function loadData() {
			let contractId = cid
			console.log("INTERVENTION reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("INTERVENTION init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()
		}
		return () => {
		}
	}, [])




	useEffect(() => {
		const initValues = async () => {
			console.log("ORDINARY uData", uData)
			if (intervention_type === 'ordinary') {
				if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('ordinaryintervention') !== -1)) {
					console.log("======can write")
					setWritePage(true)
				}

			} else {
				if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('periodicintervention') !== -1)) {
					console.log("======can write")
					setWritePage(true)
				}

			}
			if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('yield_summary') !== -1)) {
				setYieldRead(true)
			}

			const userData = uData
			setLoading(true)
			console.log(contract, domain)
			if (contract && domain.length > 0) {
				contractId = contract.id

				_structure_list = structureElements.structure_list
				console.log("structure list", _structure_list)
				let permission_department_list = []
				let permission_structure_list = []
				let permission_workarea_list = []
				if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
					console.log('userData contract', userData.contract_dict[contractId])
					if (userData.contract_dict[contractId][0].mode === 'workarea') {
						permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
						permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
						console.log("permission_structure_list", permission_structure_list)
						_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
					} else {
						if (userData.contract_dict[contractId][0].structure) {
							if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
								permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
								console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
							}
							if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
								permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
								_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

							}
							console.log("permission structure_list", permission_structure_list)
						}
					}
				}

				//cart element
				const neweconomic_material_list = await getEconomicalMaterialList(domain, contractId)
				let newelementp_list = await getProductList(domain, contractId, companyId, 'product', null)
				newelementp_list = newelementp_list.map(p => ({ ...p, category_type: 'product' }))

				let newelemente_list = await getProductList(domain, contractId, companyId, 'equipment', null)
				newelemente_list = newelemente_list.map(p => ({ ...p, category_type: 'equipment' }))

				let newelementc_list = await getProductList(domain, contractId, companyId, 'cart', null)
				newelementc_list = newelementc_list.map(p => ({ ...p, category_type: 'cart' }))

				let newelementm_list = await getProductList(domain, contractId, companyId, 'machine', null)
				newelementm_list = newelementm_list.map(p => ({ ...p, category_type: 'machine' }))

				let newelementcm_list = await getProductList(domain, contractId, companyId, 'cleaningmaterial', null)
				newelementcm_list = newelementcm_list.map(p => ({ ...p, category_type: 'cleaningmaterial' }))
				console.log("CLEANING MATERIAL", newelementcm_list)

				let newelementd_list = await getDpiList(domain, contractId)
				newelementd_list = newelementd_list.map(d => ({ ...d, category_type: 'dpi' }))

				let newgood_list = [
					...newelementp_list,
					...newelemente_list,
					...newelementc_list,
					...newelementm_list,
					...newelementcm_list,
					...newelementd_list,
				]
				newgood_list = newgood_list.sort(compareName)

				let contract_list = await getWorkContractList2(domain, contractId)
				let _employers_list = await getEmployerList2(domain, contractId, {})
				console.log("employeslist", _employers_list)
				_employers_list = _employers_list.sort(propComparator('surname'))

				setUiList({
					...ui_list, pavillon_dict: structureElements.all_pavillon_dict, department_dict: structureElements.all_department_dict, floor_dict: structureElements.all_floor_dict,
					risk_area_list, risk_area_dict, operation_dict, roomtype_dict, structure_list: structureElements.structure_list, service_dict, good_list: newgood_list, economic_material_list: neweconomic_material_list,
					contract_list: contract_list, employer_list: _employers_list
				})
				let _workarea_list = await getWorkareaList(domain, contractId)
				let tintervention_count = 0
				console.log("workarea_list", _workarea_list)
				if (permission_workarea_list.length > 0) {
					_workarea_list = _workarea_list.filter(w => permission_workarea_list.indexOf(w.id)).filter(w => (w.service_summary && w.service_summary.filter(s => s.type === intervention_type).length > 0))
				} else {
					_workarea_list = _workarea_list.filter(w => filterPermissionData(w, permission_structure_list, permission_department_list)).filter(w => (w.service_summary && w.service_summary.filter(s => s.type === intervention_type).length > 0))
				}
				console.log("\tFILTERED", _workarea_list)
				if (intervention_type === 'ordinary') {
					for (const w of _workarea_list) {
						if (w.intervention_list) {
							tintervention_count += w.intervention_list.length
						}
					}
				} else {
					for (const w of _workarea_list) {
						//	console.log(w.service_summary.filter(s => s.type === intervention_type))
						if (w.periodicintervention_list) {
							tintervention_count += w.periodicintervention_list.length
						}
					}
				}
				console.log("INTERVENTION NUMBER", tintervention_count)
				setInterventionCount(tintervention_count)
				_workarea_list.sort(compareName)
				console.log("workarea", _workarea_list)
				setWorkareaList(_workarea_list)
				setLoading(false)


			}
		}

		console.log('INTERVENTION loadingcontract', contract)
		if (contract && !load_page) {
			load_page = true
			initValues()
		}
	}, [contract])









	const updateWorkarea = async (workarea, intervention) => {


		/* 		if (intervention){
					let editlist = [...workarea.intervention_list]
					let tmp_editworkarea = { ...workarea }
					tmp_editworkarea.intervention_list = editlist
		
				} 
				 */
		let weditlist = [...workarea_list]
		const c = checkComponentPresence(workarea, weditlist, 'id')
		//console.log("update workarea intervention list", workarea.intervention_list, c)
		console.log("update workarea", workarea, c)
		weditlist.splice(c, 1, workarea)
		setWorkareaList(weditlist)
	}





	const renderRoomListPopover = (room_list, intervention_list) => {
		let used_room_list_id = []
		/* 		try {
					for (const int_id of intervention_list) {
						for (const room of intervention_dict[int_id].room_list) {
							used_room_list_id.push(room.id)
						}
		
					}
				} catch (err) {
					console.log("error, ", err, intervention_dict,)
				} */

		const getRoomtypeData = (rt) => {
			try {
				return <> - {roomtype_dict[rt].name}</>
			} catch (err) {
				return ''
			}
		}

		return (
			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Header as="h3">Locali associati all'area<br />
					{/*	<span className="text-muted section_title">
					(<FontAwesomeIcon icon="exclamation-triangle" />= locali non inseriti in interventi)</span> */}
				</Popover.Header>
				<Popover.Body>
					{room_list ?
						<ListGroup variant="flush" className="d-inline-block">
							{room_list.map((r, k) => (
								<ListGroup.Item key={k} className={"p-2 border-start w-50 " + (k % 2 === 0 ? 'float-start' : 'float-end')}>
									{r.name} {getRoomtypeData(r.roomtype.id)}
									{/*  {used_room_list_id.indexOf(r.id) < 0 ? <FontAwesomeIcon icon="exclamation-triangle" /> : ''} */}
								</ListGroup.Item>
							))}
						</ListGroup>
						: ''
					}
				</Popover.Body>
			</Popover>
		)
	}




	const cleanPlanning = () => {
		const cb = () => {
			console.log("DELETE", intervention_dict_ref.current)
			for (const k of Object.keys(intervention_dict_ref.current)) {
				const return_dataDelete = deleteEventBatch(domain, contractId, k)
			}
		}
		childRefDelete.current.confirmDelete(`Pulizia planning (interventi ${intervention_type === 'ordinary' ? 'ordinari' : 'periodici'})`, `Sei sicuro di voler cancellare tutti i interventi ${Object.keys(intervention_dict_ref.current).length} ${intervention_type === 'ordinary' ? 'ordinari' : 'periodici'} inseriti nel planning? Verranno eliminati anche i dati di report associati (se presenti). L'azione non è annullabile`, cb)

	}






	return (
		<>

			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			<Container fluid className="titleStickyTop">

				<Row >
					<Col sm="6">
						{intervention_type === 'ordinary' ?
							<PageTitle title={t('global.pages.ordinaryintervention')} />
							:
							<PageTitle title={t('global.pages.periodicintervention')} />
						}
					</Col>
					{!loading ?
						<>
							<Col className="text-center">
								<ModalFilter t={t} ui_list={ui_list} filterAppliedList={filterAppliedList} setFilterAppliedList={setFilterAppliedList} defaultfilter={defaultfilter} intervention_type={intervention_type} />
							</Col>
							<Col className="text-center">
								<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
							</Col>
						</> : ''}
					{canWrite && !loading ?
						<Col className="text-end">
							{globalEdit ?
								<>
									<Button variant="secondary" onClick={() => onCloseGlobalEdit()}><FontAwesomeIcon icon='check' />{t('globals.buttons.close')}</Button>
								</>
								:
								<Button onClick={() => handleEnableGlobalEdit()}><FontAwesomeIcon icon='pencil-alt' />  {t('interventions.buttons.enable_edit')}</Button>
							}
						</Col>
						: ''}
				</Row>
				{workarea_list && workarea_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1" >{t('workareas.labels.name')}</Col>
						<Col className="bg-secondary ms-1" >{t('global.labels.service')}</Col>
						<Col className="bg-secondary ms-1 text-center" sm="1">{t('workarea.labels.operating_units')}</Col>
						<Col className="bg-secondary ms-1 text-center p-0" sm="2" >{t('workareas.labels.frequency')}</Col>
						<Col className="bg-secondary ms-1 text-center p-0" sm="1" >m<sup>2</sup></Col>
						<Col className="bg-secondary ms-1 text-center" sm="2">
							{contract && contract.type === 'civil' ?
								t('global.labels.standard_cleaning')
								:
								t('global.labels.risk_area')
							}
						</Col>
						<Col className="bg-secondary ms-1 text-center" sm="2">{t('global.labels.action')}</Col>
					</Row>

					: ''}
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status" />&nbsp;Caricamento in corso
						</Col>
					</Row>
					:
					<>
						<Row>
							<Col>
								{workarea_list.length > 0 ?
									<>
										{workarea_list
											.filter(wi => globalEdit
												|| (intervention_type === 'ordinary' && wi.intervention_list && wi.intervention_list.length > 0)
												|| (intervention_type === 'periodic' && wi.periodicintervention_list && wi.periodicintervention_list.length > 0))
											.filter(i => (filterAppliedList.service.length === 0 ||
												checkWorkareaInterventionServiceFilter(i, filterAppliedList, intervention_dict_ref.current, intervention_type))
												&& (filterAppliedList.structure.length === 0 ||
													checkStructureFilter(i, filterAppliedList.structure))
												&& (filterAppliedList.pavillon.length === 0 ||
													checkPavillonFilter(i, filterAppliedList.pavillon))
											)
											.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
											.map((w, key) => (

												<Row key={w.id + intervention_type} className="p-1 border rounded mb-1 one_workarea border-secondary">
													<Col>
														<Row className="mb-1">
															<Col>
																<b>{w.name}</b> &nbsp;
																<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderRoomListPopover(w.room_list, w.intervention_list.filter(f => typeof f === 'string'))}>
																	<Badge bg="secondary" className="btn me-1">{w.room_list.length} {t('global.labels.rooms')}</Badge>
																</OverlayTrigger>
																{intervention_type === 'ordinary' ?
																	<>
																		{w.intervention_list && w.intervention_list.filter(f => typeof f === 'string').length > 0 ?
																			<Badge bg="secondary" disabled>{w.intervention_list.filter(f => typeof f === 'string').length} {t('global.labels.interventions')}</Badge>
																			: ''
																		}
																	</>
																	:
																	<>
																		{w.periodicintervention_list && w.periodicintervention_list.filter(f => typeof f === 'string').length > 0 ?
																			<Badge bg="secondary" disabled>{w.periodicintervention_list.filter(f => typeof f === 'string').length} {t('global.labels.interventions')}</Badge>
																			: ''
																		}
																	</>
																}
															</Col>
															<Col sm="1" className="text-center">{getFixedNumber(w.size)}</Col>
															<Col sm="2">
																{w.risk_area_summary ? <ItemProgress ui_list={ui_list} risk_list={w.risk_area_summary} item={w} mode="mq" /> : ''}
															</Col>
															<Col sm="2 p-0 text-center">
																{w.room_list.filter(r => structureElements.all_room_dict && !structureElements.all_room_dict[r.id]).length > 0 &&
																	<OverlayTrigger
																		placement="auto"
																		delay={{ show: 250, hide: 100 }}
																		overlay={
																			<Tooltip  >
																				locali non più presenti
																			</Tooltip>}
																	>
																		<FontAwesomeIcon icon="exclamation-triangle" />
																	</OverlayTrigger>

																}
																{globalEdit && canWrite ?
																	<RoomProvider>
																		{intervention_type === 'ordinary' ?
																			<ModalOrdinaryIntervention is_new={true} workarea={w} canWrite={canWrite} ui_list={ui_list} t={t} updateWorkarea={updateWorkarea} />
																			:
																			<ModalPeriodicIntervention is_new={true} workarea={w} canWrite={canWrite} ui_list={ui_list} t={t} updateWorkarea={updateWorkarea} />
																		}
																	</RoomProvider>
																	: ''}
															</Col>
														</Row>

														{intervention_type === 'ordinary' ?
															<>
																{w.intervention_list && w.intervention_list.filter(f => typeof f === 'string').length > 0
																	?
																	<>
																		<Row className="p-1 section_title">
																			<Col className="ms-1 bg-light text-secondary" >{t('workareas.labels.intervention_list')}</Col>
																		</Row>
																		{w.intervention_list.filter(f => typeof f === 'string')
																			.filter(i => ((filterAppliedList.service.length === 0 ||
																				checkInterventionServiceFilter(intervention_dict_ref.current[i], filterAppliedList.service, i))
																				&& (filterAppliedList.structure.length === 0 ||
																					checkStructureFilter(intervention_dict_ref.current[i], filterAppliedList.structure))
																				&& (filterAppliedList.pavillon.length === 0 ||
																					checkPavillonFilter(intervention_dict_ref.current[i], filterAppliedList.pavillon))
																			))
																			.map((intervention_id, ki) => (
																				<Row key={intervention_id} className="p-1 bg-light mt-3">
																					<OneIntervention intervention_id={intervention_id} ki={ki} workarea={w} globalEdit={globalEdit} canWrite={canWrite} ui_list={ui_list} t={t} updateWorkarea={updateWorkarea} childRefDelete={childRefDelete} childRefAlert={childRefAlert} ModalIntervention={ModalOrdinaryIntervention} yieldRead={yieldRead} />
																				</Row>
																			))}
																	</>
																	:
																	<i>
																		{t('workeareas.messages.no_intervention')}
																	</i>
																}
															</>
															:
															<>
																{w.periodicintervention_list && w.periodicintervention_list.filter(f => typeof f === 'string').length > 0
																	?
																	<>
																		<Row className="p-1 section_title">
																			<Col className="ms-1 bg-light text-secondary" >{t('workareas.labels.intervention_list')}</Col>
																		</Row>
																		{w.periodicintervention_list.filter(f => typeof f === 'string')
																			.filter(i => ((filterAppliedList.service.length === 0 ||
																				checkInterventionServiceFilter(intervention_dict_ref.current[i], filterAppliedList.service, i))
																				&& (filterAppliedList.structure.length === 0 ||
																					checkStructureFilter(intervention_dict_ref.current[i], filterAppliedList.structure))
																				&& (filterAppliedList.pavillon.length === 0 ||
																					checkPavillonFilter(intervention_dict_ref.current[i], filterAppliedList.pavillon))
																			))
																			.map((intervention_id, ki) => (
																				<Row key={ki} className="p-1 bg-light mt-3">
																					<OneIntervention intervention_id={intervention_id} ki={intervention_id} workarea={w} globalEdit={globalEdit} canWrite={canWrite} ui_list={ui_list} t={t} updateWorkarea={updateWorkarea} childRefDelete={childRefDelete} childRefAlert={childRefAlert} ModalIntervention={ModalPeriodicIntervention} />
																				</Row>
																			))}
																	</>
																	:
																	<i>
																		{t('workeareas.messages.no_intervention')}
																	</i>
																}
															</>
														}
													</Col>
												</Row>
											))}
									</>
									: <Row><Col className="font-italic">
										{t('interventions.messages.no_workearea')}
									</Col></Row>}
							</Col>
						</Row>

					</>
				}
				{globalEdit ?
					<>
						<Button variant="danger" onClick={cleanPlanning}><FontAwesomeIcon icon="trash" /> Elimina programmazione</Button>
					</>
					:
					<></>
				}

			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(intervention_dict_ref.current, filterAppliedList.service, t, intervention_count)} />
		</>
	);
}






///////////////////////////////////////////////////////////////////////////////////////////////
const ModalOrdinaryIntervention = ({ is_new, element, k, functions, workarea, t, canWrite, updateWorkarea }) => {

	const { domain } = useContext(DomainContext)
	const { contractId, contract } = useContext(GlobalDataContext)
	//	const [roomGlobal, setRoomGlobal] = useContext(RoomContext)
	//const { intervention_dict } = useContext(InterventionContext)

	const [currentEditIntervention, setCurrentEditIntervention] = useState(new_intervention)
	const [showEditIntervention, setShowEditIntervention] = useState(false);
	const [editedElement, setEditedElement] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [process_past, setProcessPast] = useState(false)
	const { //intervention_dict, updateInterventionDict, 
		intervention_dict_ref,
		ui_list } = useContext(InterventionContext)
	//-----intervention form

	const handleCloseIntervention = () => setShowEditIntervention(false);
	const handleShowNewIntervention = (w) => {
		update_hour = false
		//		setCurWorkarea(workarea)
		//	setNewIntervention(true)
		setShowEditIntervention(true)
		//		setRoomGlobal(new_intervention.room_list)
		const tmp_new_intervention = { ...new_intervention, type: 'ordinary' }
		setCurrentEditIntervention(tmp_new_intervention)
		setEditedElement(false)
	}


	const handleEditOneIntervention = () => {
		update_hour = false
		//		setCurWorkarea(w)
		//		setNewIntervention(false)
		setShowEditIntervention(true)
		setCurrentEditIntervention(element)
		setEditedElement(false)
		//setRoomGlobal(element.room_list)
		setTimeout(function () {
			setEditedElement(false)
		}, 99)
	}





	const handleSaveIntervention = async () => {
		//		currentEditIntervention.room_list = roomGlobal
		setIsSaving(true)
		let editlist = []
		if (workarea.intervention_list) {
			editlist = [...workarea.intervention_list]
		}
		let tmp_editworkarea = { ...workarea }
		const date_list = handleExplodeWeekdays(currentEditIntervention.weekdays, currentEditIntervention.startDate)
		const start_h = getHours(myTimestampToDate(currentEditIntervention.start))
		const start_m = getMinutes(myTimestampToDate(currentEditIntervention.start))
		const end_h = getHours(myTimestampToDate(currentEditIntervention.end))
		const end_m = getMinutes(myTimestampToDate(currentEditIntervention.end))
		let event_list = []
		if (is_new) {
			currentEditIntervention.type = 'ordinary'
			delete currentEditIntervention.id
			console.log("EDITWORKAREA", domain, contractId, currentEditIntervention)
			const return_data = await addIntervention(domain, contractId, currentEditIntervention)
			if (return_data.error) {
				console.error("errore nell'aggiunta dell'intervento")

			} else {
				currentEditIntervention.id = return_data.id
				intervention_dict_ref.current[return_data.id] = currentEditIntervention
				//updateInterventionDict(return_data.id, currentEditIntervention)
				console.log('new intervention')
				editlist.push(return_data.id)
				editlist = editlist.sort(compareHour)
				tmp_editworkarea.intervention_list = editlist

				const return_datawork = await editWorkarea(domain, contractId, workarea.id, tmp_editworkarea)
				console.log("EDITWORKAREA", domain, contractId, workarea.id, tmp_editworkarea)
				console.log(return_datawork)
				updateWorkarea(tmp_editworkarea)

				for (let d = 0; d < date_list.length; d++) {
					const nd = new Date(date_list[d])
					if (!currentEditIntervention.use_activity_period || dateInRange(currentEditIntervention.period_list, nd)) {
						nd.setSeconds(0)
						nd.setMilliseconds(0)
						const newEvent = {
							start: setMinutes(setHours(nd, start_h), start_m),
							end: setMinutes(setHours(nd, end_h), end_m),
							extendedProps: {
								type: 'ordinary_intervention',
								workarea_id: workarea.id,
								workarea_name: workarea.name,
								intervention_id: return_data.id,
								structure_list: currentEditIntervention.structure_list,
								pavillon_list: currentEditIntervention.pavillon_list,
								floor_list: currentEditIntervention.floor_list,
								department_list: currentEditIntervention.department_list
							}
						};
						event_list.push(newEvent)
					}
					if (event_list.length > MAX_EVENT_DB_INSERT) {
						await addEventBatch(domain, contractId, event_list)
						event_list = []
					}
				}
				if (event_list.length > 0) {
					const return_dataEvent = addEventBatch(domain, contractId, event_list)
					console.log(return_dataEvent)
				}
			}
		} else {
			console.log('edit intervention', currentEditIntervention.index, editlist)
			const return_data = await editIntervention(domain, contractId, currentEditIntervention.id, currentEditIntervention)
			intervention_dict_ref.current[currentEditIntervention.id] = currentEditIntervention
			//updateInterventionDict(return_data.id,  currentEditIntervention)
			functions.edit(currentEditIntervention)
			const k = editlist.indexOf(currentEditIntervention.id)
			console.log("Pre edit list", editlist)
			editlist = editlist.sort(compareHour)
			console.log("EDITLIST", editlist)
			tmp_editworkarea.intervention_list = editlist
			if (update_hour) {
				const return_datawork = await editWorkarea(domain, contractId, workarea.id, tmp_editworkarea)
			}
			updateWorkarea(tmp_editworkarea)

			//const return_dataDelete = await deleteEventBatch(domain, contractId, currentEditIntervention.id)
			if (currentEditIntervention.startDate) {
				const return_dataDelete = await deleteFutureEvents({ DomainId: domain, ContractId: contractId, interventionId: currentEditIntervention.id, start: currentEditIntervention.startDate })
				console.log("DELETING", return_dataDelete)
			} else {
				const return_dataDelete = await deleteFutureEvents({ DomainId: domain, ContractId: contractId, interventionId: currentEditIntervention.id, start: firebase.firestore.Timestamp.now() })
				console.log("DELETING", return_dataDelete)
			}

			setTimeout(async function () {
				for (let d = 0; d < date_list.length; d++) {
					const nd = new Date(date_list[d])
					if (!currentEditIntervention.use_activity_period || dateInRange(currentEditIntervention.period_list, nd)) {
						console.log("ADDD DATE")
						nd.setSeconds(0)
						nd.setMilliseconds(0)
						const newEvent = {
							start: setMinutes(setHours(nd, start_h), start_m),
							end: setMinutes(setHours(nd, end_h), end_m),
							extendedProps: {
								type: 'ordinary_intervention',
								workarea_id: workarea.id,
								workarea_name: workarea.name,
								intervention_id: currentEditIntervention.id,
								structure_list: currentEditIntervention.structure_list,
								pavillon_list: currentEditIntervention.pavillon_list,
								floor_list: currentEditIntervention.floor_list,
								department_list: currentEditIntervention.department_list
							}
						};
						event_list.push(newEvent)
						if (event_list.length > MAX_EVENT_DB_INSERT) {
							const return_dataE = await addEventBatch(domain, contractId, event_list)
							console.log(event_list, domain, contractId, return_dataE)
							event_list = []
						}
					}
				}
				if (event_list.length > 0) {
					const return_dataEvent = await addEventBatch(domain, contractId, event_list)
					console.log(event_list, domain, contractId, return_dataEvent)
				}
			}, 3000)
			//setWorkareaList(weditlist)
		}
		setIsSaving(false)
		setShowEditIntervention(false);
	}


	const onChangeHandlerIntervention = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')
		} catch {
			sub = undefined;
		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = currentEditIntervention[name] ? [...currentEditIntervention[name]] : []
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...currentEditIntervention[sub], [name]: value }
			name = sub;
		}
		if (name === 'start' || name === 'end') {
			update_hour = true
		}
		console.log("intervention new value", name, value)
		setCurrentEditIntervention({ ...currentEditIntervention, [name]: value })
		setEditedElement(true)
	}



	const handleExplodeWeekdays = (weekdays, startDate) => {
		console.log(toDateTime(contract.startDate, contract.endDate))
		let st = toDateTime(firebase.firestore.Timestamp.now())
		if (startDate) {
			st = toDateTime(startDate)
		}
		let rule = {
			//			dtstart: toDateTime(contract.startDate),
			dtstart: st,
			until: toDateTime(contract.endDate),
			byweekday: convertWeekdays(weekdays),
			freq: RRule.WEEKLY,
			interval: 1
		}
		let rep = new RRule(rule).all()
		console.log("rule date range", rule, convertWeekdays(weekdays), weekdays, rep)
		return rep
	}


	const checkForm = () => {
		if (currentEditIntervention.use_activity_period && (currentEditIntervention.period_list === undefined || currentEditIntervention.period_list?.length === 0)) {
			return true
		}
		return false
	}


	/* 	useEffect(() => (
			onUpdateInterventionRooms(currentEditIntervention.service)
		), [currentEditIntervention.service]) */

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} title_new={t('workareas.buttons.add_intervention')} action_new={handleShowNewIntervention} action_edit={handleEditOneIntervention} action_info={handleEditOneIntervention} />

			{showEditIntervention ?
				<Modal show={showEditIntervention} onHide={handleCloseIntervention} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>
							{is_new ?
								<>
									<FontAwesomeIcon icon="plus-square" /> {workarea.name} | <span className="text-primary">{t('workareas.labels.new_intervention')}</span>
								</>
								:
								[canWrite ?
									<>
										<FontAwesomeIcon icon="pencil-alt" /> {workarea.name} | <span className="text-info">{t('workareas.labels.edit_intervention')} </span>
									</>
									:
									<>
										<FontAwesomeIcon icon="info" /> {workarea.name} | <span className="text-info">{t('workareas.labels.info_intervention')}</span>
									</>
								]
							}
							: {currentEditIntervention.name}&nbsp;
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}

						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							{!is_new && currentEditIntervention && !currentEditIntervention.structure_list ?
								<Row>
									<Col>
										<FontAwesomeIcon icon="exclamation-triangle" /> è necessario re-inserire i locali
									</Col>
								</Row>
								: ''}

							<InterventionFormDetails intervention_type={"ordinary"} element={currentEditIntervention} workarea={workarea} functions={{ edit: onChangeHandlerIntervention, setEdit: setEditedElement, setCurrentEditIntervention: setCurrentEditIntervention }} t={t} ui_list={ui_list} canWrite={canWrite} isNew={is_new} />
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" disabled={isSaving} onClick={handleCloseIntervention}>
							{t('modal.close')}
						</Button>
						{canWrite ?
							<Button disabled={isSaving || checkForm()} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveIntervention}>
								{isSaving ?
									<Spinner size="sm" animation="border" role="status" />
									: <FontAwesomeIcon icon="save" />
								}&nbsp;
								{t('modal.save')}
							</Button>
							: ''}
					</Modal.Footer>
				</Modal>
				: ''
			}
		</>
	)
}

























///////////////////////////////////////////////////////////////////////////////////////////////
const InterventionFormDetails = ({ element, functions, t, workarea, ui_list, canWrite, isNew, intervention_type }) => {
	//	const [roomGlobal, setRoomGlobal] = useContext(RoomContext)
	//console.log('element', element)
	//	const [roomList, setRoomList] = useState(element.room_list)
	const { setSecondaryServiceList } = useContext(InterventionContext)
	const [loading, setLoading] = useState(false)
	const [service_roomlist, setServiceRoomlist] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [source_weekdays, setSourceWeekdays] = useState([])
	const [externalTrigger, setExternalTrigger] = useState(false)
	const { contract, structureElements, reverseOrder } = useContext(GlobalDataContext)


	const [roomfilterAppliedList, setRoomFilterAppliedList] = useState(defaultfilter)

	const handleCloseComponent = () => setShowComponentEdit(false);

	const handleShowNewComponent = () => {
		setShowComponentEdit(true)
	}

	//	const [selectedDays, setSelectedDays] = useState(date_list)

	const sumChildrenData = (workarea) => {
		let size = 0
		let risk_area_summary = {}
		let room_count = 0;
		let tempservice_summary = [];
		if (workarea.room_list) {
			for (let c = 0; c < workarea.room_list.length; c++) {
				//	console.log('child list', workarea.level, workarea.room_list[c])
				const _room = workarea.room_list[c]
				room_count += 1;
				const cur_c = structureElements.all_room_dict[_room.id]
				if (cur_c) {
					size += getFloatNumber(cur_c.size)
					//console.log(cur_c)
					if (cur_c.risk_area) {
						if (risk_area_summary[cur_c.risk_area.Id]) {
							risk_area_summary[cur_c.risk_area.Id].size += getFloatNumber(cur_c.size)
							risk_area_summary[cur_c.risk_area.Id].room_count += 1
						} else {
							risk_area_summary[cur_c.risk_area.Id] = { size: getFloatNumber(cur_c.size), room_count: 1, color: rgba2hex(cur_c.risk_area.color) }

						}
					}
					if (cur_c.roomtype) {
						const roomtype = ui_list.roomtype_dict[cur_c.roomtype.id]
						if (roomtype && roomtype.service_list) {

							for (let s = 0; s < roomtype.service_list.length; s++) {
								try {
									const one_service = ui_list.service_dict[roomtype.service_list[s].service.id]
									//								console.log("###----", one_service)
									if (one_service && one_service.type === intervention_type && checkComponentPresence(one_service, tempservice_summary, 'id') < 0) {
										let myservice = ui_list.service_dict[one_service.id]
										tempservice_summary.push(myservice)
									}
								} catch (err) {
									const one_service = roomtype.service_list[s].service
									//							console.log("###----", one_service)
									if (one_service && one_service.type === intervention_type && checkComponentPresence(one_service, tempservice_summary, 'id') < 0) {
										let myservice = ui_list.service_dict[one_service.id]
										tempservice_summary.push(myservice)
									}

								}

							}
						}
					}
				}
			}
		}
		//console.log("update group size:", workarea, size, risk_area_summary, room_count)
		return tempservice_summary
		//		return { risk_area_summary: risk_area_summary, room_count: room_count, size: size, service_summary: service_summary }
		//setCurworkareaList({ ...curItem, risk_area_summary: risk_area_summary, size: size, room_count: room_count })
	}





	const service_summary = sumChildrenData(workarea)



	const addAllSource = () => {
		let editlist = element.room_list
		if (element.room_list) {
			editlist = [...element.room_list]
		}
		setLoading(true)
		for (const el of service_roomlist.filter(r => filterRoom(r))) {
			console.log(el)
			//const room = getSelectedElement(el, workarea.room_list, 'id')
			const room = el
			console.log(el, room)
			const c = checkComponentPresence(el, element.room_list, 'id')
			if (c < 0) {
				editlist.push({ id: room.id, name: room.name, roomtype: { id: room.roomtype.id }, parent_list: room.parent_list, cdc: room.cdc || '', size: room.size })
			}

		}
		console.log("addAll-->new editlist:", editlist)
		setLoading(false)
		refreshInterventionSummary(editlist)
		setExternalTrigger(!externalTrigger)
	}


	useEffect(() => {
		setSecondaryServiceList(element.secondary_service_list)
	}, [element.secondary_service_list])


	const handleAddServiceRoom = (el) => {
		const room = getSelectedElement(el, workarea.room_list, 'id')
		let editlist = element.room_list
		setLoading(true)
		if (editlist) {
			const c = checkComponentPresence(room, element.room_list, 'id')
			if (c >= 0) {
				//FIXME usare alert bello
				alert('room present')
			} else {
				editlist.push({ id: room.id, name: room.name, roomtype: { id: room.roomtype.id }, parent_list: room.parent_list, cdc: room.cdc ? room.cdc : '', size: room.size })
			}
		} else {
			editlist = [{ id: room.id, name: room.name, roomtype: { id: room.roomtype.id }, parent_list: room.parent_list, cdc: room.cdc ? room.cdc : '', size: room.size }]
		}
		console.log("add", el, "-->new editlist:", editlist)
		//		setExternalRoomList(editlist)
		refreshInterventionSummary(editlist)
		//functions.edit({ name: 'room_list', value: editlist })
		setExternalTrigger(!externalTrigger)
		setLoading(false)
	}





	const onUpdateInterventionService = (sel_service) => {
		console.log("ELEMENT", sel_service)
		setLoading(true)
		let intervention_room_list = []
		let weekdays = []
		let structure_list = []
		let pavillon_list = []
		let floor_list = []
		let department_list = []

		setShowComponentEdit(true)
		refreshSourceServiceData(sel_service)
		//		edit_room_list = intervention_room_list
		//		setRoomGlobal(intervention_room_list)
		//		setServiceRoomlist(intervention_room_list)
		const new_intervention_room_list = []
		//setExternalRoomList(new_intervention_room_list)
		console.log("# # # #Service", sel_service, ",  intervention_rooms", intervention_room_list)
		functions.setCurrentEditIntervention({ ...element, room_list: new_intervention_room_list, service: sel_service, weekdays, structure_list, department_list, floor_list, pavillon_list })
		setExternalTrigger(!externalTrigger)
	}





	const refreshSourceServiceData = (sel_service) => {
		console.log("ELEMENT", sel_service)
		setRoomFilterAppliedList(defaultfilter)
		setLoading(true)
		let intervention_room_list = []
		let weekdays = []
		let structure_list = []
		let pavillon_list = []
		let floor_list = []
		let department_list = []
		if (sel_service && sel_service.id) {
			for (let r = 0; r < workarea.room_list.length; r++) {
				const _room = workarea.room_list[r]
				const room = structureElements.all_room_dict[_room.id]
				if (room) {

					if (room.roomtype) {
						try {
							const roomtype = ui_list.roomtype_dict[room.roomtype.id]
							for (let s = 0; s < roomtype.service_list.length; s++) {
								if (roomtype.service_list[s].service && roomtype.service_list[s].service.id === sel_service.id
									//								&& workarea.room_list[r].roomtype.service_list[s].service.type==='ordinary' 
								) {
									if (structure_list.indexOf(room.parent_list[0] !== -1 && room.parent_list[0])) {
										structure_list.push(room.parent_list[0])
									}
									if (pavillon_list.indexOf(room.parent_list[1] !== -1)) {
										pavillon_list.push(room.parent_list[1])
									}
									if (floor_list.indexOf(room.parent_list[2] !== -1)) {
										floor_list.push(room.parent_list[2])
									}
									if (department_list.indexOf(room.parent_list[3] !== -1)) {
										department_list.push(room.parent_list[3])
									}
									intervention_room_list.push(room)
									weekdays = weekdays.concat(roomtype.service_list[s].weekdays)
									//console.log("PREWEEK", weekdays, roomtype.service_list[s].service,)
									//					} else {
									//								console.log("SERVICE NOT PRESENT (room name:)", room.name)
								}
							}
						} catch (err) {
							console.log("ERR", err)
						}
					}
				}
			}
		}
		intervention_room_list = intervention_room_list.sort(propComparator('name'))
		//edit_room_list = intervention_room_list
		//setRoomGlobal(intervention_room_list)
		setServiceRoomlist(intervention_room_list)
		setSourceWeekdays(weekdays)
		setLoading(false)

	}




	const refreshInterventionSummary = (room_list, custom_weekdays) => {
		let weekdays = []
		let structure_list = []
		let pavillon_list = []
		let floor_list = []
		let department_list = []
		let size = 0
		let risk_area_summary = {}
		let room_count = 0;
		for (const _room of room_list) {
			const room = structureElements.all_room_dict[_room.id]
			if (structure_list.indexOf(room.parent_list[0] !== -1)) {
				structure_list.push(room.parent_list[0])
			}
			if (pavillon_list.indexOf(room.parent_list[1] !== -1)) {
				pavillon_list.push(room.parent_list[1])
			}
			if (floor_list.indexOf(room.parent_list[2] !== -1)) {
				floor_list.push(room.parent_list[2])
			}
			if (department_list.indexOf(room.parent_list[3] !== -1)) {
				department_list.push(room.parent_list[3])
			}
			size += getFloatNumber(room.size)
			room_count += 1;
			if (room.risk_area) {
				if (risk_area_summary[room.risk_area.Id]) {
					risk_area_summary[room.risk_area.Id].size += getFloatNumber(room.size)
					risk_area_summary[room.risk_area.Id].room_count += 1
				} else {
					risk_area_summary[room.risk_area.Id] = { size: getFloatNumber(room.size), room_count: 1, color: rgba2hex(room.risk_area.color) }

				}
			}
			try {
				const cw = custom_weekdays || element.custom_weekdays
				if (cw && cw[room.id]) {
					weekdays = weekdays.concat(cw[room.id])
				} else {
					const roomtype = ui_list.roomtype_dict[room.roomtype.id]
					for (let s = 0; s < roomtype.service_list.length; s++) {
						if (roomtype.service_list[s].service && roomtype.service_list[s].service.id === element.service.id) {
							weekdays = weekdays.concat(roomtype.service_list[s].weekdays)
						}
					}
				}
			} catch (err) {
				console.log("ERR", err)
			}
		}
		weekdays = weekdays.filter(onlyUnique)
		structure_list = structure_list.filter(onlyUnique)
		pavillon_list = pavillon_list.filter(onlyUnique)
		floor_list = floor_list.filter(onlyUnique)
		department_list = department_list.filter(onlyUnique)
		console.log("weekdays", weekdays)
		console.log(risk_area_summary)
		if (custom_weekdays) {
			functions.setCurrentEditIntervention({ ...element, room_list, weekdays, structure_list, department_list, floor_list, pavillon_list, size, risk_area_summary, room_count, custom_weekdays })
		} else {
			functions.setCurrentEditIntervention({ ...element, room_list, weekdays, structure_list, department_list, floor_list, pavillon_list, size, risk_area_summary, room_count })
		}
	}


	const clearRoomList = () => {

		refreshInterventionSummary([])
		//functions.edit({ name: 'room_list', value: editlist })
		setExternalTrigger(!externalTrigger)
		setLoading(false)
	}


	const handleUpdateFromList = async (el) => {
		//		let editoperation_list = element.operation_list
		console.log("update from list", el)
		//		setExternalRoomList(el)
		refreshInterventionSummary(el)
		//		functions.edit({ name: 'room_list', value: el })
	}
	const updateCustomFrequency = (el, secondary) => {
		if (secondary) {
			let tmp_custom = { ...element['secondary_custom_weekdays'] } || {}
			console.log("CUSTOMFREQ", tmp_custom, el)
			tmp_custom[el.room_id] = tmp_custom[el.room_id] ? { ...tmp_custom[el.room_id], [secondary]: el.value } : { [secondary]: el.value }
			console.log("CUSTOMFREQ", tmp_custom)
			functions.setCurrentEditIntervention({ ...element, secondary_custom_weekdays: tmp_custom })
			//refreshInterventionSummary(element.room_list, tmp_custom)

		} else {
			let tmp_custom = { ...element['custom_weekdays'] } || {}
			console.log("CUSTOMFREQ", tmp_custom, el)
			tmp_custom[el.room_id] = el.value
			console.log("CUSTOMFREQ", tmp_custom)
			refreshInterventionSummary(element.room_list, tmp_custom)
		}
	}



	useEffect(() => {
		setLoading(true)
		refreshSourceServiceData(element.service)
		setLoading(false)
	}, [])

	/* 	useEffect(() => {
			setLoading(true)
			setLoading(false)
		}, [element.room_list])  */


	const filterRoom = (room) => {
		if ((roomfilterAppliedList.room_pavillon.length === 0 || roomfilterAppliedList.room_pavillon.indexOf(room.parent_list[1]) >= 0)
			&& (roomfilterAppliedList.room_floor.length === 0 || roomfilterAppliedList.room_floor.indexOf(room.parent_list[2]) >= 0)
			&& (roomfilterAppliedList.room_department.length === 0 || roomfilterAppliedList.room_department.indexOf(room.parent_list[3]) >= 0)
		) {
			return true
		}


		return false
	}

	return (

		<Form className="" >
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
				<Col sm="8">
					<Form.Control defaultValue={element.name} type="text" min="0" name="name" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('workarea.labels.service')}</Form.Label>
				<Col sm="6">
					<Form.Control size="sm" as="select" value={getValue(element.service, 'id')} onChange={(event) => onUpdateInterventionService(getSelectedElement(event.target.value, service_summary, 'id'))}>
						<option value="-1">{t('workarea.labels.select_service')}</option>
						{service_summary ?
							<>
								{service_summary.map((i, k) => (
									<option value={i.id} key={k}>{i.name}</option>
								))}
							</> : ''
						}
					</Form.Control>
				</Col>
				<Col>
					{intervention_type === 'ordinary' && element.weekdays ?
						<WeekDays weekdays={element.weekdays} />
						: ''}
				</Col>
				<Col sm="1">

				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('workarea.labels.secondary_services')}</Form.Label>
				<Col sm="6">
					{service_summary ?
						<>
							{service_summary.map((i, k) => (
								<Row value={i.id} key={k}>
									<Col sm="1">
										<Form.Check type="checkbox" disabled={element.service && i.id === element.service.id} className="ms-2" name="secondary_service_list" value={i.id} checked={element.secondary_service_list && element.secondary_service_list.indexOf(i.id) >= 0}
											onChange={(event) => functions.edit(event.currentTarget)} />
									</Col>
									<Col>
										{i.name}
									</Col>
								</Row>
							))}
						</> : ''
					}

				</Col>

			</Form.Group>

			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('workarea.labels.operating_units')}</Form.Label>
				<Col sm="6">
					<Form.Control defaultValue={element.operating_units} type="number" min="0" name="operating_units" placeholder="" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{intervention_type === 'ordinary' &&
				<Form.Group as={Row} className="border-bottom pb-1">
					<Form.Label column sm="2">{t('intervention.labels.use_activity_period')}</Form.Label>
					<Col sm="2">
						<Form.Check type="radio" name="use_activity_period" value={true} checked={element.use_activity_period} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
						<Form.Check type="radio" name="use_activity_period" value={false} checked={!element.use_activity_period} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
				</Form.Group>
			}
			{element.use_activity_period &&
				<OrdinaryActivityPeriod canWrite={canWrite} functions={functions} t={t} element={element} />
			}
			<Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
				<Form.Label column sm="2">Data inizio modifiche </Form.Label>
				<Col sm="5">
					<DateTime initialValue={myTimestampToDate(element.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'startDate', value: myDateToTimestamp(e) })} />
					{console.log(myTimestampToDate(element.startDate), new Date(), myTimestampToDate(element.startDate) < new Date())}
					<Form.Text className={myTimestampToDate(element.startDate) < new Date() ? "text-danger" : "text-muted"}>
						(tutti gli interventi e i report successivi a questa data (compresa) verranno cancellati e sostituiti in base ai parametri salvati)
					</Form.Text>
				</Col>
			</Form.Group>
			{intervention_type === 'ordinary' ?
				<OrdinaryDateTimeSelector element={element} t={t} functions={functions} isNew={isNew} />
				:
				<PeriodicDateTimeSelector element={element} t={t} functions={functions} isNew={isNew} />
			}

			{intervention_type === 'ordinary' &&
				<Form.Group as={Row} className="border-bottom pb-1">
					<Form.Label column sm="2">{t('intervention.labels.enable_refill')}</Form.Label>
					<Col sm="2">
						<Form.Check type="radio" name="enable_refill" value={true} checked={element.enable_refill} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
						<Form.Check type="radio" name="enable_refill" value={false} checked={!element.enable_refill} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
				</Form.Group>
			}
			{intervention_type === 'ordinary' &&
				<Form.Group as={Row} className="border-bottom pb-1">
					<Form.Label column sm="2">{t('intervention.labels.supervision')}</Form.Label>
					<Col sm="2">
						<Form.Check type="radio" name="is_supervision" value={true} checked={element.is_supervision} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
						<Form.Check type="radio" name="is_supervision" value={false} checked={!element.is_supervision} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
				</Form.Group>
			}
			{element.is_supervision &&
				<Form.Group as={Row} className="border-bottom pb-1">
					<Form.Label column sm="2">{t('workarea.labels.min_operator_requested')}</Form.Label>
					<Col sm="6">
						<Form.Control defaultValue={element.min_operator_requested} type="number" min="0" name="min_operator_requested" placeholder="" onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
				</Form.Group>
			}


			<Form.Group as={Row} controlId="companyName" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('workarea.labels.max_check_time')}</Form.Label>
				<Col sm="6">
					<InputGroup>
						<Form.Control defaultValue={element.max_check_delay} type="number" min="0" name="max_check_delay" placeholder="" onChange={(event) => functions.edit(event.currentTarget)} />

						<InputGroup.Text>
							{t('global.labels.minutes')}
						</InputGroup.Text>

					</InputGroup>
				</Col>
			</Form.Group>
			<Row className="border-bottom h5 pb-1 ">
				<Col sm="7">
					{t('workareas.labels.room_list')} <Badge className="me-1" variant="info">{element.room_list.length} {t('global.labels.rooms')}</Badge>
					{element.room_list.length > 0 ?
						<Button variant="outline-danger" size="sm" onClick={clearRoomList}><FontAwesomeIcon icon="trash" />Rimuovi tutti i locali </Button>
						: ''}
				</Col>
				{canWrite ?
					<Col className="text-end">
						{showComponentEdit ?
							<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" />{t('service.labels.component_modend')}</Button>
							:
							<Button variant="outline-primary" size="sm" onClick={() => handleShowNewComponent()}><FontAwesomeIcon icon="edit" />{t('global.labels.start_selection')}</Button>
						}
					</Col>
					: ''}
			</Row>
			<Row>
				{loading ?
					<Spinner />
					:
					<Col>
						<DndProvider backend={HTML5Backend}>
							<EditRoomListContainer
								wa_id={workarea.id}
								intervention={element}
								rooms={element.room_list}
								t={t}
								ui_list={ui_list}
								externalTrigger={externalTrigger}
								functions={{ update: handleUpdateFromList, setEdit: functions.setEdit, updateCustomFrequency: updateCustomFrequency }}
							/>
						</DndProvider>
					</Col>
				}
				{showComponentEdit
					?
					<>
						<Col className="border-start editSection">
							{service_roomlist && service_roomlist.length > 0 ?
								<>
									<Row className="p-1 text-light section_title">
										<Col className="ms-1 text-dark" sm="7">
											{t('workarea.labels.roomlist_source')}
										</Col>
										{intervention_type === 'ordinary' ?
											<Col className="p-0">
												<WeekDays weekdays={source_weekdays} />
											</Col>
											: ''}
									</Row>
									<Row>
										<Col>
											<Button onClick={addAllSource} variant="light"><FontAwesomeIcon icon="plus" /> {service_roomlist.filter(r => filterRoom(r)).length} {t('global.labels.rooms')}</Button>
										</Col>
										<Col>
											<ModalFilterRoom service_roomlist={service_roomlist} filterAppliedList={roomfilterAppliedList} setFilterAppliedList={setRoomFilterAppliedList} defaultfilter={defaultfilter} t={t} ui_list={ui_list} />
										</Col>
										<Col>
										<OrderButton />
										</Col>

									</Row>
									<Row className="p-1 section_title mb-1">
										<Col className="ms-1 bg-light text-secondaryi text-center" sm="1">{t('global.labels.add')}</Col>
										<Col className="ms-1 bg-light text-secondary">{t('global.labels.name')}</Col>
										<Col className="ms-1 bg-light text-secondary" sm="2">{t('structures.labels.room_type')}</Col>
										<Col className="ms-1 bg-light text-secondary" sm="3">{t('workareas.labels.frequency')}</Col>
										<Col className="ms-1 bg-light text-secondary text-center" sm="1" >m<sup>2</sup></Col>
										<Col className="ms-1 bg-light text-secondary text-center" sm="1" >
											{contract && contract.type === 'civil' ?
												t('global.labels.standard_cleaning')
												:
												t('global.labels.risk_area')
											}
										</Col>
									</Row>
									<Row>
										<Col className={reverseOrder ? "d-flex flex-column-reverse" : ''}>
											{service_roomlist.filter(r => filterRoom(r)).map((i, key) => (
												<RoomEditRowSource key={key} room={i} handleAddServiceRoom={handleAddServiceRoom} intervention_room_list={element.room_list} ui_list={ui_list} service_id={element.service.id} />
											))
											}
										</Col>
									</Row>
								</>
								: <i> {t('workareas.messages.no_service_room')}</i>
							}
						</Col>
					</>
					: ''
				}
			</Row>
		</Form>
	)

}




//////////////////////////////////////////////////////////////////////////////////////////////////////////////
function OrdinaryActivityPeriod({ element, functions, t, canWrite }) {


	const newperiod = {
		id: makeid(5),
		description: '',
		startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
	}
	const [showPeriodEdit, setShowPeriodEdit] = useState(false)
	const [newPeriodEdit, setNewPeriodEdit] = useState(true)
	const [curPeriod, setCurPeriod] = useState(newperiod)
	const [editIndex, setEditIndex] = useState(-1)
	const [editedElement, setEditedElement] = useState(false)

	//----period
	const handleClosePeriod = () => setShowPeriodEdit(false);

	const handleSavePeriod = () => {
		if (newPeriodEdit) {
			let newperiod_list = element.period_list || []
			newperiod_list.push(curPeriod)
			console.log(newperiod_list)
			functions.edit({ name: 'period_list', value: newperiod_list })
		} else {
			let editlist = element.period_list
			console.log(curPeriod, editlist)
			if (!curPeriod.id) {
				curPeriod.id = makeid(5)
			}
			editlist.splice(editIndex, 1, curPeriod)
			functions.edit({ name: 'period_list', value: editlist })
		}
		setShowPeriodEdit(false);
	}

	const handleDeletePeriod = (el, index) => {
		console.log(el)
		let editlist = element.period_list
		editlist.splice(index, 1)
		functions.edit({ name: 'period_list', value: editlist })
	}

	const handleShowNewPeriod = () => {
		setCurPeriod(newperiod)
		setEditIndex(-1)
		setNewPeriodEdit(true)
		setShowPeriodEdit(true)
		setEditedElement(false)
	}

	const handleShowEditPeriod = (i, index) => {
		console.log('edit', i)
		setEditIndex(index)
		setCurPeriod(i)
		setNewPeriodEdit(false)
		setShowPeriodEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerPeriod = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		setEditedElement(true)
		console.log(name, value)
		setCurPeriod({ ...curPeriod, [name]: value })
	}



	return (
		<>
			<Row>
				<Col sm="2">{t('global.labels.activity_period_list')}</Col>
				<Col>
					<Row>
						<Col className="text-end">
							<Button variant="outline-primary" size="sm" onClick={() => handleShowNewPeriod()}><FontAwesomeIcon icon="plus-square" /> {t('suppliers.labels.period_add')}</Button>
						</Col>

					</Row>
					<Row className="border-bottom  border-end  border-start mb-2 mt-1 pb-1">
						{element.period_list && element.period_list.length > 0 ?
							<Col>
								<Row className="mb-1">
									<Col className="bg-secondary text-light">{t('global.labels.description')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.start_date')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.end_date')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('interventions.labels.length')} </Col>
									<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
								</Row>
								{element.period_list.map((i, key) => (
									<Row key={key} className="border-bottom border-info mb-2 pb-1 element_row">
										<Col>{i.description}</Col>
										<Col>{format(myTimestampToDate(i.startDate), 'dd/MM/yyyy')}
										</Col>
										<Col>{format(myTimestampToDate(i.endDate), 'dd/MM/yyyy')}
										</Col>
										<Col>
											{differenceInDays(myTimestampToDate(i.endDate), myTimestampToDate(i.startDate))}
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditPeriod(i, key)} /> </Button>
												{canWrite ?
													<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeletePeriod(i, key)} /> </Button>
													: ''}
											</ButtonGroup>
										</Col>
									</Row>))
								}
								<Row className="border-bottom border-info mb-1 pb-1 bg-info text-light">
									<Col>
										TOTALE
									</Col>
									<Col>
									</Col>
									<Col>
									</Col>
									<Col>
										{element.period_list.map(i => differenceInDays(myTimestampToDate(i.endDate), myTimestampToDate(i.startDate))).reduce((a, b) => a + b, 0)}
									</Col>
									<Col sm="1" className="text-center">
									</Col>
								</Row>
							</Col>
							: <Col className="font-italic"><FontAwesomeIcon icon="exclamation-triangle" /> {t('suppliers.messages.no_period')}</Col>
						}
					</Row>

				</Col>
			</Row>



			<Modal show={showPeriodEdit} onHide={handleClosePeriod} size="xl" bsclass="full-modal" backdrop="static" >
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					{newPeriodEdit && <Modal.Title>{t('suppliers.labels.new_period')}</Modal.Title>}
					{!newPeriodEdit && <Modal.Title>{t('suppliers.labels.edit_period')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="1">{t('global.labels.description')}</Form.Label>
							<Col sm="7">
								<Form.Control size="sm" type="text" name="description" value={curPeriod.description} onChange={(event) => onChangeHandlerPeriod(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
							<Form.Label column sm="2">{t('contract.labels.start')}</Form.Label>
							<Col sm="3">
								<DateTime initialValue={myTimestampToDate(curPeriod.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerPeriod({ name: 'startDate', value: myDateToTimestamp(e) })} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
							<Form.Label column sm="2">{t('contract.labels.end')}</Form.Label>
							<Col sm="3">
								<DateTime initialValue={myTimestampToDate(curPeriod.endDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerPeriod({ name: 'endDate', value: myDateToTimestamp(e) })} />
							</Col>
						</Form.Group>
						<Row>
							<Col>
								{differenceInDays(myTimestampToDate(curPeriod.endDate), myTimestampToDate(curPeriod.startDate))} giorni
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClosePeriod}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSavePeriod}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>

		</>
	)
}



const OrdinaryDateTimeSelector = ({ element, t, functions, disabled }) => {
	return (
		<>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('workareas.labels.start_time')}</Form.Label>
				<Col sm="6">
					<DateTime disabled={disabled} initialValue={myTimestampToDate(element.start)} dateFormat={false} timeFormat='HH:mm' name="start" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'start', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('workareas.labels.end_time')}</Form.Label>
				<Col sm="6">
					<DateTime disabled={disabled} initialValue={myTimestampToDate(element.end)} dateFormat={false} timeFormat='HH:mm' name="end" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'end', value: myDateToTimestamp(e) })} />
				</Col>
				{/* 				<Col>
					{differenceInMinutes(myTimestampToDate(element.end), myTimestampToDate(element.start))} minuti
					<Form.Text>
						durata
					</Form.Text>
				</Col> */}
			</Form.Group>
		</>
	)

}






const PeriodicDateTimeSelector = ({ isNew, element, t, functions }) => {

	const { contractId, contract } = useContext(GlobalDataContext)
	const childRefAlert = useRef()

	const [isSettingRecurrency, setIsSettingRecurrrency] = useState(isNew)
	//	const [isSameTime, setIsSameTime] = useState(true)
	const [viewDays, setViewDays] = useState([])


	const frequency_elements = frequency_dict({ t })
	let date_range = []
	let date_list = []
	let view_date_list = []

	let start_date = toDateTime(firebase.firestore.Timestamp.now())
	if (element.startDate) {
		start_date = toDateTime(element.startDate)
	}

	//-------date_list
	try {
		date_list = element.periodic_data.date_list || []
		date_list.map((i, k) => {
			view_date_list.push(myTimestampToDate(i.date))
		})
		//		setViewDays(view_date_list)
	} catch (err) {
		console.log('error read element periodic date', err)
		date_list = []
	}
	//--------date rannge
	try {
		date_range = element.periodic_data.date_range || []
	} catch (err) {
		console.log('error read element periodic date', err)
		date_range = []
	}

	const [selectedDays, setSelectedDays] = useState(date_list)
	const [selectedRange, setSelectedRange] = useState(date_range)





	/* 	const handleSelectRange = (ev) => {
			console.log(ev, date_list, isSettingRecurrency)
			date_list.push({ startDate: ev.date, endDate: ev.date, color: '#00aabb' })
			//			editDays.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
			setSelectedRange(date_list)
			functions.edit({ name: 'date_list', value: date_list })
		} */

	const handleSelectDays = (day, { selected }) => {
		console.log(`DAY, ${day} is SELECTED2: ${selected}`)
		if (isSettingRecurrency) {
			handleChangeFrequency(day)
		} else {
			if (day >= myTimestampToDate(start_date)) {

				let editDays = [...selectedDays]
				let editViewDays = [...selectedRange]
				let new_rep = element.periodic_data.date_range || []
				try {
					console.log("SELECTED", selected)
					if (selected) {
						if (element.intervention_length === 1) {
							const editIndex = editViewDays.findIndex(editOneDay => {
								console.log(myTimestampToDate(editOneDay), editOneDay, day, DateUtils.isSameDay(myTimestampToDate(editOneDay), day))
								return DateUtils.isSameDay(myTimestampToDate(editOneDay), day)
							}
							);
							console.log(editViewDays, editIndex)
							if (editIndex >= 0) {
								editDays.splice(editIndex, 1);
								editViewDays.splice(editIndex, 1);
								new_rep.splice(editIndex, 1);
							}
						} else {
							const editIndex = new_rep.findIndex(editOneDay => {
								//DateUtils.isSameDay(editDay, day)
								console.log(myTimestampToDate(editOneDay), editOneDay, day, DateUtils.isSameDay(myTimestampToDate(editOneDay), day))

								return DateUtils.isSameDay(myTimestampToDate(editOneDay), day)
							}
							);
							if (editIndex >= 0) {
								editDays.splice(editIndex * element.intervention_length, element.intervention_length);
								editViewDays.splice(editIndex, 1);
								new_rep.splice(editIndex, 1);
							}
						}
					} else {
						let rep = []
						new_rep.push(myDateToTimestamp(new Date(day)))
						for (let a = 0; a < element.intervention_length; a++) {
							const new_d = addDays(new Date(day), a)
							console.log("old, new", rep, new_d, myDateToTimestamp(new_d))
							rep.push({ date: myDateToTimestamp(new_d), start: element.start, end: element.end })
						}

						editDays = editDays.concat(rep);
						editViewDays = editViewDays.concat(rep)
						console.log("---ADDED", rep, new_rep, editDays)
						//editDays.push(new Date(2021, 5, 12));
					}
					editDays.sort((a, b) => myTimestampToDate(a.date) - myTimestampToDate(b.date));
					new_rep.sort((a, b) => myTimestampToDate(a) - myTimestampToDate(b));
					setSelectedDays(editDays)
					functions.edit({ name: 'periodic_data', value: { ...element.periodic_data, date_list: editDays, date_range: new_rep } })
					console.log(editDays)
				} catch (err) {
					console.error("errror change days", err)

				}
			} else {
				childRefAlert.current.addAlert('warning', 'Data antecedente alla data di inizio modifiche. Impossibile modificare')
				console.log("DATA ANTECEDENTE")
				return

			}
		}
	}


	/* 	const selectAllDay = () => {
			functions.edit({ name: 'weekdays', value: ['0', '1', '2', '3', '4', '5', '6'] })
		}
	
		const unSelectAllDay = () => {
			functions.edit({ name: 'weekdays', value: [] })
		} */

	const handleChangeFrequency = (start) => {
		const value = element.frequency_mode
		console.log(value)
		console.log(toDateTime(contract.endDate))
		let rule = {
			dtstart: start,
			until: toDateTime(contract.endDate)
		}
		let step_interval = 0
		if (value === 'week') {
			rule.freq = RRule.WEEKLY
			rule.interval = 1
			step_interval = parseInt(7 / element.frequency_times)
		} else if (value === "twoweek") {
			rule.freq = RRule.WEEKLY
			rule.interval = 2
			step_interval = parseInt(7 / element.frequency_times)

		} else if (value === "month") {
			rule.freq = RRule.MONTHLY
			rule.interval = 1
			step_interval = parseInt(30 / element.frequency_times)
		} else if (value === "bimonthly") {
			rule.freq = RRule.MONTHLY
			rule.interval = 2
			step_interval = parseInt(60 / element.frequency_times)
		} else if (value === "quarter") {
			rule.freq = RRule.MONTHLY
			rule.interval = 3
			step_interval = parseInt(92 / element.frequency_times)
		} else if (value === "four-monthly") {
			rule.freq = RRule.MONTHLY
			rule.interval = 4
			step_interval = parseInt(125 / element.frequency_times)
		} else if (value === "semester") {
			rule.freq = RRule.MONTHLY
			rule.interval = 6
			step_interval = parseInt(182 / element.frequency_times)
		} else if (value === "yearly") {
			rule.freq = RRule.MONTHLY
			rule.interval = 12
			step_interval = parseInt(365 / element.frequency_times)
		}
		let rep_global = []

		for (let t = 0; t < element.frequency_times; t++) {
			rule.dtstart = addDays(start, step_interval * t)
			console.log(rule.dtstart, start)
			console.log(rule)
			const rep = new RRule(rule).all()
			console.log("rule date range", rep)
			rep_global = [...rep_global, ...rep]
		}

		rep_global.sort((a, b) => new Date(a) < new Date(b))

		setSelectedRange(rep_global)
		let new_r = []

		for (let d = 0; d < rep_global.length; d++) {
			for (let a = 0; a < element.intervention_length; a++) {
				const new_d = addDays(rep_global[d], a)
				console.log("old, new", rep_global[d], new_d)
				new_r.push({ date: new_d, start: element.start, end: element.end })
			}
		}

		console.log("new_ exploded date_list", new_r)
		setSelectedDays(new_r)
		functions.edit({ name: 'periodic_data', value: { ...element.periodic_data, date_list: new_r, date_range: rep_global } })
	}



	const updateTime = (index, s, time) => {

		let new_date_list = [...element.periodic_data.date_list]
		if (element.periodic_data.isSameTime) {
			for (var d = 0; d < new_date_list.length; d++) {
				new_date_list[d][s] = time
			}

		} else {
			new_date_list[index][s] = time
			console.log(time)
		}
		functions.edit({ name: 'periodic_data', value: { ...element.periodic_data, date_list: new_date_list, date_range: element.periodic_data.date_range } })
	}





	const setIsSameTime = (v) => {
		let new_date_list = [...element.periodic_data.date_list]
		if (v) {
			for (var d = 0; d < new_date_list.length; d++) {
				new_date_list[d].start = element.start
				new_date_list[d].end = element.end
			}
		}
		functions.edit({ name: 'periodic_data', value: { ...element.periodic_data, isSameTime: v, date_list: new_date_list } })
	}


	useEffect(() => {
		let new_date_list = [...element.periodic_data.date_list]
		if (element.periodic_data.isSameTime) {
			for (var d = 0; d < new_date_list.length; d++) {
				new_date_list[d].start = element.start
				new_date_list[d].end = element.end
			}
		}
		functions.edit({ name: 'periodic_data', value: { ...element.periodic_data, date_list: new_date_list } })

	}, [element.start, element.end])

	return (
		<>

			<AlertSection ref={childRefAlert} />
			<Form.Group as={Row} controlId="companyName" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('workarea.labels.length')}</Form.Label>
				<Col sm="2">
					<InputGroup>
						<Form.Control defaultValue={element.intervention_length} type="number" min="1" max="30" disabled={!isSettingRecurrency} name="intervention_length" onChange={(event) => functions.edit(event.currentTarget)} />

						<InputGroup.Text>
							{parseInt(element.intervention_length) === 1 ?
								<>{t('global.labels.day')}</>
								:
								<>{t('global.labels.days')}</>
							}
						</InputGroup.Text>


					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureCF" className="border-bottom">
				<Form.Label column sm="2">{t('workareas.labels.type_of_frequency')}</Form.Label>
				<Col>
					<Button className="btn-block" onClick={() => setIsSettingRecurrrency(true)} variant={isSettingRecurrency ? "info" : "outline-info"}>Inizializza tutte le date</Button>
					<Button className="btn-block" onClick={() => setIsSettingRecurrrency(false)} variant={!isSettingRecurrency ? "info" : "outline-info"}>Modifica data singola</Button>
				</Col>
				<Col sm="2">
					<InputGroup>
						<Form.Control defaultValue={element.frequency_times} type="number" disabled={!isSettingRecurrency} min="1" max="30" name="frequency_times" onChange={(event) => functions.edit(event.currentTarget)} />

						<InputGroup.Text>
							{parseInt(element.frequency_times) === 1 ?
								<>{t('global.labels.times')}</>
								:
								<>{t('global.labels.timess')}</>
							}
						</InputGroup.Text>


					</InputGroup>
				</Col>
				<Col sm="6">
					<Form.Control as="select" value={element.frequency_mode} name="frequency_mode" disabled={!isSettingRecurrency} onChange={(event) => functions.edit(event.target)}  >
						{Object.keys(frequency_elements).map((fk, k) => (
							<option key={k} value={frequency_elements[fk].value} >
								{frequency_elements[fk].label}
							</option>
						))}
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workarea.labels.same_time')}</Form.Label>
				<Col>
					<Button onClick={() => setIsSameTime(true)} variant={element.periodic_data.isSameTime ? "info" : "outline-info"}><FontAwesomeIcon icon="check-square" /></Button>
					<Button onClick={() => setIsSameTime(false)} variant={!element.periodic_data.isSameTime ? "info" : "outline-info"}><FontAwesomeIcon icon="square" /></Button>
				</Col>
			</Form.Group>
			{element.periodic_data.isSameTime ?
				<OrdinaryDateTimeSelector element={element} t={t} functions={functions} disabled={!element.periodic_data.isSameTime} />
				: ''
			}
			<Row>
				<Col>
					{isSettingRecurrency ? <Row><Col className="bg-info text-light">seleziona la data di inizio della ricorsione</Col></Row> : ''}
					<DayPicker
						numberOfMonths={12}
						selectedDays={view_date_list}
						onDayClick={handleSelectDays}
						localeUtils={MomentLocaleUtils} locale='it'
					/>
				</Col>
				<Col sm="5">
					{selectedDays && selectedDays.length > 0 ?
						<>
							<Row className="section_title">
								<Col>({element.intervention_length > 1 ? element.periodic_data.date_range.length : ''} {selectedDays.length}) {t('workareas.labels.days_selected')} </Col>
								<Col sm="3">{t('workareas.labels.start_time')}</Col>
								<Col sm="3">{t('workareas.labels.end_time')}</Col>
								<Col sm="1"></Col>
							</Row>
							<span style={{ height: '400px', overflowY: 'auto', position: 'absolute', overflowX: 'hidden' }}>
								{selectedDays.map((i, k) => (
									<Row key={k} className="border-bottom mb-1 pb-1">
										<Col className={myTimestampToDate(i.date) < start_date ? "bg-secondary text-light" : ""}>
											{format(myTimestampToDate(i.date), 'dd/MM/yyyy')}
											{k > 0 &&
												<span className="text-info">[+ {differenceInDays(myTimestampToDate(i.date), myTimestampToDate(selectedDays[k - 1].date))}gg]</span>
											}
										</Col>
										<Col sm="3">
											{element.periodic_data.isSameTime
												?
												getFormattedDate(myTimestampToDate(i.start), 'HH:mm')
												:
												<DateTime initialValue={myTimestampToDate(i.start)} dateFormat={false} timeFormat='HH:mm' name="start" locale="it" closeOnSelect={true} onChange={(e) => updateTime(k, 'start', myDateToTimestamp(e))} />
											}
										</Col>
										<Col sm="3" className={myTimestampToDate(i.end) <= myTimestampToDate(i.start) ? 'text-danger' : ''}>
											{element.periodic_data.isSameTime
												?
												getFormattedDate(myTimestampToDate(i.end), 'HH:mm')
												:
												<DateTime initialValue={myTimestampToDate(i.end)} dateFormat={false} timeFormat='HH:mm' name="end" locale="it" closeOnSelect={true} onChange={(e) => updateTime(k, 'end', myDateToTimestamp(e))} />
											}
										</Col>
										<Col sm="1"></Col>
									</Row>
								))}
							</span>
						</>
						: ''}
				</Col>
			</Row>
		</>
	)

}





///////////////////////////////////////////////////////////////////////////////////////////////
const ModalPeriodicIntervention = ({ is_new, element, k, functions, workarea, t, canWrite, updateWorkarea }) => {

	const { domain } = useContext(DomainContext)
	const { contractId, contract } = useContext(GlobalDataContext)
	//	const [roomGlobal, setRoomGlobal] = useContext(RoomContext)
	//	const { intervention_dict } = useContext(InterventionContext)

	const [currentEditIntervention, setCurrentEditIntervention] = useState(new_intervention)
	const [showEditIntervention, setShowEditIntervention] = useState(false);
	const [editedElement, setEditedElement] = useState(false)
	const [loading, setLoading] = useState(true)
	const [isSaving, setIsSaving] = useState(false)
	const {
		//                intervention_dict, updateInterventionDict,
		//				update_hour, setUpdateHour,
		intervention_dict_ref,
		ui_list
	} = useContext(InterventionContext)
	//-----intervention form

	const handleCloseIntervention = () => setShowEditIntervention(false);
	const handleShowNewIntervention = (w) => {
		//		update_hour = false
		//	setUpdateHour(false)
		//		setCurWorkarea(workarea)
		//	setNewIntervention(true)
		setShowEditIntervention(true)
		//		setRoomGlobal(new_intervention.room_list)
		const tmp_new_intervention = { ...new_intervention, type: 'periodic', days_length: 1, frequency_mode: 'month', frequency_times: 1 }
		setCurrentEditIntervention(tmp_new_intervention)
		setEditedElement(false)
	}


	const handleEditOneIntervention = () => {
		//update_hour = false
		//setUpdateHour(false)
		//		setCurWorkarea(w)
		//		setNewIntervention(false)
		setShowEditIntervention(true)
		setCurrentEditIntervention(element)
		setEditedElement(false)
		//setRoomGlobal(element.room_list)
		setTimeout(function () {
			setEditedElement(false)
		}, 99)
	}





	const handleSaveIntervention = async () => {
		//		currentEditIntervention.room_list = roomGlobal
		setIsSaving(true)
		let editlist = []
		if (workarea.periodicintervention_list) {
			editlist = [...workarea.periodicintervention_list]
		}
		let tmp_editworkarea = { ...workarea }
		const date_list = currentEditIntervention.periodic_data.date_list
		let event_list = []
		let freq_date_list = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
		if (date_list.length > 0) {
			console.log(date_list)
			let curDate = myTimestampToDate(date_list[0].date)
			console.log(curDate)

			const end_date = addYears(curDate, 1)
			//			const end_date = toDateTime(contract.endDate)
			let dd = 0
			console.log(curDate, end_date)
			let done = false
			while (curDate < end_date && !done) {
				console.log(curDate, done)
				if (date_list[dd]) {
					curDate = myTimestampToDate(date_list[dd].date)
					console.log(curDate, curDate.getMonth())
					freq_date_list[curDate.getMonth()] = freq_date_list[curDate.getMonth()] + 1
					dd = dd + 1
				} else {
					done = true
				}
			}
			console.log("periodic graphical freq creator", freq_date_list)
		}

		currentEditIntervention.freq_date = freq_date_list

		let start_date = toDateTime(firebase.firestore.Timestamp.now())
		if (currentEditIntervention.startDate) {
			start_date = toDateTime(currentEditIntervention.startDate)
		}
		if (is_new) {
			currentEditIntervention.type = 'periodic'
			const return_data = await addIntervention(domain, contractId, currentEditIntervention)
			currentEditIntervention.id = return_data.id
			intervention_dict_ref.current[return_data.id] = currentEditIntervention
			//updateInterventionDict(return_data.id, currentEditIntervention)
			console.log('new intervention')
			editlist.push(return_data.id)

		} else {
			console.log('edit intervention', currentEditIntervention.index, editlist)
			await editIntervention(domain, contractId, currentEditIntervention.id, currentEditIntervention)
			functions.edit(currentEditIntervention)
			intervention_dict_ref.current[currentEditIntervention.id] = currentEditIntervention
			//updateInterventionDict(currentEditIntervention.id, currentEditIntervention)
			//const k = editlist.indexOf(currentEditIntervention.id)
			//			const return_dataDelete = await deleteEventBatch(domain, contractId, currentEditIntervention.id)
			//			const return_dataDelete = await deleteFutureEvents({ DomainId: domain, ContractId:contractId, interventionId: currentEditIntervention.id, start: firebase.firestore.Timestamp.fromDate(setDate(new Date(), { hours: 0, minutes: 0, seconds: 0 })))
			if (currentEditIntervention.startDate) {
				const return_dataDelete = await deleteFutureEvents({ DomainId: domain, ContractId: contractId, interventionId: currentEditIntervention.id, start: currentEditIntervention.startDate })
				console.log("DELETING", return_dataDelete)
			} else {
				const return_dataDelete = await deleteFutureEvents({ DomainId: domain, ContractId: contractId, interventionId: currentEditIntervention.id, start: firebase.firestore.Timestamp.now() })
				console.log("DELETING", return_dataDelete)
			}
		}
		editlist = editlist.sort(compareHour(intervention_dict_ref.current))
		console.log("EDITLIST", editlist)
		tmp_editworkarea.periodicintervention_list = editlist
		if (is_new || update_hour) {
			console.log("EDITWORKAREA", domain, contractId, workarea.id, tmp_editworkarea)
			await editWorkarea(domain, contractId, workarea.id, tmp_editworkarea)
		}
		updateWorkarea(tmp_editworkarea)


		let ev_counter = 0
		setTimeout(async function () {
			for (let d = 0; d < date_list.length; d++) {
				const nd = myTimestampToDate(date_list[d].date)
				nd.setSeconds(0)
				nd.setMilliseconds(0)
				if (nd >= start_date) {
					ev_counter += 1
					console.log(nd, "|", myTimestampToDate(date_list[d].end), "|", myTimestampToDate(date_list[d].start))
					const newEvent = {
						start: setMinutes(setHours(nd, getHours(myTimestampToDate(date_list[d].start))), getMinutes(myTimestampToDate(date_list[d].start))),
						end: setMinutes(setHours(nd, getHours(myTimestampToDate(date_list[d].end))), getMinutes(myTimestampToDate(date_list[d].end))),
						extendedProps: {
							type: 'periodic_intervention',
							workarea_id: workarea.id,
							workarea_name: workarea.name,
							intervention_id: currentEditIntervention.id,
							structure_list: currentEditIntervention.structure_list,
							pavillon_list: currentEditIntervention.pavillon_list,
							floor_list: currentEditIntervention.floor_list,
							department_list: currentEditIntervention.department_list
						}
					};
					event_list.push(newEvent)
					if (event_list.length > MAX_EVENT_DB_INSERT) {
						const return_dataE = await addEventBatch(domain, contractId, event_list)
						console.log(event_list, domain, contractId, return_dataE)
						event_list = []
					}
				} else {
					console.log("date before start_date ...doesnt insert", nd)
				}
			}
			console.log(event_list, domain, contractId)
			const return_dataEvent = await addEventBatch(domain, contractId, event_list)
			console.log(return_dataEvent)
			//alert(`${ev_counter} eventi inseriti in calendario`)
			setIsSaving(false)
			setShowEditIntervention(false);
		}, 3000)

	}


	const onChangeHandlerIntervention = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')
		} catch {
			sub = undefined;
		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = [...currentEditIntervention[name]]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...currentEditIntervention[sub], [name]: value }
			name = sub;
		}
		if (name === 'start' || name === 'end') {
			update_hour = true
		}
		console.log("intervention new value", name, value)
		setCurrentEditIntervention({ ...currentEditIntervention, [name]: value })
		setEditedElement(true)
	}




	return (
		<>
			{is_new ?
				<Button variant="primary" size="sm" onClick={() => handleShowNewIntervention()} ><FontAwesomeIcon icon="plus-square" /> {t('workareas.buttons.add_intervention')}</Button>
				:
				<Button size="sm" variant="info" onClick={() => handleEditOneIntervention()}><FontAwesomeIcon icon={canWrite ? "pencil-alt" : "info-circle"} /> </Button>
			}
			{showEditIntervention ?
				<Modal show={showEditIntervention} onHide={handleCloseIntervention} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> {workarea.name} | <span className="text-primary">{t('workareas.labels.new_intervention')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> {workarea.name} | <span className="text-info">{t('workareas.labels.edit_intervention')} </span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> {workarea.name} | <span className="text-info">{t('workareas.labels.info_intervention')}</span>
								</>
							]
						}
						: {currentEditIntervention.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							<InterventionFormDetails intervention_type={"periodic"} element={currentEditIntervention} workarea={workarea} functions={{ edit: onChangeHandlerIntervention, setEdit: setEditedElement, setCurrentEditIntervention: setCurrentEditIntervention }} t={t} ui_list={ui_list} canWrite={canWrite} isNew={is_new} />
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" disabled={isSaving} onClick={handleCloseIntervention}>
							{t('modal.close')}
						</Button>
						{canWrite ?
							<Button disabled={isSaving} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveIntervention}>
								{isSaving ?
									<Spinner size="sm" animation="border" role="status" />
									: <FontAwesomeIcon icon="save" />
								}&nbsp;
								{t('modal.save')}
							</Button>
							: ''}
					</Modal.Footer>
				</Modal>
				: ''
			}
		</>
	)
}









///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function compareHour(_intervention_dict) {

	function mycompare(a, b) {
		console.log(a, b, _intervention_dict[a], _intervention_dict[b])

		if (getTimeValue(_intervention_dict[a].start) < getTimeValue(_intervention_dict[b].start)) {
			return -1;
		}
		if (getTimeValue(_intervention_dict[a].start) > getTimeValue(_intervention_dict[b].start)) {
			return 1;
		}
		return 0;
	}
}












///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const OneIntervention = ({ intervention_id, workarea, updateWorkarea, canWrite, t, globalEdit, childRefDelete, childRefAlert, ModalIntervention, yieldRead }) => {

	const { domain } = useContext(DomainContext)
	const { contractId, service_dict, structureElements } = useContext(GlobalDataContext)

	const [intervention, setIntervention] = useState()
	const [loading, setLoading] = useState(false)
	const [showRoomList, setShowRoomList] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const {
		//                intervention_dict, updateInterventionDict, setInterventionDict,
		intervention_dict_ref,
		ui_list
	} = useContext(InterventionContext)



	const handleDeleteIntervention = async () => {
		console.log("ASK DELETE", intervention_id)
		const cb = async () => {
			let tmp_editworkarea = { ...workarea }
			let editlist = []
			if (intervention.frequency_mode === 'ordinary') {
				editlist = tmp_editworkarea.intervention_list
			} else {
				editlist = tmp_editworkarea.periodicintervention_list

			}
			const k = editlist.indexOf(intervention_id)
			if (k >= 0) {
				editlist.splice(k, 1)
			} else {
				console.log("ERROR find index", editlist, intervention_id, k)
			}
			console.log("DELETE", intervention_id)
			delete intervention_dict[intervention_id]
			delete intervention_dict_ref.current[intervention_id]

			//let _intervention_dict = intervention_dict
			//delete _intervention_dict[intervention_id]
			//setInterventionDict(_intervention_dict)
			//			removeIntervention(intervention_dict)
			const return_dataDelete = await deleteEventBatch(domain, contractId, intervention_id)
			console.log(return_dataDelete)
			let return_data = await editWorkarea(domain, contractId, tmp_editworkarea.id, tmp_editworkarea)
			console.log(return_data)
			let return_dataIntervention = await deleteIntervention(domain, contractId, intervention_id)
			console.log(return_dataIntervention)
			updateWorkarea(tmp_editworkarea)
		}
		childRefDelete.current.confirmDelete(t('workareas.messages.delete_intervention'), t('workareas.messages.delete_intervention_message'), cb)
	}


	const handleUpdateIntervention = (i) => {
		//intervention_dict[i.id] = i
		intervention_dict_ref.current[i.id] = i

		//updateInterventionDict(i.id, i)
		//updateIntervention(i)
		setIntervention(i)
	}



	const toggleShow = () => {
		setShowRoomList(s => !s)
	}


	useEffect(() => {

		async function fetchData() {
			setLoading(true)

			//let intervention = intervention_dict[intervention_id] intervention_dict_ref.current[i.id] = i
			let intervention = intervention_dict_ref.current[intervention_id]

			if (!intervention) {
				if (typeof intervention_id === 'string') {
					intervention = await getInterventionById(domain, contractId, intervention_id)
					//					console.log("SET", domain, contractId, intervention_id, intervention)
					//intervention_dict[intervention_id] = intervention
					intervention_dict_ref.current[intervention_id] = intervention
					//					updateInterventionDict(intervention_id,  intervention)
					setIntervention(intervention)
				}
			} else {
				setIntervention(intervention)
			}
			setLoading(false)
		}
		fetchData()
	}, [])



	/* 	if (intervention_dict && typeof intervention_id === 'string' && intervention_dict[intervention_id]) {
	
			const i = intervention_dict[intervention_id] */
	if (intervention) {
		{ console.log("INTERVENTION", intervention) }
		return (
			<Col>
				<Row className="mb-1">
					<Col>
						<FontAwesomeIcon icon="circle" className="text-secondary" />
						&nbsp;
						<b>{intervention.name}</b>
					</Col>
					<Col>
						<Row>
							<Col>
								<ServiceBadge service={service_dict[intervention.service?.id]} />&nbsp;
								{intervention.enable_refill &&
									<OverlayTrigger
										trigger={['focus', 'hover']}
										placement="right-start"
										overlay={<Tooltip>Ricarica dispenser</Tooltip>}>
										<FontAwesomeIcon icon="pump-soap" />
									</OverlayTrigger>
								}
							</Col>
						</Row>
						{intervention.secondary_service_list && intervention.secondary_service_list.length > 0 ?
							intervention.secondary_service_list.map(s => (

								<Row>
									<Col>
										<FontAwesomeIcon icon="plus-square" />
										<ServiceBadge service={service_dict[s]} />
									</Col>
								</Row>
							))
							: ''}
					</Col>
					<Col sm="1" className="text-center">
						<FontAwesomeIcon icon="user" /> &times; {intervention.operating_units}

					</Col>
					<Col sm="2" className="text-center p-0">
						{intervention.frequency_mode === 'ordinary' ?
							<>
								{intervention.use_activity_period ?
									<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderActivityPeriodListPopover(intervention.period_list)}>
										<Badge bg="secondary">{intervention.period_list ? intervention.period_list.length : <FontAwesomeIcon icon="exclamation-triangle" />} periodi</Badge>
									</OverlayTrigger>
									: ''}
								{getTimeValue(intervention.start)} - {getTimeValue(intervention.end)} <br />
								{intervention.weekdays ?
									<WeekDays weekdays={intervention.weekdays} />
									: ''}
							</>
							:
							<FrequencySelector element={intervention} mode='read' />
						}
					</Col>
					<Col sm="1" className="text-center">
						{getFixedNumber(intervention.size)}
					</Col>
					<Col sm="2">
						{intervention.risk_area_summary ? <ItemProgress ui_list={ui_list} risk_list={intervention.risk_area_summary} item={intervention} use_room_count={true} /> : ''}
					</Col>
					<Col sm="2" className="text-center">
						<ButtonGroup>
							{intervention.cart_element_list && intervention.cart_element_list.length > 0 ?
								<Button disabled variant="info" size="sm"><FontAwesomeIcon icon="check" /></Button>
								: ''}
							<ModalInterventionCart element={intervention} workarea={workarea} editedElement={editedElement} ui_list={ui_list} t={t} updateWorkarea={updateWorkarea} canWrite={canWrite} functions={{ edit: handleUpdateIntervention }} />
							{/* 							<ModalInterventionAssignment element={intervention} workarea={workarea} editedElement={editedElement} ui_list={ui_list} t={t} updateWorkarea={updateWorkarea} canWrite={canWrite} functions={{ edit: handleUpdateIntervention }} /> */}
							{yieldRead ?
								<ModalTimeAnalisys element={intervention} workarea={workarea} ui_list={ui_list} t={t} />
								: ''}
						</ButtonGroup>
						<ButtonGroup className="workarea-buttons">
							{globalEdit ?
								<>
									<RoomProvider>
										<ModalIntervention workarea={workarea} element={intervention} ui_list={ui_list} t={t} functions={{ edit: handleUpdateIntervention }} canWrite={canWrite} updateWorkarea={updateWorkarea} />
									</RoomProvider>
									{canWrite &&
										<Button size="sm" variant="danger" onClick={() => handleDeleteIntervention()}> <FontAwesomeIcon icon="trash" /> </Button>
									}
								</> : ''}
						</ButtonGroup>
					</Col>
				</Row>
				<Row>

					{intervention.room_list && intervention.room_list.length > 0 ?
						<>
							<Col>
								<Card >
									<Card.Header className="p-1" onClick={toggleShow} style={{ cursor: "pointer" }}>
										{t('workarea.labels.roomlist')}
										<Badge bg="secondary" className="ms-3 me-3">{intervention.room_list.length} {t('global.labels.rooms')}
											{intervention.room_list.filter(r => structureElements.all_room_dict[r.id] === undefined).length > 0 &&
												<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={<Tooltip>Presenza di locali eliminati</Tooltip>}>
													<FontAwesomeIcon icon="exclamation-triangle" />
												</OverlayTrigger>
											}
										</Badge>
										{intervention.floor_list ?
											<FontAwesomeIcon icon="check" /> : ''}
									</Card.Header>
									{showRoomList &&
										<Card.Body>
											<Row className="p-1 section_title mb-1">
												<Col className="ms-1 bg-light text-secondary" >{t('global.labels.name')}</Col>
												<Col className="ms-1 bg-light text-secondary" >{t('structures.labels.room_type')}</Col>
												<Col className="ms-1 bg-light text-secondary" sm="5" ></Col>
												<Col className="ms-1 bg-light text-secondary" sm="1" >Cdc</Col>
												<Col className="ms-1 bg-light text-secondary" sm="1" ></Col>
											</Row>
											{intervention.room_list.map((room, index) => (
												<OneRoom key={intervention.id + "_" + room.id + "_" + index} index={index} room={room} intervention={intervention} ui_list={ui_list} />
											))}
										</Card.Body>
									}
								</Card>
							</Col>

						</>
						: ''
					}
				</Row>

			</Col>
		)
	} else {
		return <>
			<Spinner animation="grow" role="status" variant="info" />
		</>
	}

}





////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
export const ModalFilter = ({ t, filterAppliedList, setFilterAppliedList, defaultfilter, intervention_type }) => {
	const { service_dict, structureElements } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)
	const {
		intervention_dict_ref,
		ui_list
	} = useContext(InterventionContext)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	function checkInterventionPresence(d) {
		console.log("INTERVETNION_DICT", intervention_dict_ref.current)
		for (let i in intervention_dict_ref.current) {
			if (intervention_dict_ref.current[i].service && intervention_dict_ref.current[i].service.id === d) {
				return true
			}
		}
		return false
	}


	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.service.length > 0 ?
					<>
						{Object.keys(service_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.service.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.service.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('service', d)}>
								{service_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.service.length > 1 &&
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('service')}><FontAwesomeIcon icon="times" /></Button>
						}
					</>
					: ''}
				{filterAppliedList.structure.length > 0 ?
					<>
						{Object.keys(structureElements.all_structure_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.structure.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.structure.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('structure', d)}>
								{structureElements.all_structure_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.structure.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.pavillon.length > 0 ?
					<>
						{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.pavillon.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.pavillon.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('pavillon', d)}>
								{structureElements.all_pavillon_dict[d].name && structureElements.all_pavillon_dict[d].name !== 'null' ? structureElements.all_pavillon_dict[d].name : 'padiglione senza nome'}
								<FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.pavillon.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
								{t('global.labels.services')}
							</Col>
						</Row>
						<Row>
							{service_dict ?
								<>
									{Object.keys(service_dict).filter(d => service_dict[d].type === intervention_type).map((d, k) => (
										<Col sm="4" key={k}>
											<Button block size="sm" className="ms-1 mb-1 text-start" disabled={!checkInterventionPresence(d)} variant={filterAppliedList.service.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('service', d)}>
												<ServiceBadge service={service_dict[d]} />
											</Button>
										</Col>
									))}
								</>
								: ''
							}
						</Row>
						<Row>
							<Col>
								{t('global.labels.structure')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_structure_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.structure.indexOf(structureElements.all_structure_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('structure', structureElements.all_structure_dict[d].id)}>
										{structureElements.all_structure_dict[d].name}
									</Button>
								))}
								{filterAppliedList.structure.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
									//disabled={filterAppliedList.structure.indexOf(structureElements.all_pavillon_dict[d].parent_list[0]) === -1}
									>
										{structureElements.all_pavillon_dict[d].name && structureElements.all_pavillon_dict[d].name !== 'null' ? structureElements.all_pavillon_dict[d].name : 'padiglione senza nome'}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilterRoom = ({ t, filterAppliedList, defaultfilter, event_list, calendarRef, setFilterAppliedList }) => {
	const { structureElements } = useContext(GlobalDataContext)
	const {
		ui_list
	} = useContext(InterventionContext)

	const [showFilter, setShowFilter] = useState(false)




	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		let _filterAppliedList = filterAppliedList
		if (section) {
			_filterAppliedList = { ...filterAppliedList, [section]: [] }
		} else {
			_filterAppliedList = (defaultfilter)
		}
		setFilterAppliedList(_filterAppliedList)
		//setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		console.log(filterAppliedList, section)
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}



	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.room_pavillon.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('room_pavillon')}><Badge>{filterAppliedList.room_pavillon.length}</Badge>{t('global.labels.pavillon')}<FontAwesomeIcon icon="times" /></Button>
					: ''}
				{filterAppliedList.room_floor.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('room_floor')}><Badge>{filterAppliedList.room_floor.length}</Badge>{t('global.labels.floor')}<FontAwesomeIcon icon="times" /></Button>
					: ''}
				{filterAppliedList.room_department.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('room_department')}><Badge>{filterAppliedList.room_department.length}</Badge>{t('global.labels.department')}<FontAwesomeIcon icon="times" /></Button>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} size="md">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>

						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.room_pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('room_pavillon', structureElements.all_pavillon_dict[d].id)}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.room_pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('room_pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>

						<Row>
							<Col>
								{t('global.labels.floor')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_floor_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.room_floor.indexOf(structureElements.all_floor_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('room_floor', structureElements.all_floor_dict[d].id)}
										disabled={filterAppliedList.room_pavillon.length > 0 && filterAppliedList.room_pavillon.indexOf(structureElements.all_floor_dict[d].parent_list[1]) < 0}
									>
										{structureElements.all_pavillon_dict[structureElements.all_floor_dict[d].parent_list[1]] ?
											<>
												{structureElements.all_pavillon_dict[structureElements.all_floor_dict[d].parent_list[1]].name}&nbsp;|&nbsp;{t('global.labels.floor')}
											</>
											: ''}
										{structureElements.all_floor_dict[d].name}
									</Button>
								))}
								{filterAppliedList.room_floor.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('room_floor')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.department')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_department_dict).map((d, k) => (
									<Button className="m-1" key={k} size="sm"
										variant={filterAppliedList.room_department.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
										disabled={(filterAppliedList.room_pavillon.length > 0 && filterAppliedList.room_pavillon.indexOf(structureElements.all_department_dict[d].parent_list[1]) < 0)
											|| (filterAppliedList.room_floor.length > 0 && filterAppliedList.room_floor.indexOf(structureElements.all_department_dict[d].parent_list[2])) < 0}
										onClick={(e) => updateAppliedFilter('room_department', d)}>
										<i>{getValue(getSelectedElement(structureElements.all_department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}</i> |
										{structureElements.all_department_dict[d].name}
									</Button>
								))}
								{filterAppliedList.room_department.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('room_department')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}





export default translate()(InterventionContainerView)