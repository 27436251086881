import React, { useContext, useState, useEffect } from 'react';
import { Form, Container, Button, Row, Col, ButtonGroup, InputGroup } from 'react-bootstrap';
import { firebaseAuth } from '../provider/AuthProvider'
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import { firebase, auth, } from '../Firebase2/firebaseIndex.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Signin = (props) => {
    const { handleSignin, setInputs, errors, twofactor_errors, setTwofactorErrors, handleSigningTwofactor } = useContext(firebaseAuth)
    // DEBUG: console.log(handleSignin)
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState()
    const [verificationCode, setCode] = useState()
    const [showInsertCode, setShowInsertCode] = useState(false)
    const [verificationId, setVerificationId] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const [recaptchaVerifier, setCaptchaVerifier] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault()
        // DEBUG: console.log('handleSubmit')
        setLoading(true);
        //wait to signup 
        await handleSignin()
        setLoading(false);
        //push home
        props.history.push('#contractdashboard')
    }
    const handleChange = e => {
        const { name, value } = e.target
        // DEBUG: console.log(inputs)
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleChangeCode = e => {
        const { name, value } = e.target
        // DEBUG: console.log(inputs)
        setCode(prev => ({ ...prev, [name]: value }))
    }

    const handleSendSMS = async () => {
        setShowInsertCode(true)
        const resolver = twofactor_errors
        try {


            if (resolver.hints[0].factorId ===
                firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
                const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                var phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session
                };
                const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                    phoneInfoOptions, recaptchaVerifier);
                setVerificationId(verificationId)

            }
        } catch (err) {
            recaptchaVerifier.clear();

        }

    }
    const undoTwoFactor = () => {
        recaptchaVerifier.clear();
        setTwofactorErrors()

    }


    const handleTwofactorErrors = async () => {
        console.log("CODE", verificationCode.second_factor)
        const resolver = twofactor_errors
        var cred = firebase.auth.PhoneAuthProvider.credential(
            verificationId, verificationCode.second_factor);
        try {




            var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
            const userCredential = await resolver.resolveSignIn(multiFactorAssertion)
            await handleSigningTwofactor(userCredential)
        } catch (err) {
            console.log("ERRORE CODICE")
        }

    }


    const handleKeyPress = (target) => {
        //console.log(target)
        if (target.charCode === 13) {

            handleSubmit(target)
        }
    }
    useEffect(() => {
        const getData = async () => {
            console.log(props.subdomain.subdomain)
            const c = await (
                await fetch(`https://clauditpublic.web.app/api/logo/${props.subdomain.subdomain}`)
            ).json()
            if (c.path && c.path.length > 0) {
                setLogo(c.path)
            } else {
                setLogo()
            }


        }
        try {
            let _recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container-id', {
                size: 'invisible',
                callback: (response) => {
                    console.log('successfully created the captcha');
                    console.log(response);
                },
            }
            );
            setCaptchaVerifier(_recaptchaVerifier)
        } catch (e) {
            console.log(e)
        }


        if (props.subdomain) {
            getData()
        }
        setShowInsertCode(false)
    }, [])

    return (
        <Container fluid className="text-center login_background vh-100 justify-content-center d-flex">
            <Form as={Row} className="justify-content-center align-items-center flex-grow-1">
                <div id="recaptcha-container-id"></div>

                <Col sm="4" className="border border-info pb-2 bg-light mt-5" >
                    <Row>
                        {logo ?
                            <Col className="mt-2">
                                <img
                                    src={logo}
                                    height="120"
                                    className="d-inline-block align-top"
                                    alt="Claudit"
                                />
                            </Col>
                            :
                            <Col className="mt-2">
                                <img
                                    src="/Logo.Claudit.registrato.png"
                                    height="120"
                                    className="d-inline-block align-top"
                                    alt="Claudit"
                                />
                            </Col>
                        }

                    </Row>


                    {twofactor_errors ?
                        <>
                            {twofactor_errors.hints.length > 0 ?
                                <>

                                    <Row>

                                        {showInsertCode ?
                                            <Col className="border-bottom border-top me-4 ms-4 mt-2 mb-2 pb-2 pt-2">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Codice di verifica</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoFocus
                                                        placeholder="codice"
                                                        name="second_factor"
                                                        onChange={(event) => handleChangeCode(event)}
                                                        isInvalid={errors.second_Factor ? true : false}
                                                    />

                                                </Form.Group>
                                                <Button variant="primary" onClick={(event) => handleTwofactorErrors(event)}>
                                                    Conferma codice
                                                </Button>
                                            </Col>
                                            :
                                            <Col className="border border-warning me-2 ms-2">
                                                <Row className='mt-4 mb-4 bg-warning'>
                                                    <Col>
                                                        <b>
                                                            Secondo fattore di autentificazione richiesto.<br />
                                                        </b>

                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col>
                                                        Invia codice tramite SMS al numero associato ({twofactor_errors.hints[0].phoneNumber})
                                                    </Col>
                                                </Row>
                                                <Row className='mt-4 mb-4'>
                                                    <Col>
                                                        <Button variant="warning" onClick={(event) => handleSendSMS(event)}>
                                                            Invia codice di verifica
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="secondary" onClick={(event) => undoTwoFactor(event)}>
                                                            Annulla
                                                        </Button>
                                                    </Col>
                                                </Row>



                                            </Col>
                                        }
                                    </Row>


                                </>
                                : <>Secondo fattore non presente. Account non verificabile</>
                            }
                        </>
                        :
                        <>
                            <Row>
                                <Col className="border-bottom border-top me-4 ms-4 mt-2 mb-2 pb-2 pt-2">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            type="email"
                                            autoFocus
                                            placeholder="Inserisci username/email"
                                            name="email"
                                            onChange={(event) => handleChange(event)}
                                            isInvalid={errors.email ? true : false}
                                        />
                                        {errors.email &&
                                            <Form.Text className="text-muted text-danger">
                                                {errors.email}
                                            </Form.Text>
                                        }

                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup>

                                            <Form.Control
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Password"
                                                name="password"
                                                onChange={(event) => handleChange(event)} onKeyPress={handleKeyPress}
                                            />
                                            <Button onClick={() => setShowPassword(v => !v)}><FontAwesomeIcon icon={showPassword ? "eye-slash" : "eye"} /></Button>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ms-4 me-4 mt-3 mb-3">
                                    <Button variant="primary" type="submit" className="btn-block" onClick={(event) => handleSubmit(event)} disabled={loading}>
                                        Accedi
                                    </Button>
                                </Col>
                            </Row>

                            {errors.length > 0 &&
                                <Row>
                                    <Col>
                                        <Form.Text className="text-danger">
                                            {errors.map(error => <p style={{ color: 'red' }}>{error}</p>)}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            }
                        </>

                    }
                </Col>
            </Form>
        </Container>
    );





};






export default withRouter(Signin);