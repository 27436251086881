import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from 'react-bootstrap'


export const domain_page_list = [
	'usersettings',
	'trainer',
	'trainings',
	'employers'
]

export const serviceType_list = (props) => {
	const t = props.t
	const service_type = [
		{ id: 'ordinary', intervention_id: 'ordinary_intervention', label: t('workarea.labels.ordinary'), icon: "tasks", color: "grey" },
		{ id: 'periodic', intervention_id: 'periodic_intervention', label: t('workarea.labels.periodic'), icon: "calendar-day", color: "lightgrey" },
		{ id: 'requested', intervention_id: 'requested_intervention', label: t('workarea.labels.requested'), icon: "comment-dollar", color: "ligthblue" },
		{ id: 'fee', intervention_id: 'fee_intervention', label: t('workarea.labels.fee'), icon: "clipboard-check", color: "lightyellow" }
	]
	return service_type
}

export const serviceType_dict = (props) => {
	const t = props.t
	const service_type = {
		ordinary: { id: 'ordinary', intervention_id: 'ordinary_intervention', label: t('workarea.labels.ordinary'), icon: "tasks", color: "grey" },
		periodic: { id: 'periodic', intervention_id: 'periodic_intervention', label: t('workarea.labels.periodic'), icon: "calendar-day", color: "lightgrey" },
		requested: { id: 'requested', intervention_id: 'requested_intervention', label: t('workarea.labels.requested'), icon: "comment-dollar", color: "ligthblue" },
		fee: { id: 'fee', intervention_id: 'fee_intervention', label: t('workarea.labels.fee'), icon: "clipboard-check", color: "lightyellow" }
	}
	return service_type
}

export const interventionType_dict = (props) => {
	const service_type = {
		ordinary_intervention: { id: 'ordinary', title: "Intervento ordinario", intervention_id: 'ordinary_intervention', label: "Ordinaria", icon: "tasks", color: "grey" },
		periodic_intervention: { id: 'periodic', title: "Intervento periodico", intervention_id: 'periodic_intervention', label: "Periodica", icon: "calendar-day", color: "lightgrey" },
		requested_intervention: { id: 'requested', title: "intervento a richiesta", intervention_id: 'requested_intervention', label: "A richiesta", icon: "comment-dollar", color: "ligthblue" },
		fee_intervention: { id: 'fee', title: "Intervento a canone", intervention_id: 'fee_intervention', label: "A canone", icon: "clipboard-check", color: "lightyellow" }
	}
	return service_type
}

export const good_dict = ({ t }) => {
	const goods_labels = {
		'product': { color: "text-danger", icon: "flask", label: t('roomtype.labels.product_list') },
		'cleaningmaterial': { color: "text-dark", icon: "square", label: t('roomtype.labels.cleanig_material_list') },
		'equipment': { color: "text-warning", icon: "broom", label: t('roomtype.labels.equipment_list') },
		'machine': { color: "text-info", icon: "tachometer-alt", label: t('roomtype.labels.machine_list') },
		'cart': { color: "text-primary", icon: "dolly-flatbed", label: t('roomtype.labels.cart_list') },
	};
	return goods_labels

}

export const goods_labels = ({ t }) => {
	return {
		'product': { color: "text-danger", icon: "flask", label: t('roomtype.labels.product_list') },
		'cleaningmaterial': { color: "text-dark", icon: "square", label: t('roomtype.labels.cleanig_material_list') },
		'equipment': { color: "text-warning", icon: "broom", label: t('roomtype.labels.equipment_list') },
		'machine': { color: "text-info", icon: "tachometer-alt", label: t('roomtype.labels.machine_list') },
		'cart': { color: "text-primary", icon: "dolly-flatbed", label: t('roomtype.labels.cart_list') },
		'dpi': { color: "text-secondary", icon: "head-side-mask", label: t('global.labels.dpi_list') },
	}
};

export const biocida_productType_list = ({ t }) => {
	const biocida_producttype_list = [
		{ label: t('product.labels.biocida_type_pt1'), code: 'PT1' },
		{ label: t('product.labels.biocida_type_pt2'), code: 'PT2' },
		{ label: t('product.labels.biocida_type_pt4'), code: 'PT4' },
		{ label: t('product.labels.biocida_type_pt5'), code: 'PT5' },
		{ label: t('product.labels.biocida_type_pt14'), code: 'PT14' },
		{ label: t('product.labels.biocida_type_pt15'), code: 'PT15' },
		{ label: t('product.labels.biocida_type_pt16'), code: 'PT16' },
		{ label: t('product.labels.biocida_type_pt18'), code: 'PT18' },
		{ label: t('product.labels.biocida_type_pt19'), code: 'PT19' },
		{ label: t('product.labels.biocida_type_pt20'), code: 'PT20' },
	]
	return biocida_producttype_list
}
export const samplingplanTiming_list = ({ t }) => {

	const control_option_list = [
		{ id: 0, label: t('workarea.labels.frequency_monthly'), multiplier: [0.33, 1, 12], month: 1 },
		{ id: 1, label: t('frequency.labels.frequency_bimonthly'), multiplier: [0.125, 0.5, 6], month: 2 },
		{ id: 2, label: t('workarea.labels.frequency_quarterly'), multiplier: [0.06, 0.33, 4], month: 3 },
		{ id: 3, label: t('frequency.labels.frequency_four-monthly'), multiplier: [0.05, 0.25, 3], month: 4 },
		{ id: 4, label: t('frequency.labels.frequency_semester'), multiplier: [0.04, 0.16, 2], month: 6 },
		{ id: 5, label: t('frequency.labels.frequency_yearly'), multiplier: [0.02, 0.08, 1], month: 12 }
	]
	return (control_option_list)
}

export const processCheckSection_dict = ({ t }) => {
	const check_section = {
		operator: {
			list: [
				{ id: "personal_hygiene", label: t('workarea.labels.personal_hygiene') },
				{ id: "behavioral_rules", label: t('workarea.labels.behavioral_rules') },
				{ id: "prevention_measures", label: t('workarea.labels.prevention_measures') },
			], label: t('workarea.labels.checkoperator'), id: 'operator'
		},
		equipment: {
			list: [
				{ id: "product", label: t('global.labels.products') },
				{ id: "cleaningmaterial", label: t('global.labels.material') },
				//{ id: "equipment", label: t('global.labels.equipment') },
				//		{ id: "machine", label: t('global.labels.machines') },
				//{ id: "cart", label: t('global.labels.carts') },
			], label: t('workarea.labels.checkequipment'), id: 'equipment'
		},
		skill: {
			list: [
				{ id: "methodological_rules", label: t('workarea.labels.methodological_rules') },
				{ id: "procedures", label: t('workarea.labels.procedures') },
				{ id: "training", label: t('workarea.labels.training') },
			], label: t('workarea.labels.checkskill'), id: 'skill'
		},

	}
	return check_section
}

export const accountType_dict = ({ t }) => {
	const type_dict = {
		client: {
			id: 'client',
			label: t('global.labels.account_client')
		},
		supplier: {
			id: 'supplier',
			label: t('global.labels.account_supplier')
		},
	}
	return type_dict
}
export const workshiftType_dict = ({ t }) => {
	const type_dict = {
		monthdays: {
			id: 'monthdays',
			label: t('workshift.labels.monthdays')
		},
		weekdays: {
			id: 'weekdays',
			label: t('workshift.labels.weekdays')
		},
		workrestdays: {
			id: 'workrestdays',
			label: t('workshift.labels.workrestdays')
		},

	}
	return type_dict
}

export const roomStatus_dict = ({ t }) => {
	const status_dict = {
		active: {
			id: 'active',
			label: t('structure.labels.status_type_active'),
			icon: '',
			label_status: t('structure.labels.status_type_active')
		},
		suspended: {
			id: 'suspended',
			label: t('structure.labels.status_type_suspension'),
			icon: 'hourglass',
			label_status: t('structure.labels.status_type_issuspension')
		},
		decommissioned: {
			id: 'decommissioned',
			label: t('structure.labels.status_type_decommission'),
			icon: 'exclamation-circle',
			label_status: t('structure.labels.status_type_isdecommission')
		}

	}
	return status_dict
}

export const timingMode_dict = ({ t }) => {
	const timing_mode_dict = {
		now: { id: 'now', label: t('ticket.labels.now_mode') },
		delayed: { id: 'delayed', label: t('ticket.labels.delayed_mode') }
	}

	return timing_mode_dict
}

export const roomStatus_list = ({ t }) => {
	const status_list = [
		{
			id: 'active',
			label: t('structure.labels.status_type_active'),
			icon: '',
			label_status: t('structure.labels.status_type_active')
		},
		{
			id: 'suspended',
			label: t('structure.labels.status_type_suspension'),
			icon: 'hourglass',
			label_status: t('structure.labels.status_type_issuspension')
		},
		{
			id: 'decommissioned',
			label: t('structure.labels.status_type_decommission'),
			icon: 'exclamation-circle',
			label_status: t('structure.labels.status_type_isdecommission')
		}

	]
	return status_list
}

export const contractStatus_list = ({ t }) => {
	const status_list = [
		{ id: 'draft', label: t('contract.labels.status_draft') },
		{ id: 'testing', label: t('contract.labels.status_testing') },
		{ id: 'operational', label: t('contract.labels.status_operational') },
		{ id: 'extension', label: t('contract.labels.status_extension') },
		{ id: 'expired', label: t('contract.labels.status_expired') },
	]
	return status_list
}

export const contractStatus_dict = ({ t }) => {
	const status_dict = {
		draft: { id: 'draft', label: t('contract.labels.status_draft') },
		testing: { id: 'testing', label: t('contract.labels.status_testing') },
		operational: { id: 'operational', label: t('contract.labels.status_operational') },
		extension: { id: 'extension', label: t('contract.labels.status_extension') },
		expired: { id: 'expired', label: t('contract.labels.status_expired') },
	}
	return status_dict
}

export const roomComposition_dict = ({ t }) => {
	const roomcomposition_dict = {
		furniture_list: {
			id: 'furniture_list',
			title: t('global.labels.furniture'),
			available: t('roomtype.labels.avaiable_furniture'),
			empty: t('structure.messages.no_furniture'),
			add: t('structure.labels.furniture_add'),
			new: t('roomtypes.labels.new_furniture'),
			edit: t('roomtypes.labels.edit_furniture'),
			delete_title: t('roomtype.labels.delete_furniture_title'),
			delete: t('roomtype.labels.delete_furniture')

		},
		environment_element_list: {
			id: 'environment_element_list',
			title: t('global.labels.environment_element'),
			available: t('roomtype.labels.avaiable_environment_element'),
			empty: t('structure.messages.no_component'),
			add: t('structure.labels.environment_element_add'),
			new: t('roomtypes.labels.new_environment_element'),
			edit: t('roomtypes.labels.edit_environment_element'),
			delete_title: t('roomtype.labels.delete_environment_element_title'),
			delete: t('roomtype.labels.delete_environment_element')
		},
		economic_material_list: {
			id: 'economic_material_list',
			title: t('global.labels.dispenser_economic_material'),
			available: t('roomtype.labels.avaiable_dispenser_economic_material'),
			empty: t('structure.messages.no_dispenser_economic_material'),
			add: t('structure.labels.economic_material_add'),
			new: t('roomtypes.labels.new_economic_material'),
			edit: t('roomtypes.labels.edit_economic_material'),
			delete_title: t('roomtype.labels.delete_economic_material_title'),
			delete: t('roomtype.labels.delete_economic_material')
		},
	}

	return roomcomposition_dict
}

export const productRegCode_dict = ({ t }) => {
	const rec_code_dict = {
		'en1040': { sector: 'civil', type: 'battericida/funghicida' }
	}
	return rec_code_dict


}


export const messagePriority_dict = ({ t }) => {
	const priority_dict = {
		"1": t('ticket.labels.priority_low'),
		"2": t('ticket.labels.priority_middle'),
		"3": t('ticket.labels.priority_high'),
	}
	return priority_dict
}


export const frequency_dict = ({ t }) => {

	const freq_dict = {
		week: { value: 'week', label: t('frequency.labels.frequency_week'), year_count: 52 },
		twoweek: { value: 'twoweek', label: t('frequency.labels.frequency_twoweek'), year_count: 26 },
		month: { value: 'month', label: t('frequency.labels.frequency_month'), year_count: 12 },
		bimonthly: { value: 'bimonthly', label: t('frequency.labels.frequency_bimonthly'), year_count: 6 },
		quarter: { value: 'quarter', label: t('frequency.labels.frequency_quarter'), year_count: 4 },
		'four-monthly': { value: 'four-monthly', label: t('frequency.labels.frequency_four-monthly'), year_count: 3 },
		semester: { value: 'semester', label: t('frequency.labels.frequency_semester'), year_count: 2 },
		year: { value: 'year', label: t('frequency.labels.frequency_year'), year_count: 1 }
	}
	return freq_dict
}


export const licence_status_dict = ({ t }) => {
	const timing_dict = {
		active: { label: t('licence.labels.status_active'), icon:'check'},
		demo: { label: t('licence.labels.status_demo') , icon: 'magic'},
		archived: { label: t('licence.labels.status_archived'), icon: 'archive'},
	}
	return timing_dict
}
export const processcontroltiming_dict = ({ t }) => {
	const timing_dict = {
		pre: { label: t('workarea.labels.timing_type_pre') },
		during: { label: t('workarea.labels.timing_type_during') },
		post: { label: t('workarea.labels.timing_type_post') }

	}
	return timing_dict
}

export const materialIntendedUse_dict = ({ t, subsection }) => {
	let intended_use_dict = {
		dusting_furniture: { color: '#3366ff', label: t('products.labels.dusting_furniture') },
		dusting_trash: { color: '#003300', label: t('products.labels.dusting_trash') },
		waste_bag: { color: '#003300', label: t('products.labels.waste_bag') },
		dusting_toilet: { color: '#990033', label: t('products.labels.dusting_toilet') },
		washing_floors: { color: '#3366ff', label: t('products.labels.washing_floors') },
		washing_toilet_floors: { color: '#990033', label: t('products.labels.washing_toilet_floors') },
		washing_umid_floors: { color: '#990033', label: t('products.labels.washing_umid_floors') },
		mechanized_washing: { color: '#990033', label: t('products.labels.mechanized_washing') },
	}
	if (!subsection) {
		intended_use_dict['economic_material'] = { color: '#aaaaaa', label: t('products.labels.economic_material') }
	}
	return intended_use_dict

}
export const servicePaymentModeParams_dict = ({ t }) => {
	const rt_params = {
		'month': { label: t('global.labels.month') },
		'year': { label: t('global.labels.year') },
		'contract': { label: t('global.labels.contract') },
	}
	return rt_params
}

export const roomTypeParams_dict = ({ t, contract_type }) => {
	const rt_params = {
		'risk_area': {
			label:
				contract_type === 'civil' ?
					t('global.labels.standard_cleaning')
					:
					t('global.labels.risk_area')
		},
		'floor': { label: "Pavimento" },
		'can_mechanized_washing': { label: t('structure.labels.can_mechanized_washing') },
		'need_waxing': { label: t('structure.labels.need_waxing') },
		'furniture_list': { label: t('global.labels.furniture') },
		'environment_element_list': { label: t('global.labels.environment_element') },
		'economic_material_list': { label: t('global.labels.dispenser_economic_material') },

	}
	return rt_params
}


export const wallMaterials_dict = ({ t }) => {

	const wmt = {
		'polish': { label: t('structure.labels.polish') },
		'tile': { label: t('structure.labels.tile') },
		'laminate': { label: t('structure.labels.laminate') },
		'glass': { label: t('structure.labels.glass') },

	}
	return wmt
}


export const ticketType_dict = ({ t }) => {

	const ticket_type = {
		request: {
			disabled: false, id: 'request', label: t('ticket.buttons.request'), color: 'DarkGreen', label_new: t('ticket.buttons.new_request'),
			icon: <FontAwesomeIcon icon="exclamation-triangle" className="text-danger" />, is_ticket: true, event: 'request_ticket',
			status: { 'sent': t('ticket.labels.open'), 
			//'read': t('ticket.labels.read'), 
			'assigned': t('ticket.labels.assigned'), 'partially_assigned': t('ticket.labels.partially_assigned'), 'done': <Badge bg='success'><FontAwesomeIcon icon="check" />{t('ticket.labels.done')}</Badge>, forward: t('ticket.labels.forward'), sent_forward: t('ticket.labels.openforward') }
		},
		noncompliance: {
			disabled: false, id: 'noncompliance', color: 'Brown', label: t('ticket.buttons.non-compliance_service'), label_new: t('ticket.buttons.new_non-compliance'),
			icon: <FontAwesomeIcon icon="bell" className="text-warning" />, is_ticket: true, event: 'noncompliance_ticket',
			status: { 'sent': t('ticket.labels.open'), 
			//'read': t('ticket.labels.read'), 
			'done': t('ticket.labels.done'), forward: t('ticket.labels.forward'), sent_forward: t('ticket.labels.openforward') }
		},
		failure: {
			disabled: false, id: 'failure', label: t('ticket.buttons.failure'), color: 'DarkYellow', label_new: t('ticket.buttons.new_failure'),
			icon: <FontAwesomeIcon icon="wrench" className="text-primary" />, is_ticket: true, event: 'failure_ticket',
			status: { 'sent': t('ticket.labels.reported'), 
			//'read': t('ticket.labels.read'), 
			'done': t('ticket.labels.closed'), 	forward: t('ticket.labels.forward'), sent_forward: t('ticket.labels.reportedforward') }
		},
		communication: {
			disabled: false, id: 'communication', color: 'DarkOliveGreen', label: t('ticket.buttons.communication'), label_new: t('ticket.buttons.new_communication'),
			icon: <FontAwesomeIcon icon="comment" className="text-info" />, is_ticket: true, event: 'communication_ticket',
			status: { 'sent': t('ticket.labels.sent'), 
			//'read': t('ticket.labels.read'), 
			'done': t('ticket.labels.done'), forward: t('ticket.labels.forward'), sent_forward: t('ticket.labels.sentforward') }
		},
		registry_change: {
			disabled: false, id: 'registry_change', color: 'DarkOliveGreen', label: t('ticket.buttons.registry_change'), label_new: t('ticket.buttons.new_registry_change'),
			icon: <FontAwesomeIcon icon="building" className="text-info" />, is_ticket: true, event: 'registry_change_ticket',
			status: { 'sent': t('ticket.labels.sent'), 
			//'read': t('ticket.labels.read'), 
			forward: t('ticket.labels.forward'), sent_forward: t('ticket.labels.sentforward'), 'done': <Badge bg='success'><FontAwesomeIcon icon="check" />{t('ticket.labels.done')}</Badge> }
		},
		noncompliance_control: {
			disabled: true, id: 'noncompliance_control', color: 'Brown', label: t('ticket.buttons.non-compliance_control'), label_new: t('ticket.buttons.new_non-compliance_control'),
			icon: <FontAwesomeIcon icon="bell" className="text-danger" />, is_ticket: true, event: 'noncompliance_control_ticket',
			status: { 'sent': t('ticket.labels.open'), 
			//'read': t('ticket.labels.read'), 
			'done': t('ticket.labels.done'), forward: t('ticket.labels.forward'), sent_forward: t('ticket.labels.openforward') }
		},
		manual_event: {
			disabled: false, id: 'manual_event', color: 'Green', label: t('ticket.buttons.event'), label_new: t('ticket.buttons.new_manual_event'),
			icon: <FontAwesomeIcon icon="info-circle" className="text-primary" />, is_ticket: false, event: 'manual_event',
			status: { 'sent': t('ticket.labels.open'), 'read': t('ticket.labels.read'), 'done': t('ticket.labels.done'), forward: t('ticket.labels.forward'), sent_forward: t('ticket.labels.openforward') }
		},
	}
	return ticket_type
}
//-------------------------------------------
export const reportType_dict = ({ t }) => {

	const report_type = {
		structureintervention: { disabled: false, id: 'structureintervention', label: t('report.buttons.structureinterventions') }
	}
	return report_type
}
export const checkType_dict = ({ t }) => {

	const check_type_dict = {
		results: { label: t('global.pages.resultscheck') },
		process: { label: t('global.pages.processcheck') },
		microbiological: { label: t('global.pages.microbiologicalcheck') },
		customer_satisfaction: { label: t('global.pages.customer_satisfactioncheck') }
	}
	return check_type_dict
}


export const checkMode_dict = ({ t }) => {
	const check_mode_dict = {
		autocheck: { label: t('contract.labels.autocheck'), code: 'A' },
		contradictory: { label: t('contract.labels.contradictory'), code: 'C' },
		client_checks: { label: t('contract.labels.client_checks'), code: 'S' },
		micro_checks: { label: "Controllo Microbiologico", code: 'M' },
	}
	return check_mode_dict
}
export const appRole_dict = ({ t }) => {
	const app_roles = {
		'customer_controller': { name: t('contract.labels.role_customer_controller'), id: 'customer_controller' },
		'supplier_controller': { name: t('contract.labels.role_supplier_controller'), id: 'supplier_controller' },
		'external_controller': { name: t('contract.labels.role_external_controller'), id: 'external_controller' },
		'other': { name: t('check.labels.other'), id: 'other' },
		'-1': { name: t('check.labels.other'), id: '' }
	}

	return app_roles
}


export const contractScope_dict = ({ t }) => {
	const contract_scope = {
		"hospital": { id: "hospital", icon: "./icone_contratto/ospedale.png", label: t('contract.labels.sector_hospital'), type: 'hospital' },
		"retirement": { id: "retirement", icon: "./icone_contratto/casariposo.png", label: t('contract.labels.sector_retirement'), type: 'hospital' },
		"clinic": { id: "clinic", icon: "./icone_contratto/poliambulatorio.png", label: t('contract.labels.sector_clinic'), type: 'hospital' },
		"community": { id: "community", icon: "./icone_contratto/comunita.png", label: t('contract.labels.sector_community'), type: 'hospital' },
		"school": { id: "school", icon: "./icone_contratto/scuola.png", label: t('contract.labels.sector_school'), type: 'civil' },
		"office": { id: "office", icon: "./icone_contratto/ufficio.png", label: t('contract.labels.sector_office'), type: 'civil' },
		"shopping_center": { id: "shopping_center", icon: "./icone_contratto/centrocommerciale.png", label: t('contract.labels.sector_shopping_center'), type: 'civil' },
		"bank": { id: "bank", icon: "./icone_contratto/banca.png", label: t('contract.labels.sector_bank'), type: 'civil' },
		"railroad": { id: "railroad", icon: "./icone_contratto/ferrovia.png", label: t('contract.labels.sector_railroad'), type: 'civil' },
		"highway": { id: "highway", icon: "./icone_contratto/autostrada.png", label: t('contract.labels.sector_highway'), type: 'civil' },
		"airport": { id: "airport", icon: "./icone_contratto/aeroporto.png", label: t('contract.labels.sector_airport'), type: 'civil' },
		"hotel": { id: "hotel", icon: "./icone_contratto/hotel.png", label: t('contract.labels.sector_hotel'), type: 'civil' },
		"catering": { id: "catering", icon: "./icone_contratto/ristorante.png", label: t('contract.labels.sector_catering'), type: 'civil' },
		"residential": { id: "residential", icon: "./icone_contratto/residenziale.png", label: t('contract.labels.sector_residential'), type: 'civil' },
		"casino": { id: "casino", icon: "./icone_contratto/casino.png", label: t('contract.labels.sector_casino'), type: 'civil' },
		"prison": { id: "prison", icon: "./icone_contratto/carcere.png", label: t('contract.labels.sector_prison'), type: 'civil' },
	}

	return contract_scope
}

export const contractSector_dict = ({ t }) => {

	const contract_sector = {
		private: { label: t('contract.labels.scope_private') },
		public: { label: t('contract.labels.scope_public') }

	}
	return contract_sector
}

export const controlOption_list = ({ t }) => {

	const control_option_list = [
		{ id: 0, label: t('workarea.labels.frequency_monthly'), multiplier: [0.33, 1, 12], month_count: 0 },
		{ id: 1, label: t('frequency.labels.frequency_bimonthly'), multiplier: [0.125, 0.5, 6], month_count: 1 },
		{ id: 2, label: t('workarea.labels.frequency_quarterly'), multiplier: [0.06, 0.33, 4], month_count: 2 },
		{ id: 3, label: t('frequency.labels.frequency_four-monthly'), multiplier: [0.05, 0.25, 3], month_count: 3 },
		{ id: 4, label: t('frequency.labels.frequency_semester'), multiplier: [0.04, 0.16, 2], month_count: 5 },
		{ id: 5, label: t('frequency.labels.frequency_year'), multiplier: [0.02, 0.08, 1], month_count: 11 }
	]
	return control_option_list
}


export const contractType_dict = ({ t }) => {
	const contract_type = {
		hospital: { label: t('contract.labels.type_hospital') },
		civil: { label: t('contract.labels.type_civil') }
	}
	return contract_type
}

export const checkStrengthType_dict = ({ t }) => {
	const strong_type = {
		ordinary: { label: t('checks.labels.ordinary'), code: 'C2' },
		reinforced: { label: t('checks.labels.reinforced'), code: 'C3' },
		reduced: { label: t('checks.labels.reduced'), code: 'C4' },
	}
	return strong_type
}


export const controlCoefficient_dict = ({ t }) => {
	const contract_type = {
		1: { label: t('control.labels.coefficient1') },
		2: { label: t('control.labels.coefficient2') },
		3: { label: t('control.labels.coefficient3') },
	}
	return contract_type
}

export const mediaType_dict = ({ t, section }) => {

	let type_dict = {
		'file': { value: 'file', label: t('global.labels.document'), icon: "file", type: 'file' },
		'image': { value: 'image', label: t('global.labels.image'), icon: "image", type: 'image' },
		'data_sheet': { value: 'data_sheet', label: t('global.labels.data_sheet'), type: 'file' },
		'manual': { value: 'manual', label: t('global.labels.manual'), type: 'file' },
		'certification': { value: 'certification', label: t('global.labels.certification'), type: 'file' },
		'security_sheet': { value: 'security_sheet', label: t('global.labels.security_sheet'), type: 'file' },
		'administrative': { value: 'administrative', label: t('global.labels.administrative'), type: 'file' },
		'link': { value: 'link', label: t('global.labels.link'), type: 'link' }
	}
	if (section === 'contract') {
		type_dict = {
			'image': { value: 'image', label: t('global.labels.logo'), icon: "image", type: 'file' },
			'file': { value: 'file', label: t('global.labels.document'), icon: "file", type: 'file' },
			'link': { value: 'link', label: t('global.labels.link'), type: 'link' }
		}
	} else if (section === 'product') {
		type_dict = {
			'file': { value: 'file', label: t('global.labels.document'), icon: "file", type: 'file' },
			'image': { value: 'image', label: t('global.labels.image'), icon: "image", type: 'image' },
			'image_label': { value: 'image_label', label: t('global.labels.image_label'), icon: "image", type: 'file' },
			'image_product': { value: 'image_product', product: t('global.products.image_product'), icon: "image", type: 'image' },
			'data_sheet': { value: 'data_sheet', label: t('global.labels.data_sheet'), type: 'file' },
			'manual': { value: 'manual', label: t('global.labels.manual'), type: 'file' },
			'scene_doc': { value: 'scene_doc', label: t('global.labels.scene_doc'), type: 'file' },
			'cam_attachment': { value: 'cam_attachment', label: t('global.labels.cam_attachment'), type: 'file' },
			'cam_trial': { value: 'cam_trial', label: t('global.labels.cam_trial'), type: 'file' },
			'ecolabel': { value: 'ecolabel', label: t('global.labels.ecolabel'), type: 'file' },
			'validation_cts': { value: 'validation_cts', label: t('global.labels.validation_cts'), type: 'file' },
			'plastic_recycled': { value: 'plastic_recycled', label: t('global.labels.plastic_recycled'), type: 'file' },
			'autocertification': { value: 'autocertification', label: t('global.labels.autocertification'), type: 'file' },
			'certification': { value: 'certification', label: t('global.labels.certification'), type: 'file' },
			'security_sheet': { value: 'security_sheet', label: t('global.labels.security_sheet'), type: 'file' },
			'administrative': { value: 'administrative', label: t('global.labels.administrative'), type: 'file' },
			'link': { value: 'link', label: t('global.labels.link'), type: 'link' }
		}
	} else if (section === 'machine') {
		type_dict = {
			...type_dict,
			'validation_cts': { value: 'validation_cts', label: t('global.labels.validation_cts'), type: 'file' },
		}
	} else if (section === 'structure') {
		type_dict = {
			'file': { value: 'file', label: t('global.labels.document'), icon: "file", type: 'file' },
			'image': { value: 'image', label: t('global.labels.image'), icon: "image", type: 'file' },
			'map': { value: 'map', label: t('global.labels.structure_map'), icon: "map", type: 'file' },
			'link': { value: 'link', label: t('global.labels.link'), type: 'link' }
		}
	} else if (section === 'trainer') {
		type_dict = {
			'file': { value: 'file', label: t('global.labels.document'), icon: "file", type: 'file' },
			'image': { value: 'image', label: t('global.labels.image'), icon: "image", type: 'file' },
			'link': { value: 'link', label: t('global.labels.link'), type: 'link' }
		}

	}
	return type_dict
}

export const popoverTiming = { show: 250, hide: 100 }


export const execution_color = {
	doing: '#ffc107',
	done: '#20c997',
	undone: '#007bff'
}


export const payment_mode_dict = {
	'hour': { id: 'hour', label: "Orario", label_mode: 'Costo orario', label_unit: 'ore', label_unit_simple: 'ore', label_done: 'ore effettuate' },
	'activity': { id: 'activity', label: "Ad attività", label_mode: 'Costo ad attività', label_unit: 'attività', label_unit_simple: 'attività', label_done: 'attività effettuate' },
	'unit': { id: 'unit', label: "Unità", label_mode: 'Costo ad unità', label_unit: 'unità', label_unit_simple: 'unità', label_done: 'unità effettuate' },
	'size': { id: 'size', label: <>a m<sup>2</sup></>, label_mode: <>Consto a m<sup>2</sup></>, label_unit: <>m<sup>2</sup></>, label_unit_simple: "m^2", label_done: <>m<sup>2</sup> effettuati</> },

}

export const month_list = [
	{ label: 'gennaio', id: 0 },
	{ label: 'febbraio', id: 1 },
	{ label: 'marzo', id: 2 },
	{ label: 'aprile', id: 3 },
	{ label: 'maggio', id: 4 },
	{ label: 'giugno', id: 5 },
	{ label: 'luglio', id: 6 },
	{ label: 'agosto', id: 7 },
	{ label: 'settembre', id: 8 },
	{ label: 'ottobre', id: 9 },
	{ label: 'novembre', id: 10 },
	{ label: 'dicembre', id: 11 },
]



export const room_recalc_param_list = [
	'status',
	'size',
	'risk_area',
	'status_planning',
	'cdc',
	'roomtype'
]